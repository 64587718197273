import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../assets/img/blog/Banner-image_Australia_UAE_UK-NEW.webp";
import getimage from "../assets/img/blog/getimage.webp";
import { useParams, Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import "./CareerAndInternship.css";
import axios from "axios";
const CareersAndInternship = () => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fatchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/careers-and-internships"
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("fine an erroe", error);
      }
    };
    fatchData();
  }, []);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const buttonStyles = {
    width: "250px",
    height: "52px",
    background: isHovered ? "white" : "#b23427",
    border: isHovered ? "#252b37" : "#9B945F",
    position: "relative",
  };
  const circleStyle = {
    width: "400px",
    height: "400px",
    borderRadius: "50%",
    backgroundColor: "#b23427",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    transition: "transform 0.3s ease",
    transform: isHovered ? "scale(1.04)" : "scale(1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-300px",
    marginLeft: "100px",
  };

  const textStyles = {
    fontSize: "17px",
    fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
    color: isHovered ? "#b23427" : "white",
    border: isHovered ? "1px solid #b23427" : "#b23427",
    textAlign: "center",
    position: "relative",
    padding: "15px 30px",
    fontWeight: "400",
  };

  const handleFindVacancyClick = () => {
    history.push("/CareerInnerPage");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Careers And Internship",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );

  return (
    <div>
      <Header />
      {loading ? (     
               <Skeleton height={500} width={1800} />    
        ) : ( 
      <div style={{ position: "relative" }}>
        <img
          className="bannerimg"
          src={Banner}
          style={{ width: "100%", height: "auto" }}
          alt="Banner Image"
        />
        <br></br>
        <br></br>
        <br></br>
        <div
          className="bannerimgmobile"
          style={{
            position: "absolute",
            padding: "10px",
            marginLeft: "50px",
            marginTop: "-220px",
          }}
        >
          <h1
            style={{
              color: "#FFF",
              fontSize: "55px",
              fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
              fontStretch: "normal",
              fontStyle: "normal",
              fontWeight: "600",
            }}
          >
            Careers & Internships
          </h1>
          <button
            style={buttonStyles}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleFindVacancyClick}
          >
            <h3 style={textStyles}>Find a vacancy</h3>
          </button>
        </div>
      </div>
        )}
      <div>
        <div
          className="careermobile"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "1000px",
            height: "500px",
            background: "#e7eaeb",
            marginTop: "100px",
          }}
        >
          <h1
            className="desmobiletext1"
            style={{
              color: "#425563",
              fontSize: "39.614px",
              fontFamily: "Garamond,serif",
              textAlign: "center",
              paddingTop: "20px",
              fontWeight: "400",
            }}
          >
            Careers & Internships
          </h1>
          {data.map((internship, index) => (
            <h3
              className="desmobiletext"
              key={index}
              style={{
                fontSize: "18px",
                paddingTop: "70px",
                textAlign: "justify",
                paddingLeft: "40px",
                paddingRight: "40px",
                color: "#425563",
                fontSize: "16px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                lineHeight: "2",
                paddingTop: "-50px",
              }}
            >
              {internship.attributes.title}
            </h3>
          ))}
        </div>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <br class="remove-on-mobile"></br>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "50vh",
            marginTop: "50px",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            className="Bannerlogomobile"
            src={getimage}
            alt="Banner Logo"
            style={{ width: "100%", height: "auto", marginTop: "-70px" }}
          />
          <div className="circlestyleintership">
            <h3
              style={{
                fontWeight: "400",
                fontFamily: "Garamond-Regular,georgia,serif",
                fontSize: "40px",
                color: "#fff",
                zIndex: "9999",
                marginLeft: "5px",
              }}
            >
              Get in touch
            </h3>
            <button onClick={handleFindVacancyClick}>
              <Link
                to={"/CareerInnerPage"}
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "16px",
                  padding: "10px 20px",
                  backgroundColor: "#b23427",
                  color: "white",
                  border: "1px solid white",
                  cursor: "pointer",
                  width: "150px",
                  height: "50px",
                  marginTop: "10px",
                  textAlign: "center",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "#b23427";
                  e.target.style.border = "1px solid white";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "#b23427";
                  e.target.style.color = "white";
                }}
              >
                Contact us
              </Link>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
             
  );
};

export default CareersAndInternship;
