import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactPlayer from 'react-player';
import "./BlogDetails.css";

const CorporateInductionVideo = () => {
  return (
    <div>
      <Header />
     
      <h3 style={{ color: '#b23427', fontSize: '22px', fontFamily: 'Garamond-Regular,georgia,serif', marginTop: '50px', textAlign: 'center' }}>Corporate Induction Video</h3>
      <br></br>
      <br></br>
      <div style={{ margin:"0 auto", backgroundColor: 'white', maxWidth: '500px', height: '300px' }}>
      <ReactPlayer url='https://www.youtube.com/watch?v=JkpZSxeEqLg' className="mobileimage"/>
      </div>
     

      <br></br>
      <br></br>
      <h3 style={{ color: '#b23427', fontSize: '22px', fontFamily: 'Garamond-Regular,georgia,serif', marginTop: '50px', textAlign: 'center' }}>Patentability/Novelty Search Video</h3>
      <br></br>
      <br></br>
      <div  style={{ margin:"0 auto", backgroundColor: 'white', maxWidth: '500px', height: '300px' }}>
      <ReactPlayer url='https://www.youtube.com/watch?v=sH8cntG_wNo' className="mobileimage" />
      </div>
      <br></br>
      <br></br>
      <h3 style={{ color: '#b23427', fontSize: '22px', fontFamily: 'Garamond-Regular,georgia,serif', marginTop: '50px', textAlign: 'center' }}>Patent Filing Procedure Overview</h3>
      <br></br>
      <br></br>
      <div style={{ margin:"0 auto", backgroundColor: 'white', maxWidth: '500px', height: '300px' }}>
      <ReactPlayer url='https://www.youtube.com/watch?v=7OeDC4DWNUA' className="mobileimage" />
      </div>
      <br></br>
      <br></br>
      <h3 style={{ color: '#b23427', fontSize: '22px', fontFamily: 'Garamond-Regular,georgia,serif', marginTop: '50px', textAlign: 'center' }}>Trademark Filing Procedure</h3>
      <br></br>
      <br></br>
      <div style={{ margin:"0 auto", backgroundColor: 'white',maxWidth:"500px", height: '300px' }}>
      <ReactPlayer url='https://www.youtube.com/watch?v=YYSC61e1r68' className="mobileimage"/>
      </div>
      {/* Add more videos in a similar manner */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
}

export default CorporateInductionVideo;
