import React, {Fragment} from 'react';
import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
import Features from '../components/Features'
import Services from '../components/Services'
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials/home-one";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Overview from '../components/Overview';
import { Box, Typography } from "@mui/material";
import ImageCarousel from '../components/ImageCarousel';
import CardCarousel from '../components/Cardcarousel'
// import Card from '../components/Card';
import AutoImageCarousel from '../components/AutoImageCarousel';
import CookieConsent from '../pages/CookieConsent';

import Mycard from '../components/Mycard'

const HomeOne = () => {
  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Khurana And Khurana",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );

    return (
        <Fragment>
            <Header/>
            <AutoImageCarousel/>
            <br></br>
             <div>
              <center>
              <Typography variant="h1" style={{ fontFamily: 'Garamond,serif', fontSize: '43px', color: 'brown' }}>
                  Overview
              </Typography>
                </center> 
                <br></br>
          </div>
            <Overview/>
            <br></br>
            <div>
              <center>
              <Typography variant="h1" style={{ fontFamily: 'Garamond,serif', fontSize: '43px', color: 'brown' }}>
                  Exemplary Services
              </Typography>
                </center> 
                <br></br>
         <Services/> 
          </div>
          <br></br>
            <div>
          <center>
      <Typography variant="h1" style={{ fontFamily: 'Garamond,serif', fontSize: '43px', color: 'brown' }}>
        Latest Insights
      </Typography>
      </center>
    </div>
    <div>
           <CardCarousel/>
            {/* Add more instances of Mycard for additional cards */}
        </div>
        
            <div>
        <div>
          <center>
      <Typography variant="h1" style={{ fontFamily: 'Garamond,serif', fontSize: '43px', color: 'brown' }}>
        Awards and Media Coverage
      </Typography>
            <br></br>
      </center>
    </div>
            <br></br>
    <ImageCarousel/>
 
          </div>
            <Footer/>
            {/* <CookieConsent /> */}
            <MobileMenu/>
            <LoginRegister/>
        </Fragment>
    );
};

export default HomeOne;