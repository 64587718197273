import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ConductFreeTradeMarkButton.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LinearLoader from "./LinearLoader";
import html2canvas from "html2canvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

const ConductFreeTradeMarkButton = () => {
  const [trademarks, setTrademarks] = useState([]);
  const [moredetails, setMoreDetails] = useState({});
  const [tradeSearch, setTradeSearch] = useState("");
  const [searchcount, setSearchCount] = useState(0);
  const [moredetailsbtn, setMoreDetailsbtn] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingmore, setLoadingMore] = useState(false);

  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [displayCount, setDisplayCount] = useState(15);
  const [classFilter, setClassFilter] = useState(1);
  const [startWith, setStartWith] = useState("Start with");
  // const handlePrint = (event) => {
  //   event.preventDefault();
  //   window.print();
  // };
  const searchResultsRef = useRef(null);
  const handlePrint = () => {
    const searchResultsNode = searchResultsRef.current;
    if (searchResultsNode) {
      const canvas = document.createElement("canvas");
      canvas.width = searchResultsNode.offsetWidth;
      canvas.height = searchResultsNode.offsetHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.scale(window.devicePixelRatio, window.devicePixelRatio);
        const options = { scale: window.devicePixelRatio };
        html2canvas(searchResultsNode, options)
          .then((canvas) => {
            const imageURL = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = "search_results.png";
            link.click();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const loadMore = async () => {
    setLoadingMore(true);
    setFullyLoaded(false);
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/tm/?wordmark=${tradeSearch}&offset=${displayCount}&limit=15&class_number=${classFilter}&match_with=${startWith}`
      );
      if (response.data && response.data.dataset.length > 0) {
        setTrademarks((prevTrademarks) => [
          ...prevTrademarks,
          ...response.data.dataset,
        ]);
        setDisplayCount(displayCount + 15);
        setLoadingMore(false);
        if (response.data.count <= response.data.offset) {
          setFullyLoaded(false);
        } else {
          setFullyLoaded(true);
        }
      }
    } catch (error) {
      console.error("Error fetching additional trademarks:", error);
    }
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const searchTerm = formData.get("name");
    setTradeSearch(searchTerm);
    setLoading(true);
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/tm/?wordmark=${searchTerm}&offset=0&limit=15&class_number=${classFilter}&match_with=${startWith}`
      );
      if (response.data && response.data.dataset) {
        setTrademarks(response.data.dataset);
        setSearchCount(response.data.count);
        setLoading(false);
        if (response.data.count > response.data.offset) {
          setFullyLoaded(true);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error searching trademarks:", error);
      toast.error("Error: " + error.message);
    }
  };

  const handlegetmoreDetails = async (extradetails, index) => {
    console.log("Got_more_details", extradetails);

    try {
      const response = await axios.post(
        `https://graphdata.khuranaandkhurana.com/tm_detail/`,
        {
          event_validation: extradetails.event_validation,
          more_details_event_target: extradetails.more_details_event_target,
          url: extradetails.url,
          view_state: extradetails.view_state,
        }
      );
      if (response.data) {
        setMoreDetails((prevDetails) => ({
          ...prevDetails,
          [index]: response.data,
        }));
        setExpandedIndex(index);
      }
    } catch (error) {
      console.error("Error searching trademarks:", error);
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {/* <section class="banner">
    <div class="banner-content">
        <h1 style={{color:"white", textAlign:"left", marginLeft:"40px"}}>Trademark Requisition Form</h1>
    </div>
</section> */}
      <div className="maindiv">
        <div className="wrappersearch">
          {/* Search section */}
          <section className="search_section">
            <div className="content">
              <h1 className="trade">Trademark Search</h1>
              <p style={{ color: "rgb(107 114 128 " }}>
                Please enter a Brand Name to search across all NICE/Trade Mark
                Classes
              </p>
            </div>
            <form onSubmit={handleSearch}>
              <div className="search_form">
                <div className="inputfield">
                  <input
                    name="name"
                    className="field"
                    type="text"
                    placeholder="Eg. Apple Pro"
                  />
                </div>
                <div className="search_btn">
                  <button type="submit">SEARCH</button>
                </div>
              </div>
            </form>
          </section>
          {loading && <LinearLoader />}
          {/* Filter section */}
          <section className="filter-section">
            <div className="reg_btn">
              <a href="/trademark-requisition-form" target="_blank">
                <button>Register your Trade Mark</button>
              </a>
            </div>
            <div className="filter_label">
              <div className="filter_by">Filter by</div>
              {/* Render dropdowns with dynamic options based on fetched data */}
              <div className="select-wrapper">
                <FormControl variant="outlined" fullWidth className="no-border">
                  <InputLabel shrink={false} sx={{ display: "none" }}>
                    Class
                  </InputLabel>
                  <Select
                    value={classFilter}
                    onChange={(e) => setClassFilter(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput notched={false} />}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #000",
                        display: "flex",
                        alignItems: "space-around",
                        gap: "1rem",
                      },
                      "& .MuiSvgIcon-root": {
                        marginLeft: "10px",
                      },
                      "&:focus .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span style={{ color: "#00000" }}>Class</span>;
                      }
                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      <span style={{ color: "#000000" }}> Class</span>
                    </MenuItem>
                    {Array.from({ length: 45 }, (_, i) => i + 1).map(
                      (classValue) => (
                        <MenuItem key={classValue} value={classValue}>
                          {classValue}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="select-wrapper" style={{ width: "8rem" }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink={false} sx={{ display: "none" }}>
                    Start with
                  </InputLabel>
                  <Select
                    value={startWith}
                    onChange={(e) => setStartWith(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput notched={false} />}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #000",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "40rem",
                      },
                      "& .MuiSvgIcon-root": {
                        marginLeft: "10rem",
                      },
                      "&:focus .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span style={{ color: "#000000" }}>Start With</span>
                        );
                      }
                      return selected;
                    }}
                  >
                    {/* <MenuItem disabled value="">
            <span style={{ color: '#000000' }}>Start With</span>
          </MenuItem> */}
                    {["Start with", "Contains", "Match with"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </section>

          {/* Output section - Trademark Results */}
          {trademarks.length > 0 && (
            <section className="trademark_section">
              <div
                className="contentspace"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="text" style={{ padding: "1.5rem" }}>
                  Search results for {tradeSearch} :{" "}
                  <span className="txt">{searchcount} trademarks</span>
                </div>
                {/* <div className="Generate_pdf" style={{padding:"1.5rem"}}>
              <a href="#">Generate PDF Report</a>
            </div> */}
                <div className="Generate_pdf" style={{ padding: "1.5rem" }}>
                  <button onClick={handlePrint}>Generate PDF Report</button>
                </div>
              </div>
              <div className="class1">Class {classFilter}</div>
            </section>
          )}

          {/* Render trademark data */}
          {trademarks.length > 0 && (
            <section className="output_section" ref={searchResultsRef}>
              <div className="trademark-list">
                {trademarks.map((trademark, index) => (
                  <>
                    <div key={index} className="trademark-item">
                      <div className="other_portion">
                        <h3
                          style={{
                            fontSize: "1.175rem",
                            fontWeight: "600",
                            lineHeight: "1.75rem",
                          }}
                        >
                          {trademark.wordmark}
                        </h3>
                        <p>
                          <b>Class:</b> {trademark.class}
                        </p>
                        <p>
                          <b>Status:</b> {trademark.status}
                        </p>
                        <p>
                          <b>Application Number:</b>{" "}
                          {trademark.application_number}
                        </p>
                        <p>
                          <b>Applicant Name:</b> {trademark.proprietor}
                        </p>
                        {expandedIndex === index && moredetails[index] ? (
                          <>
                            <p>
                              <b>Application Date:</b>{" "}
                              {moredetails[index]["Application Date"]}
                            </p>
                            <p>
                              <b>Journal Number:</b>{" "}
                              {moredetails[index]["Journal Number"]}
                            </p>
                            <p>
                              <b>Journal Date:</b>{" "}
                              {moredetails[index]["Journal Date"]}
                            </p>
                            <p>
                              <b>Used Since:</b>{" "}
                              {moredetails[index]["Used Since"]}
                            </p>
                            <p>
                              <b>Valid Upto:</b>{" "}
                              {moredetails[index]["Valid Upto"]}
                            </p>
                            <p>
                              <b>Goods and Services:</b>{" "}
                              {moredetails[index]["Goods and Services"]}
                            </p>
                          </>
                        ) : (
                          <button
                            className="moredetails_btn"
                            onClick={() =>
                              handlegetmoreDetails(trademark.extra, index)
                            }
                          >
                            Get More Details...
                          </button>
                        )}
                      </div>
                      <div className="image_portion">
                        {trademark.image ? (
                          <img
                            src={trademark.image}
                            alt="Trademark Logo"
                            width={100}
                            height={100}
                          />
                        ) : (
                          "No Image Found"
                        )}
                      </div>
                      {/* {index !== trademarks.length - 1 && (
                  <hr className="border-line" />
                )} */}
                    </div>

                    <hr style={{ background: "black" }}></hr>
                  </>
                ))}
              </div>
            </section>
          )}
          {loadingmore && <LinearLoader />}

          {fullyLoaded && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    padding: "2px 20px",
                    backgroundColor: "#b23427",
                    color: "white",
                    border: "2px solid #b23427",
                    cursor: "pointer",
                    width: "56.1rem",
                    height: "2rem",
                  }}
                  onClick={loadMore}
                >
                  Load More
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ConductFreeTradeMarkButton;
