import React, { useRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "./ShareTradeMarkButton.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Pdf from "../assets/PDFDownload/POA.doc";
import Pdf2 from "../assets/PDFDownload/templete.doc";
import Pdf3 from "../assets/PDFDownload/Sample.pdf";
import { format } from 'date-fns';


const ShareTradeMarkButton = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedLogoFiles, setUploadedLogoFiles] = useState([]);
  const [uploadedPOAFiles, setUploadedPOAFiles] = useState([]);
  const [formData, setFormData] = useState({
    applicant_name: "",
    applicant_address: "",
    applicant_email: "",
    applicant_phoneno: "",
    word_mark: "",
    word_mark_description: "",
    exising_word_mark: false,
    mark_used_since: null,
    word_mark_or_logo: "word",
    power_of_attorney: uploadedPOAFiles,
    use_details: uploadedFiles,
    power_ofattorney_details: "",
    logo_file: uploadedLogoFiles,
  });
 
  console.log("form_date",formData);
  const formattedDate = formData.mark_used_since 
    ? format(formData.mark_used_since, 'dd-MM-yyyy') 
    : 'Specify date, since when the mark is being used';

  const datePickerRef = useRef(null);
  const useDetailsFileInputRef = useRef(null);
  const poaFileInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const logoFileInputRef = useRef(null);

  const validateForm = () => {
    const {
      applicant_name,
      applicant_address,
      applicant_email,
      applicant_phoneno,
      word_mark,
      word_mark_description,
    } = formData;

    if (
      !applicant_name ||
      !applicant_address ||
      !applicant_email ||
      !applicant_phoneno ||
      !word_mark ||
      !word_mark_description
    ) {
      setMessage("Please fill all the required fields.");
      return true;
    }
    return true;
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      use_details: uploadedFiles,
      power_of_attorney: uploadedPOAFiles,
      logo_file:uploadedLogoFiles,
    }));
  }, [uploadedFiles, uploadedPOAFiles]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setLoading(true);
  
    const formPayload = new FormData();
    const jsonData = {
      applicant_name: formData.applicant_name,
      applicant_address: formData.applicant_address,
      applicant_email: formData.applicant_email,
      applicant_phoneno: formData.applicant_phoneno,
      word_mark: formData.word_mark,
      word_mark_description: formData.word_mark_description,
      exising_word_mark: formData.exising_word_mark,
      mark_used_since: formData.mark_used_since ? formData.mark_used_since.toISOString() : '',
      power_ofattorney_details: formData.power_ofattorney_details,
    };
    
    formPayload.append('data', JSON.stringify(jsonData));
    if (formData.use_details) {
      formData.use_details.forEach((file, index) => {
        formPayload.append(`files.use_details`, file);
      });
    }
  
    if (formData.power_of_attorney) {
      formData.power_of_attorney.forEach((file, index) => {
        formPayload.append(`files.power_of_attorney`, file);
      });
    }

    if (formData.logo_file) {
      formData.logo_file.forEach((file, index) => {
        formPayload.append(`files.logo_file`, file);
      });
    }
  
    fetch("https://khuranaandkhurana-strapi.ntb.one/api/trade-mark-details", {
      method: "POST",
      body: formPayload,
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setMessage(
        "Thank you for the submission. We'll revert back within 24 hours on the Trade Mark Filing Details."
      );
      setLoading(false);
      setFormData({
        applicant_name: "",
        applicant_address: "",
        applicant_email: "",
        applicant_phoneno: "",
        word_mark_or_logo:"word",
        word_mark: "",
        word_mark_description: "",
        exising_word_mark: false,
        mark_used_since: null,
        power_of_attorney: null,
        use_details: null,
        power_ofattorney_details: "",
      });
    })
    .catch((error) => {
      console.error("There was an error with the fetch operation:", error);
      setMessage("There was an error with the submission. Please try again.");
      setLoading(false);
    });
  };
  
  
  

  // const handleChange = (event) => {
  //   const { name, value, type, checked, files } = event.target;
  //   if (name === "use_details_files" && type === "file") {
  //     const newFiles = Array.from(files);
  //     setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   } else if (name === "power_of_attorney" && type === "file") {
  //     const newFiles = Array.from(files);
  //     setUploadedPOAFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   } else {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: type === "checkbox" ? checked : value,
  //     }));
  //   }
  // };
  console.log("uploaded_files_state:", uploadedFiles, uploadedPOAFiles);
  const handleChange = (event) => {
    const { name, files, type, checked, value } = event.target;
    console.log("files_handle_change", files);
    if (name === "use_details_files" && type === "file") {
      const newFiles = Array.from(files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else if (name === "power_of_attorney" && type === "file") {
      const newFiles = Array.from(files);
      setUploadedPOAFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else if (name === "logo_file" && type === "file") {
      const newFiles = Array.from(files);
      setUploadedLogoFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      mark_used_since: date,
    }));
  };

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleRemovePOAFile = (index) => {
    setUploadedPOAFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const handleRemoveLogoFile = (index) => {
    setUploadedLogoFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };


  return (
    <>
      <Header />
      <section className="banner">
        <div className="banner-content">
          <h1 style={{ color: "white", textAlign: "left", marginLeft: "40px" }}>
            Trademark Requisition Form
          </h1>
        </div>
      </section>
      <div className="wrapper">
        <p
          style={{
            fontSize: "24px",
            fontWeight: "400",
            fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
            letterSpacing: "0.00938em",
            lineHeight: "1.5",
          }}
        >
          Trademark Requisition Form
        </p>
        {/* Applicant details section */}
        <section className="applicant_deatils_section">
          <form>
            <div className="btn_list1">
              <button
                type="button"
                style={{
                  height: "40%",
                  width: "100%",
                  fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
                  fontSize: "0.8125rem",
                  verticalAlign: "middle",
                  alignItems: "center",
                }}
              >
                Applicant Details
              </button>
            </div>
            <div className="applicant_detail">
              <input
                name="applicant_name"
                type="text"
                placeholder="Applicant Name"
                value={formData.applicant_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="applicant_detail">
              <input
                type="text"
                name="applicant_address"
                placeholder="Applicant Address"
                value={formData.applicant_address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="applicant_detail">
              <input
                type="email"
                name="applicant_email"
                placeholder="Email Address"
                value={formData.applicant_email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="applicant_detail">
              <input
                type="number"
                name="applicant_phoneno"
                placeholder="Phone No."
                value={formData.applicant_phoneno}
                onChange={handleChange}
                required
              />
            </div>
          </form>
        </section>

        {/* Mark details section */}
        <section className="mark_details_section">
          <div className="mark_details_section_wrapper">
            <form className="form section">
              <div className="btn_list2">
                <button
                  type="button"
                  style={{
                    height: "40%",
                    width: "100%",
                    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
                    fontSize: "0.8125rem",
                    verticalAlign: "middle",
                    alignItems: "center",
                  }}
                >
                  Mark Details
                </button>
              </div>
              <div className="mark_details">
                <div className="world_mark">
                  {/* Word Mark
                  <select className="custom-select"></select>
                </div>
                <div className="mark_text_area">
                  <input
                    type="text"
                    name="word_mark"
                    placeholder="Please enter the word mark here"
                    value={formData.word_mark} */}
                     <select
                    className="custom-select"
                    name="word_mark_or_logo"
                    value={formData.word_mark_or_logo}
                    onChange={handleChange}
                    required
                  >
                      <option value="word">Word Mark</option>
                    <option value="logo">Logo/Device Mark</option>
                  </select>
                </div>
                {formData.word_mark_or_logo === "word" ? (
                  <div
                    className="mark_text_area"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <textarea
                      name="word_mark"
                      placeholder="Please enter the word mark here"
                      value={formData.word_mark}
                      onChange={handleChange}
                      required
                      style={{
                        resize: "none",
                        paddingTop: "10px",
                        paddingBottom: "60px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        borderRadius: "5px",
                        border: "1px solid rgb(191, 188, 188)",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="mark_upload_area"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <label htmlFor="logodevidemark" className="fileUpload">
                      Drag 'n' drop Logo/Device Mark here, or click to select
                      file.
                      <span className="upload-instruction">
                        Please upload an image file such as .jpg or .png or .pdf
                      </span>
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      name="logo_file"
                      id="logodevidemark"
                      onChange={handleChange}
                      accept=".pdf, .docx, .doc, .zip, .jpeg, .png, .jpg"
                      ref={logoFileInputRef}
                      required
                    />
                  </div>
                )}
              </div>

              {uploadedLogoFiles.length > 0 && (
                <div className="uploaded-files">
                  <table className="uploaded-files-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>File Name</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedLogoFiles.map((file, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{file.name}</td>
                          <td>
                            <button onClick={handleRemoveLogoFile}>X</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
                {/* </div>
              </div> */}
              <div className="description">
                <textarea
                  name="word_mark_description"
                  placeholder="Please enter description of goods/services for which the mark is intended to be used"
                  value={formData.word_mark_description}
                  onChange={handleChange}
                  required
                  style={{
                    resize: "none",
                    paddingTop: "10px",
                    paddingBottom: "60px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "5px",
                    border: "1px solid rgb(191, 188, 188)",
                  }}
                />
              </div>
            </form>
          </div>
        </section>

        {/* Use details section */}
        <section className="use_details_section">
          <form className="form_section">
            <div className="btn_list3">
              <button
                type="button"
                style={{
                  height: "40%",
                  width: "100%",
                  fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
                  fontSize: "0.8125rem",
                  verticalAlign: "middle",
                  alignItems: "center",
                }}
              >
                Use Details
              </button>
            </div>
            <div className="use-details_div">
              <div className="use_details">
                <input
                  style={{ width: "35px", height: "20px", color: "gray" }}
                  type="checkbox"
                  name="exising_word_mark"
                  checked={formData.exising_word_mark}
                  onChange={handleChange}
                />
                <span className="checkmark">
                  Are you already using the mark? (Leave it unchecked if the
                  mark is Proposed to be used)
                </span>
              </div>
              {/* New date input section */}
              {formData.exising_word_mark && (
                <div className="date-picker">
                  <div className=" MuiFormControl-root MuiTextField-root">
                    {formData.mark_used_since ? null :  (
                    <label
                      className="space MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated"
                      onClick={openDatePicker}

                    >
                      Specify date, since when the mark is being used
                    </label>
                    )}
                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                      <DatePicker
                        ref={datePickerRef}
                        className="MuiInputBase-input MuiInput-input date_picker"
                        selected={formData.mark_used_since}
                        onChange={handleDateChange}
                        dateFormat="dd-MM-yyyy"
                        placeholderText=""
                        wrapperClassName="datePickerWrapper"
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* <hr /> */}
              {/* Add feature if checkbox is clicked */}
              {formData.exising_word_mark && (
                <div className="use-evidences">
                  <p>
                    <a
                      className="download-link"
                      href={Pdf2}
                      target="_blank"
                      // download="UserAffidavitTemplate.doc"
                    >
                      Download User Affidavit Template
                    </a>
                    | Please Complete, Execute, and Upload the Used Affidavit
                    Below
                  </p>
                  <p style={{width:"38rem", marginTop:"-10px"}}>
                    (See a Sample Executed User Affidavit{" "}
                    <a
                      className="download-link"
                      href={Pdf3}
                      target="_blank"
                      // download="UserAffidavitTemplate.doc"
                    >
                      Here
                    </a>
                    )
                  </p>
                  <p style={{ marginTop:"20px"}}>
                    Upload Use Evidences (Invoices, Advertisement Material,
                    Brochures, Catalogs, and the like)
                  </p>
                  <section className="upload-box">
                    <input
                      accept=".pdf,.docx,.doc,.zip"
                      multiple
                      type="file"
                      onChange={handleChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <p className="checkmark">
                      <label
                        className="fileUpload"
                        htmlFor="useDetailsFileInputRef"
                      >
                        One or Multiple at a time. Drag 'n' drop file here, or click to Select File(s)
                        <br/>
                        <span className="upload-instruction">
                          Please Upload Evidence of Use for Each Year of Use.
                        </span>
                      </label>
                      <input
                        id="useDetailsFileInputRef"
                        name="use_details_files"
                        type="file"
                        onChange={handleChange}
                        accept=".pdf, .docx, .doc, .zip, .jpeg, .png, .jpg"
                        style={{ display: "none" }}
                        ref={useDetailsFileInputRef}
                      />
                    </p>
                  </section>

                  {uploadedFiles.length > 0 && (
                    <div className="uploaded-files">
                      <table className="uploaded-files-table">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Filename</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedFiles.map((file, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{file.name}</td>
                              <td>
                                <button onClick={() => handleRemoveFile(index)}>
                                  X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* Display uploaded files in a table */}
            </div>
          </form>
        </section>

        {/* Power of attorney section */}
        <section className="Power_of_Attorney_section">
          <form onSubmit={handleSubmit}>
            <div className="btn_list4">
              <button
                type="button"
                style={{
                  height: "40%",
                  width: "100%",
                  fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
                  fontSize: "0.8125rem",
                  verticalAlign: "middle",
                  alignItems: "center",
                }}
              >
                Power of Attorney
              </button>
            </div>
            <div className="link_tag">
              <a href={Pdf} target="_blank">
                <p className="checkmark">
                  Download Power of Attorney template{" "}
                </p>
              </a>
            </div>
            <p className="checkmark">Upload Power of Attorney</p>
            <div className="fileUpload">
              <label htmlFor="poaFileUpload">
                Drag 'n' drop Power of Attorney file here, or click to select
                file
              </label>
              <input
                // id="fileUpload"
                id="poaFileUpload"
                name="power_of_attorney"
                type="file"
                onChange={handleChange}
                accept=".pdf, .docx, .doc, .zip, .jpeg, .png, .jpg"
                style={{ display: "none" }}
                ref={poaFileInputRef}
              />
            </div>
            {uploadedPOAFiles.length > 0 && (
              <div className="uploaded-files">
                <table className="uploaded-files-table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Filename</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedPOAFiles.map((file, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{file.name}</td>
                        <td>
                          <button onClick={() => handleRemovePOAFile(index)}>
                            X
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="description des2">
              <textarea
                name="power_ofattorney_details"
                placeholder="Please enter additional detail/comment that you may like to share, if any"
                value={formData.power_ofattorney_details}
                onChange={handleChange}
                style={{
                  resize: "none",
                  paddingTop: "10px",
                  paddingBottom: "60px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  borderRadius: "5px",
                  border: "1px solid rgb(191, 188, 188)",
                }}
              />
            </div>
            {message && (
              <div
                className="message-box"
                style={{
                  padding: "15px",
                  borderRadius: "5px",
                  marginBottom: "20px",
                  color: "black",
                  background: "rgb(220, 255, 234)",
                }}
              >
                {message}
              </div>
            )}
            <div className="submit_button">
              {!loading && (
                <button
                  type="submit"
                  style={{
                    width: "7rem",
                    height: "2.5rem",
                    borderRadius: "5px",
                    color: "gray",
                  }}
                >
                  SUBMIT
                </button>
              )}
            </div>
          </form>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ShareTradeMarkButton;

