import React,{Fragment} from 'react';
import {Box,Typography} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
const NewsLetterSubscription = () => {
  return (
    <Fragment>
         <Header/>
         <br></br>
         <br></br>
         <br></br>
         <br></br>
        <Typography style={{alignItems:"center",justifyContent:"center",textAlign:"center",fontSize:"20px",fontFamily:"HelveticaNeue-Roman, Arial, Helvetica, sans-serif",color:"#425563"}}>
            Your subscription has been confirmed
        </Typography>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
         <Footer/>
        </Fragment>
  )
}

export default NewsLetterSubscription