import { useState, useEffect } from "react";
import "../components/AutoImageCarousel.scss";
import { Link } from "react-router-dom";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const AutoImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/auto-image-carousels"
        );
        setData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data?.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [data]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="image-container-autoimage">
       {loading ? (
          <Skeleton height={500} /> 
        ) : (
          <>
      <img
        src={data && data[currentIndex]?.attributes?.imagePath}
        alt={data && data[currentIndex]?.attributes?.caption}
      />
      <div className="dots-container">
        {data &&
          data.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
      </div>
      <div to={`/image/${currentIndex + 1}`}>
        {/* <img
          src={data && data[currentIndex].attributes.src}
          alt={`Image ${currentIndex + 1}`}
        /> */}
        <center>
          <div className="caption">
            {/* <a
              href={data && data[currentIndex]?.attributes?.linkUrl}
              style={{ color: "#b23427" }}
            >
              {data && data[currentIndex]?.attributes?.caption}
            </a> */}
          </div>
          <br />
          <div className="text1">
            <a
              // href={data && data[currentIndex]?.attributes?.textLines}
              style={{ color: "white" }}
            >
              {data && data[currentIndex]?.attributes?.description}
            </a>
          </div>
          <div className="text3">
            <a
              href={data && data[currentIndex]?.attributes?.linkUrl}
              style={{ color: "#b23427"}}
            >
              {data && data[currentIndex]?.attributes?.linkText}
              <ArrowRightAltRoundedIcon />
            </a>
          </div>
        </center>
      </div>
      </>
        )}
    </div>
  );
};

export default AutoImageCarousel;
