import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
function ScrollDownTypoGraphy({ children, ...props }) {
  return (
    <a href={props.link} style={{ textDecoration: "none" }}>
      <Typography {...props}>{children}</Typography>
    </a>
  );
}

function SelectedService({ service, toggleService }) {
  return (
    <div
      style={{
        backgroundColor: "rgba(244,241,241,0.776)",
        minHeight: "200px",
        padding: "36px 42px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10} sm={9} md={10}>
        <a href={service?.attributes?.link}>
          <ScrollDownTypoGraphy
            style={{
              color: "#f60c0c",
              textTransform: "capitalize",
              fontSize: "20px",
              cursor:"pointer",
            }}
          >
            {service.attributes.title}
          </ScrollDownTypoGraphy>
          </a>
          <ScrollDownTypoGraphy
            style={{
              color: "black",
              textAlign: "justify",
              fontSize: "15px",
            }}
          >
            {service.attributes.shortDesc}
          </ScrollDownTypoGraphy>
        </Grid>
        <Grid
          item
          xs={2}
          sm={3}
          md={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6} style={{ fontSize: "60px" }}>
              <KeyboardArrowUpIcon
                fontSize="inherit"
                onClick={() => toggleService(-1)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ fontSize: "60px" }}>
              <KeyboardArrowDownIcon
                fontSize="inherit"
                onClick={() => toggleService(1)}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

// ... (imports)

function Services() {
  const [currentService, setCurrentService] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/services"
        );
        setData(response.data.data);
        setLoading(false);
        // console.log("Exemplary Services from Strapi:", response.data.data);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);

  const toggleService = (delta) => {
    if (data) {
      setCurrentService((cs) => {
        let ncs = (cs + delta) % data.length;
        return ncs < 0 ? data.length + ncs : ncs;
      });
      setIsVisible(false);
      setTimeout(() => {
        setIsVisible(true);
      }, 400);
    }
  };

  const fadeAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 400 },
  });

  return (
    <div key={currentService}>
      <>
       {loading ? (
           <div>
          <Skeleton height={500} /> 
           </div>
        ) : (
      <animated.div style={fadeAnimation}>
        
        {data && data[currentService] && (
          <div
            style={{
              backgroundImage: `url(${data[currentService].attributes.thumb})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={3} md={6} />
              <Grid item xs={12} sm={6}>
                <div>
                <SelectedService
                    service={data[currentService]}
                    toggleService={toggleService}
                  />
                  {data &&
                    data
                      .slice(currentService + 1, data.length)
                      .map((service) => (
                        <ScrollDownTypoGraphy
                          key={service.id}
                          link={service.attributes.link}
                          component={"div"}
                          style={{
                            padding: "20px",
                            color: "white",
                            textTransform: "capitalize",
                            fontWeight: "600",
                            fontSize: "1rem",
                          }}
                        >
                          {service.attributes.title}
                        </ScrollDownTypoGraphy>
                      ))}
                  {data &&
                    data.slice(0, currentService).map((service) => (
                      <ScrollDownTypoGraphy
                        key={service.id}
                        link={service.attributes.link}
                        component={"div"}
                        style={{
                          padding: "20px",
                          color: "white",
                          textTransform: "capitalize",
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        {service.attributes.title}
                      </ScrollDownTypoGraphy>
                    ))}
                  {/* <SelectedService
                    service={data[currentService]}
                    toggleService={toggleService}
                  /> */}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </animated.div>
        )}
      </>
    </div>

  );
}

export default Services;
