import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ShareIcon from "@mui/icons-material/Share";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import "./CareerAndInternship.css"
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaFacebook, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";
const CareerInnerPage = () => {
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [isShareVisible, setIsShareVisible] = useState(jobs.map(() => false));
  const [jobsData, setjobsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/find-a-vaccancies?populate=*"
        );
        setjobsData(response?.data?.data);
        
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);
  console.log("jobsData",jobsData);
  console.log("jbosimg",jobsData[0]?.attributes?.image?.data?.attributes?.url);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions(isExpanded ? panel : false);
  };

  const handleShareClick = (index) => {
    setIsShareVisible(
      isShareVisible.map((visible, i) => (i === index ? !visible : visible))
    );
  };


  const url = window.location.href;
  return (
    <div>
      <Header />
      <br />
      <br />
      <div
        className="MainDiv1 mobilecareer"
        style={{
          width: "1000px",
          height: "auto",
          background: "#f3f3f3",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "20px",
        }}
      >
        <div>
          {jobsData?.map((job, index) => (
            <div
            className="mobilecre"
              key={index}
              style={{
                width: "900px",
                height: "auto",
                background: "white",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                marginBottom: "20px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3
                  style={{
                    fontSize: "20px",
                    fontFamily: "Arial, Arial, Arial, sans-serif",
                    color: "#569bbd",
                    paddingLeft: "30px",
                    paddingTop: "30px",
                  }}
                >
                  {job?.attributes?.title}
                </h3>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "0",
                    height: "0",
                    borderLeft: "50px solid transparent",
                    borderBottom: "50px solid white",
                    background: "#f3f3f3",
                    transform: "rotate(90deg)",
                    cursor:"pointer"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   alignItems: "center",
                    }}
                    onClick={() => handleShareClick(index)}
                  >
                    <ShareIcon
                      style={{
                        fontSize: "large",
                        marginTop: "1px",
                        marginLeft: "-40px",
                        marginRight: "-5px",
                        transform: "rotate(270deg)",
                        color: "#569bbd",
                      }}
                    />

                    {isShareVisible[index] && (
                      <div
                        style={{
                          transform: "rotate(-90deg)",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          marginTop:"130px"
                        }}
                      >
                        <FacebookShareButton
                          url={url}
                          quote={`Check out this profile:  `}
                          style={{ width: "auto" }}
                        >
                          <FaFacebook
                            size={32}
                            round
                            color="rgb(86, 155, 189)"
                          />
                        </FacebookShareButton>
                        <TwitterShareButton
                          className="twitter"
                          url={url}
                          title={`Check out this profile:  `}
                          style={{ width: "auto" }}
                        >
                          <TwitterIcon
                            size={35}
                            round
                            style={{
                              fill: "rgb(86, 155, 189)",
                              width: "35px",
                              height: "35px",
                            }}
                          />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          url={url}
                          title="Check out this profile:  "
                          style={{ width: "auto" }}
                        >
                          <FaWhatsapp
                            size={32}
                            round
                            color="rgb(86, 155, 189)"
                          />
                        </WhatsappShareButton>
                        <LinkedinShareButton
                          url={url}
                          title={`Check out this profile:  `}
                          style={{ width: "auto" }}
                        >
                          <FaLinkedin
                            size={32}
                            round
                            color="rgb(86, 155, 189)"
                          />
                        </LinkedinShareButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  width: "auto",
                  height: "auto",
                  background: "#f3f3f3",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20px",
                }}
              >
                <div>
                  <Accordion
                    expanded={expandedAccordions === index}
                    onChange={handleAccordionChange(index)}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon style={{ color: "#569bbd" }} />
                      }
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography
                        style={{ paddingLeft: "15px", color: "#569bbd" }}
                      >
                        Show more
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {expandedAccordions === index && 
                      (
                        <img
                          src={`https://khuranaandkhurana-strapi.ntb.one${job?.attributes?.image?.data?.attributes?.url}`}
                          alt="Accordion Image"
                          style={{
                            width: "100%",
                            zIndex: 9999,
                            position: "relative",
                          }}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br></br>
      </div>

      <br />
      <br />
      <Footer />
    </div>
  );
};

const jobs = [
  { title: "Patent Attorney" },
  { title: "Patent Drafting Professional" },
  { title: "Patent Searching Professional" },
  { title: "Executive Assistant/ Paralegal/ Secretary" },
  { title: "US Based Senior IP Business Development Professional" },
  { title: "Commercial/ Corporate Attorney" },
  { title: "Business Development Specialist- Trademarks" },
  { title: "Business Development/ Sales Professional" },
  { title: "Front-End Software Developer" },
  // Add more job objects as needed
];
const accordionImages = [
  { image: "aaaa.jpg", title: "Patent Attorney" },
  { image: "bbbb.jpg", title: "Patent Drafting Professional" },
  { image: "cccc.jpg", title: "Patent Searching Professional" },
  { image: "dddd.jpg", title: "Executive Assistant/ Paralegal/ Secretary" },
  {
    image: "eeee.jpg",
    title: "US Based Senior IP Business Development Professional",
  },
  { image: "ffff.jpg", title: "Commercial/ Corporate Attorney" },
  { image: "gggg.jpg", title: "Business Development Specialist- Trademarks" },
  { image: "hhhh.jpg", title: "Business Development/ Sales Professional" },
  { image: "iiii.jpg", title: "Front-End Software Developer" },
  // Add more image objects as needed
];

export default CareerInnerPage;
