import React, { useRef, useState, useEffect } from 'react';
import Mycard from '../components/Mycard'
import '../components/CardCarousel.scss'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@mui/material/Grid';
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const Imagecarousel = () => {
    const [idPost, setidPost] = useState({insightsPosts: []});
    const [loading, setLoading] = useState(true);

    function formatDate(dateString) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
    const sliderRef = useRef(null);

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5, 
      slidesToScroll: 1,
      responsive: [
         {
           breakpoint: 650, 
           settings: {
             slidesToShow: 1, 
             slidesToScroll: 1,
             infinite: true,
             dots: false
           }
         },
         {
          breakpoint: 768, 
          settings: {
            slidesToShow: 2, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1025, 
          settings: {
            slidesToShow: 3, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1200, 
          settings: {
            slidesToShow: 4, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1450, 
          settings: {
            slidesToShow: 5, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1850, 
          settings: {
            slidesToShow: 6, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1950, 
          settings: {
            slidesToShow: 6, 
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },

      ]
     };
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await fetch(`https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?populate=*&filters[post_status]=publish&pagination[start]=0&pagination[limit]=10&sort=createdAt:desc`);
          const data = await response.json();
          console.log("res_data", data?.data);
    
          if (Array.isArray(data?.data)) {
            console.log("entered_array_if_loop_gcv");
    
            const insightsPosts = data?.data?.filter(item => {
              const attributes = item?.attributes;
              return attributes &&  attributes?.post_status === 'publish';
            });
    
            console.log("insight_posts_jv", insightsPosts);
    
            insightsPosts.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
    
            const latestPosts = insightsPosts.slice(0, 12);
    
            console.log("latest_posts", latestPosts);
    
            setidPost(prev => ({
              ...prev,
              insightsPosts: latestPosts
            }))
            setLoading(false);
          } else {
            console.error('Unexpected data structure:', data);
          }
        } catch (error) {
          console.error('Failed to fetch posts:', error);
        }
      };
    
      fetchPosts();
    }, []);

    const btnpressprev = () => {
      if (sliderRef.current) {
          sliderRef.current.slickPrev(); 
      }
  };

  const btnpressnext = () => {
      if (sliderRef.current) {
          sliderRef.current.slickNext(); 
      }
  };
      return (
        <div className="product-carousel">
          <button className="pre-btn" onClick={btnpressprev} style={{backgroundColor:"white",border:"1px solid white"}}>
            <ArrowBackIosNewIcon
              style={{ zIndex: '100', color: '#b23427', marginLeft: '-60px', position: 'absolute',backgroundColor:"white"}}
            />
          </button>
          <button className="next-btn" onClick={btnpressnext} style={{backgroundColor:"white",border:"1px solid white"}}>
            <ArrowForwardIosIcon
              style={{ zIndex: '100', color: '#b23427', position: 'absolute', marginRight: '-40px' }}
            />
          </button>
          {loading ? (
           <div style={{display:"flex",gap:"10px"}}>
           <Skeleton height={350} width={300} /> 
           <Skeleton height={350} width={300} /> 
           <Skeleton height={350} width={300} /> 
           <Skeleton height={350} width={300} /> 
           <Skeleton height={350} width={300} /> 
 
           </div>
        ) : (
          <Slider ref={sliderRef} {...settings}>
            {idPost?.insightsPosts?.map((card, index) => (
              <div key={index}>
                <Grid item xs={12} sm={6} md={3}>
                 <Mycard
                    logo={card?.attributes?.featuredImage?.data?.attributes?.url}
                    title={card?.attributes?.post_title}
                    date={formatDate(card?.attributes?.post_date)}
                    // link={`/insights/${card?.id}`}
                    postname = {`/insights/${card?.attributes?.post_name}`}
                 />
                </Grid>
              </div>
            ))}
          </Slider>
        )}
        </div>
      );
    
    }
     
    export default Imagecarousel;