import React,{useState,Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/page";
import TeamMember from '../templates/Team'
import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import {Typography} from "@mui/material";
import ServiceThumb from '../assets/img/about.jpg'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Button } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const PageTeam = () => {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
    const allNames = [
     // "Alice", "Bob", "Charlie", "David",
      ];
      
    
      const [filteredNames, setFilteredNames] = useState(allNames);
    
      const handleFilter = (letter) => {
        const filtered = allNames.filter((name) =>
          name.toUpperCase().startsWith(letter)
        );
        setFilteredNames(filtered);
      };
      const NameList = ({ names }) => {
        return (
          <ul>
            {names.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        );
      };
      const NameFilter = ({ allNames, onFilter }) => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      
        return (
          <div >
            {letters.split("").map((letter) => (
              <button style={{justifyContent:"space-between",marginInline:"10px"}} key={letter} onClick={() => onFilter(letter)}>
                {letter}
              </button>
            ))}
          </div>
        );
      };
      
    return (
        <Fragment>
            <Header/>
            <Typography variant="h1" style={{ fontFamily: 'Garamond,serif', fontSize: '70px', color: 'brown',marginLeft:"100px",marginTop:"30px" }}>
        People
     </Typography>
     <br></br>
     <br></br>
     <br></br>
    
     <Box
sx={{
width: 1100,
fontSize:"large",
maxWidth: '100%',
marginLeft:"100px",
}}
>
<TextField fullWidth label="" id="fullWidth" variant='standard' placeholder='Search by name or keyword'  />
<br></br>
<br></br>
<div style={{display:"flex"}}>
<div>
     <FormControl variant="filled" sx={{ m: 1, minWidth: 300 ,'&:hover': {backgroundColor:"brown"}}} style={{border:"1px solid brown"}}>
        <InputLabel placeholder="Practice" style={{color:"brown",'&:hover': {backgroundColor:"brown"},'&:hover': {color:"white"}}}>Practice</InputLabel>
        <Select 
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={age}
          onChange={handleChange}
        >
          <MenuItem  style={{backgroundColor:"brown",color:"white"}}value="" >
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      </div>
      <div>
     <FormControl variant="filled" sx={{ m: 1, minWidth: 300 ,'&:hover': {backgroundColor:"brown"}}} style={{border:"1px solid brown"}}>
        <InputLabel placeholder="Offices" style={{color:"brown",'&:hover': {backgroundColor:"brown"},'&:hover': {color:"white"}}}>Offices</InputLabel>
        <Select 
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={age}
          onChange={handleChange}
        >
          <MenuItem  style={{backgroundColor:"brown",color:"white"}}value="" >
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      </div>
      </div>
</Box>
<br></br>
<div>
<Typography variant="h1" style={{display:"flex",fontFamily: 'Garamond,serif', fontSize: '25px',color:"brown",marginLeft:"100px",marginTop:"30px" }}>
        Filter by surname
        <NameFilter allNames={allNames} onFilter={handleFilter} />
      <NameList names={filteredNames} />
     </Typography>
    
     
    </div>
           
            
            {/* <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="KNOW ABOUT BUSINEX THE ULTIMATE TEAM"
                content="Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
            />
            <About
                title={'Our Team'}
                heading="Meet Our <br/> Expert Member"
                thumb={ServiceThumb}
                content="<b>Businex</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
            /> */}
            <TeamMember/>
            <br></br>
            <br></br>
            <center>
            <Button style={{border:"2px solid white",color:"white",textAlign:'center',backgroundColor:"#B23427",marginTop:"14px",alignItems:"center",justifyContent:"center",'&:hover': {color:"white"}}} variant="contained">Load More</Button>
            </center>
            <br></br>
            <br></br>
            {/* <BrandLogo/>
            <Funfact classes="sp-top"/> */}
            {/* <CallToAction/> */}
            <Footer/>
            {/* <LoginRegister/>
            <MobileMenu/> */}
        </Fragment>
    );
};

export default PageTeam;