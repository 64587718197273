import React, { useEffect } from 'react';

const Feedback = () => {
  useEffect(() => {
    // Redirect the user to the Google Form URL immediately when the component mounts
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfUL7C2RSvnkXsyG6ObUdf31ttr_f2fBhQjKxkxkUGW6Ws86A/viewform';
  }, []); // Empty dependency array ensures that the effect runs once after the initial render

  // Empty div or any other content you want to display (optional)
  return <div></div>;
};

export default Feedback;
