import React, { Fragment, useState, useEffect } from "react";
import Pagination from "../Pagination";
import InsightItemList from "./insightsItemList";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import { FadeLoader } from "react-spinners";

import "./insightlist.css";

const InsightsList = (props) => {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const sanitizedContent = DOMPurify.sanitize(props.post_content);

  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const [totalPages, setTotalPages] = useState(0);
  const [state, setState] = useState({
    posts: [],
    currentPage: 1,
    postsPerPage: 10,
  });
  const [loading, setLoading] = useState(true);

  const searchQuery = query.get("q")?.toLowerCase();

  const catsearchQuery = query.get("categories")?.toLowerCase();

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        let apiUrl =
          "https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?populate=*&filters[post_status]=publish";
        let paginationParams = "&pagination[start]=0&pagination[limit]=10&sort=createdAt:desc";

        if (searchQuery) {
          apiUrl +=
            `&filters[post_title][$containsi]=${searchQuery}` + paginationParams;
        } else if (catsearchQuery) {
          apiUrl += `&filters[categories][$containsi]=${catsearchQuery}` + paginationParams;
        }
        else {
            apiUrl +=paginationParams;
        }

        const response = await fetch(apiUrl);
        const data = await response.json();

        if (Array.isArray(data?.data)) {
          setState((prevState) => ({
            ...prevState,
            posts: data.data,
          }));
          setTotalPages(data.meta.pagination.total);
        } else {
          console.error("Unexpected data structure:", data);
        }
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, [query, searchQuery]);

  const paginate = async (currentPage) => {
    try {
      let paginationParams = `&pagination[start]=${(currentPage - 1) * 10}&pagination[limit]=10&sort=createdAt:desc`;
  
      let apiUrl = `https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?populate=*&filters[post_status]=publish`;

      if (searchQuery) {
        apiUrl +=
          `&filters[post_title][$containsi]=${searchQuery}` + paginationParams;
      } else {
          apiUrl +=paginationParams;
      }
  
      const response = await fetch(apiUrl);
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
      setState((prevState) => ({
        ...prevState,
        posts: data.data,
        currentPage: currentPage,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  return (
    <div className={props.cols + " " + props.classes}>
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <Fragment>
          <div
            className={`blog-content-wrapper ${
              props.blog_type === "list" && "blog-list"
            }`}
            style={{
              fontFamily: "Garamod,serif",
              color: "brown",
              fontSize: "24.225px",
            }}
          >
            <div className="row mtn-30">
              {props.blog_type === "list" ? (
                <div
                  className={"col-12"}
                  style={{
                    fontFamily: "Arial,Helvetica,sans-serif",
                    color: "#425563",
                    fontSize: "15px",
                  }}
                >
                  {state.posts?.map((post) => (
                    <InsightItemList
                      key={post?.id}
                      id={post?.id}
                      thumb={post?.attributes?.postImage}
                      title={post?.attributes?.post_title}
                      postName={post?.attributes?.post_name}
                      date={post?.attributes?.post_date}
                      postBy={post?.attributes?.post_author}
                      excerpt={post?.attributes?.post_excerpt}
                      description={sanitizedContent}
                      category={post?.attributes?.categories?.replace(
                        /&amp;/g,
                        ""
                      )}
                      metaTitle={post?.attributes?.metaTitle}
                      metaDescription={post?.attributes?.metaDescription}
                    />
                  ))}
                </div>
              ) : (
                state.posts.map((blog) => (
                  <InsightItemList
                    key={blog?.id}
                    id={blog?.id}
                    thumb={blog?.attributes?.thumbnail}
                    title={blog?.attributes?.post_title}
                    date={blog?.attributes?.post_date}
                    postBy={blog?.attributes?.post_author}
                    excerpt={blog?.attributes?.post_excerpt}
                  />
                ))
              )}
            </div>
          </div>
          <div
            className="pagescroll"
            style={{
              maxWidth: "100%",
            }}
          >
            <Pagination
              paginate={paginate}
              totalPosts={totalPages}
              currentPage={state.currentPage}
              postsPerPage={state.postsPerPage}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default InsightsList;


