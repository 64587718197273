import { TextField } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import * as React from "react";
import { useClickAway } from "react-use";
import { useState, useEffect } from "react";
// import Logo from "../../assets/img/KnK Logo.png";
import Logo from "../../assets/img/LOGOIMAGE.png";
import NavbarItem from "./Navbar/NavbarItem";
import { InputAdornment } from "@mui/material";
import Slider from "./Slider";
import HeaderConfig from "./HeaderConfig";
import { yellow } from "@material-ui/core/colors";
import "../../components/Header/Homepage.css";
import { useHistory } from "react-router-dom";
const searchStyle = `
.MuiInput-underline::before {
  border-bottom: 1px solid #b23427 !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #b23427 !important;
}`;

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [ishamOpen, sethamOpen] = React.useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => sethamOpen(false));
  
  const iconStyle = {
    color: isMouseOver ? "#425563" : "#b23427",
    paddingLeft: "260px",
    overflow: "hidden",
    background: "white",
    width: "300px",
    height: "270px",
    marginTop: "-100px",
    transition: "color 0.3s ease",
    marginRight:"100px"
  };
  const iconStyle1 = {
    color: isHovered ? "#b23427" : "#425563", // Set initial and hover color
    paddingLeft: "140px",
    width: "200px",
    height: "60px",
    marginTop: "-10px",
    background: "white",
  };
  const [slider, setSlider] = useState({
    width: "0px",
    offset: "0px",
    show: false,
  });
  const [search, setSearch] = useState("");
  const history = useHistory();
  const handleLogoClick = () => {
    history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (ishamOpen) {
        sethamOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
 }, [ishamOpen, sethamOpen]);

  // const [isBool, setIsBool] = useState(false);
  const onClickHandler = () => {
    // setIsBool(!isBool)
    history.push("/global-search?q=" + search);
  };

  const updateSlider = (element) => {
    setSlider({
      offset: element.getBoundingClientRect().left + "px",
      width: element.getBoundingClientRect().width + "px",
      show: true,
    });
  };
  const removeSlider = () => {
    setSlider({
      offset: "0px",
      width: "0px",
      show: false,
    });
  };
  const LoginRegHandler = () => {
    const offCanvasConfig = document.querySelector(".off-canvas-cog");
    offCanvasConfig.classList.add("active");
    document.querySelector("body").classList.add("fix");
  };

  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector(".off-canvas-menu");
    offCanvasMenu.classList.add("active");
  };
  return (
    <div style={{ borderBottom: "1px solid #e7eaeb" }}>
      <AppBar
        position="static"
        style={{ background: "white", paddingTop: "5px" }}
        onMouseLeave={removeSlider}
      >
        {/* <div
          style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingBottom: "5px",
          }}
        >
          <a href="https://iiprd-khuranaandkhurana-cms.com/">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#b23427 ",
                color: "white",
                borderRadius: "20px",
                fontSize: "15px",
                width: "auto",
                marginRight: "17px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid #b23427",
                  color: "#b23427",
                },
              }}
            >
              Client Login
            </Button>
          </a>
        </div> */}
        <Slider {...slider} />
        <Container maxWidth="xl" style={{ height: "20%" }}>
          <Toolbar style={{ marginTop: "1px" }} disableGutters>
            <img
            className="mobilelogo"
              src={Logo}
              style={{ width: "25%", paddingBottom: "10px", cursor: "pointer" }}
              onClick={handleLogoClick}
            ></img>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                color: "white",
                marginLeft: "70px",
              }}
            >
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <NavbarItem
                  updateSlider={updateSlider}
                  removeSlider={removeSlider}
                  style={{ textTransform: "capitalize" }}
                  handleCloseNavMenu={handleCloseNavMenu}
                ></NavbarItem>
                
              </Menu>
            </Box>
         <Box>
          <div ref={ref} className="mobhamburger">
            <Hamburger  toggled={ishamOpen} size={30} toggle={sethamOpen} />
            {ishamOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                 display: "flex",
                 flexDirection: "column",
                 position: "fixed",
                 right: "0px",
                 top: "60px",
                 zIndex: "9999",
                 backgroundColor: "#E7EAEB",
                 width: "100%",
                 justifyContent: "center",
                }}
              >
                <motion.div
                 initial={{ scale: 0, opacity: 0 }}
                 animate={{ scale: 1, opacity: 1 }}
                 transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                    delay: 0.1 / 100,
                 }}
                >
                 <NavbarItem
                    updateSlider={updateSlider}
                    removeSlider={removeSlider}
                    style={{ textTransform: "capitalize", width: "100%" }}
                    handleCloseNavMenu={() => sethamOpen(false)} 
                 ></NavbarItem>
                </motion.div>
              </motion.div>
            )}
          </div>
        </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                flexWrap: "wrap",
              }}
            >
              {/* {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                  style={{textTransform:"capitalize"}}
                >
                  {page}
                </Button> 
                
               ))}  */}
            </Box>



            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", md: "flex" },
                flexWrap: "wrap",
              }}
            >
              <NavbarItem
                updateSlider={updateSlider}
                removeSlider={removeSlider}
                style={{ textTransform: "capitalize" }}
                handleCloseNavMenu={handleCloseNavMenu}
              ></NavbarItem>


              {/* Client Login Button  Start*/}
  <div
          style={{
            // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingBottom: "5px",
          }}
        >
          <a href="https://iiprd-khuranaandkhurana-cms.com/">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#b23427 ",
                color: "white",
                borderRadius: "20px",
                fontSize: "15px",
                width: "auto",
                marginRight: "17px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "white",
                  border: "1px solid #b23427",
                  color: "#b23427",
                },
              }}
            >
              Client Login
            </Button>
          </a>
        </div>
 {/* Client Login Button  Start*/}

              <Button
                variant=""
                onClick={handleClickOpen}
                sx={{
                  // backgroundColor: "#b23427 ",
                  background:"none !important",
                  color: "black",
                  width: "auto",
                  borderRadius: "20px",
                  fontSize: "15px",
                  textTransform: "capitalize",
                  "&:hover": {
                    // backgroundColor: "white",
                    background:"none !important",
                    // border: "1px solid #b23427",
                    color: "#b23427",
                  },
                }}
              >
                <SearchIcon  />
              </Button>
              
              {/* <Button className="header-action mt-lg-3 text-end">
            <button onClick={MobileMenuHandler} className="btn-menu d-lg-none"><i className="fa fa-bars"/></button>
            </Button> */}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                style={{ textTransform: "lowercase" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div>
                  <Dialog
                    style={{ textAlign: "center", justifyContent: "center" }}
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    // TransitionComponent={Transition}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Toolbar>
                        <style>{searchStyle}</style>

                        <TextField
                          className="placeholder-text-color"
                          style={{
                            marginLeft: "100px",
                            width: "60%",
                            height: "200px",
                            marginTop: "50px",
                            marginLeft: "310px",
                            paddingTop: "100px",
                            // Newly added border-bottom
                            color: "#b23427",
                            borderColor: "#b23427 !important", // Text color
                            // Increase the font size as needed  // Increase the height as needed
                          }}
                          fullWidth
                          label=""
                          id="fullWidth"
                          variant="standard"
                          placeholder="Type search keyword"
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              onClickHandler();
                            }
                          }}
                          value={search}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={onClickHandler}
                                  disableRipple
                                >
                                  {/* {isBool ? <SearchIcon /> : <SearchIcon style={{ color: "#b23427" }} />} */}
                                  {
                                    <SearchIcon
                                      style={iconStyle1}
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            style: {
                              color: "#b23427",
                              fontSize: "40px",
                              fontFamily: "Garamond serif",
                              fontStyle: "normal",
                              fontStretch: "normal",
                              paddingBottom: "20px",
                            },
                          }}
                        />

                        <IconButton
                          edge="start"
                          onClick={handleClose}
                          aria-label="close"
                          disableRipple
                        >
                          <CloseIcon
                            style={iconStyle}
                            onMouseEnter={() => setIsMouseOver(true)}
                            onMouseLeave={() => setIsMouseOver(false)}
                          />
                        </IconButton>
                        {/* <div
  style={{
    width: "0",
    height: "0",
    borderLeft: "100px solid blue", // Height of the triangle
    borderBottom: "150px solid transparent", // Width of the triangle
    marginRight: "200px",
    zIndex: 99999999,
    position: "relative",
    transform: "rotate(270deg)",
    marginTop:"547px",
    paddingLeft:"-100px" // Rotate the div by 180 degrees
  }}
>
</div> */}
                      </Toolbar>
                      <div
                        style={{
                          left: "0px",
                          bottom: "0px",
                          position: "fixed",
                        }}
                      >
                        <div
                          style={{
                            width: "0",
                            height: "0",
                            borderTop: "300px solid transparent",
                            borderLeft: "450px solid #e7eaeb",
                          }}
                        ></div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default ResponsiveAppBar;