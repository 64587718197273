import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import IIPRD_KK_Brochure from "../PDFs/IIPRD_KK_Brochure.pdf";

function CorporateBrouchure() {
  const iframeRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const iframeKey = Date.now();
  useEffect(() => {
    const handleBackButton = () => {
      if (history.action === "POP") {
        history.push(history.location.pathname);
      } else {
        history.push("/");
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [history]);
  useEffect(() => {
    const shouldOpenInNewTab = location.pathname === history.push("/");
    if (shouldOpenInNewTab) {
      window.open(IIPRD_KK_Brochure, "_blank");
    } else {
      const newIframe = document.createElement("iframe");
      // newIframe.src = IIPRD_KK_Brochure;
      newIframe.src = window.open(IIPRD_KK_Brochure, "_blank");
      newIframe.style.width = "100%";
      newIframe.style.height = "100vh";
      // iframeRef.current.appendChild(newIframe);
    }
  }, [location.pathname]);
  // useEffect(() => {
  //   const newWindow = window.open(IIPRD_KK_Brochure, "_blank");
  //   if (newWindow) {
  //     newWindow.document.write("<div></div>");
  //     const newIframe = newWindow.document.createElement("iframe");
  //     newIframe.src = IIPRD_KK_Brochure;
  //     newIframe.style.width = "100%";
  //     newIframe.style.height = "100vh";
  //     newWindow.document.body.appendChild(newIframe);
  //   }
  // }, []);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        key={iframeKey}
        title="Corporate Brochure"
        src={IIPRD_KK_Brochure}
        ref={iframeRef}
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
}
export default CorporateBrouchure;
