import React from "react";
import List from "../UI/List";
import LI from "../UI/List/Item";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const Pagination = ({
  classes,
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];
  console.log("pagination", totalPosts, postsPerPage);

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  const nextPage = () => {
    paginate(currentPage + 1);
  };
  console.log("totalPosts:", totalPosts);
  console.log("postsPerPage:", postsPerPage);
  console.log("currentPage:", currentPage);
  console.log("pageNumbers:", pageNumbers);
  return (
    <div className={`pagination-wrap`}>
      <button
        onClick={() => prevPage()}
        style={{
          marginRight: "10px",
          pointerEvents: currentPage === 1 ? "none" : null,
          width: "10px",
          height: "10px",
          backgroundColor: "white",
          border: "1px solid white",
        }}
      >
        <KeyboardArrowLeftIcon />
      </button>
      <List classes={"pagination"}>
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "white",
                border: "1px solid white",
                fontWeight: number === currentPage ? "bold" : "normal",
                textDecoration: number === currentPage ? "underline" : "none",
              }}
              onClick={() => {
                paginate(number);
              }}
              className={number === currentPage ? "current" : null}
            >
              {number}
            </button>
          </li>
        ))}
      </List>
      <button
        onClick={() => nextPage()}
        style={{
          pointerEvents: currentPage === pageNumbers.length ? "none" : null,
          width: "10px",
          height: "10px",
          backgroundColor: "white",
          border: "1px solid white",
        }}
      >
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

export default Pagination;
