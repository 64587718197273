import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import Anchor from "../../components/UI/Anchor";
import FeaturedBlog from "../../components/FeaturedBlog";
import Blogs from "../../data/Blog/blog";
import SearchIcon from "@mui/icons-material/Search";
import Categories from "../../data/Sidebar/sidebar";
import { TextField } from "@material-ui/core";
import { Checkbox, InputAdornment } from "@mui/material";
import "./insightlist.css";
import { useLocation, useHistory } from "react-router-dom";
import {FadeLoader } from "react-spinners";

const SidebarForInsights = ({ classes }) => {
  const history = useHistory();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);


  const onSearch = (e) => {
    query.set("q", e.target.value);
    history.push({
      pathname: "/insights",
      search: query.toString(),
    });
  };

  const [idPost, setidPost] = useState({ insightsPosts: [] });
  const [allPosts, setallPosts] = useState({ AllPosts: [] });
  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          // `https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?filters[post_status]=publish&sort=createdAt:desc`
          `https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?populate=*&filters[post_status]=publish&pagination[start]=0&pagination[limit]=100&sort=createdAt:desc`
        );
        const data = await response.json();
        const insightsPosts = data?.data?.filter((item) => {
          const attributes = item?.attributes;
          return attributes && attributes?.post_status === "publish";
        });

        insightsPosts.sort(
          (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date)
        );
        const latestPosts = insightsPosts.slice(0, 4);
        setidPost((prev) => ({
          ...prev,
          insightsPosts: latestPosts,
        }));

        setallPosts((prev) => ({
          ...prev,
          AllPosts: insightsPosts,
        }));
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Filter posts based on search term
  const searchTerm = query.get("q") || "";
  const filteredPosts = idPost.insightsPosts.filter((post) => {
    const title = post?.attributes?.post_title?.toLowerCase() || "";
    return title.includes(searchTerm.toLowerCase());
  });

  const onSelectChange = (select, event) => {
    let updatedSelected;

    if (event.target.checked === false) {
      updatedSelected = selected.filter((s) => s !== select);
    } else {
      updatedSelected = [...selected, select];
    }
    setSelected(updatedSelected);
    query.set("categories", updatedSelected.join(","));
    history.push({
      pathname: "/insights",
      search: query.toString(),
    });
  };

  const uniqueCategories = new Set(
    allPosts?.AllPosts?.map((category) => category.attributes.categories)
  );

  return (
    
    <Sidebar classes={`col-lg-3 ${classes} searchmobile`}>
      <TextField
        id="outlined-basic"
        placeholder="Search"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginLeft: "10px" }} />
            </InputAdornment>
          ),
        }}
        onChange={onSearch}
        value={searchTerm}
      />
      <br></br>
      <br></br>
      <br></br>
    
      <SidebarItem title={"Recent Posts"} classes={"single-sidebar-item-wrap"}>
      {loading ? (
        <div className="loader-container1" >
          <div>
            <FadeLoader size={10} color={"rgb(178, 52, 39)"} loading={loading} />
          </div>
        </div>
      ) : (
        <div className={"latest-blog-widget"}>
          {filteredPosts.map((post) => (
            <FeaturedBlog
              key={post.id}
              id={post.id}
              title={post?.attributes?.post_title}
              postName={post?.attributes?.post_name}
              publishDate={formatDate(post?.attributes?.post_date)}
              thumb={post?.attributes?.featuredImage?.data?.attributes?.url}
            />
          ))}
        </div>
              )}
        <br></br>
        <br></br>
        <SidebarItem
          title={"Categories"}
          classes={"single-sidebar-item-wrap"}
          style={{ fontSize: "24px", color: "#b23427" }}
        >
           {loading ? (
        <div className="loader-container1" >
          <div>
            <FadeLoader size={10} color={"rgb(178, 52, 39)"} loading={loading} />
          </div>
        </div>
      ) : (
          <List classes={"sidebar-list"}>
            {Array.from(uniqueCategories).map((categoryName) => (
              <div className="checkboxli" key={categoryName}>
                <Checkbox
                  checked={selected.indexOf(categoryName) >= 0}
                  onChange={(e) => onSelectChange(categoryName, e)}
                />{" "}
                <span style={{marginTop:"8px"}}>
                {categoryName?.replace(/&amp;/g, "")}
                </span>
                {/* <span style={{marginTop:"8px"}}>
  {categoryName ? categoryName.replace(/&amp;/g, "") : ''}
</span> */}
             
              </div>
            ))}
          </List>
      )}
        </SidebarItem>
      </SidebarItem>

    </Sidebar>
  );
};

export default SidebarForInsights;
