import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../components/ImageCarousel.scss";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const ImageCarousel = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/awards"
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container">
      {loading ? (
           <div style={{display:"flex",gap:"150px"}}>
           <Skeleton height={150} width={150} /> 
           <Skeleton height={150} width={150} /> 
           <Skeleton height={150} width={150} /> 
           <Skeleton height={150} width={150} /> 
           <Skeleton height={150} width={150} /> 
 
           </div>
        ) : (
      <Slider {...settings}>
        {data.map((award, index) => (
          <div key={index}>
            <img
              src={award?.attributes?.images}
              alt={`ImgSrc ${index}`}
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100px",
                height: "100px",
                objectFit: "contain",
         
              }}
            />
          </div>
        ))}     
      </Slider>
        )}
    </div>
  );
};

export default ImageCarousel;
