import React, { Fragment, useEffect, useState } from "react";
import { ClipLoader, ClockLoader, FadeLoader, HashLoader, PulseLoader } from "react-spinners";
import PageWrapper from "../PageWrapper";
import InsightDetailsContentWrapper from "./ContentWrapper";
import BlogData from "../../data/Blog/blog";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer/index";
import LoginRegister from "../LoginRegister";
import MobileMenu from "../MobileMenu";
import SidebarForInsights from "../insights/sidebarInsights";
import "../../components/insights/insightlist.css";
import SidebarItem from "../Sidebar/SidebarItem";

const InsightDetailsPage = ({ sidebar }) => {
  const { postname } = useParams();
  const [loading, setLoading] = useState(true);
  const [idPost, setidPost] = useState({ insightsPosts: [] });
  const [singlepost, setsinglepost] = useState({});

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          `https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?filters[post_status]=publish&sort=createdAt:desc`
        );
        const data = await response.json();
        console.log("res_data", data?.data);

        if (Array.isArray(data?.data)) {
          console.log("entered_array_if_loop_gcv");

          const insightsPosts = data?.data?.filter((item) => {
            const attributes = item?.attributes;
            return attributes && attributes?.post_status === "publish";
          });

          console.log("insight_posts_jv", insightsPosts);

          setidPost((prev) => ({
            ...prev,
            insightsPosts,
          }));
        } else {
          console.error("Unexpected data structure:", data);
        }
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchsinglepost = async() => {

      try {
        // const res = await fetch(`https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests/${id}`);
        const res = await fetch(`https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?filters[post_status]=publish&filters[post_name][$eq]=${postname}`);
        const data = await res.json();
  
        if(data?.data) {
          const singlePost = data?.data;
          setsinglepost(singlePost);
        }
      }
      catch (error) {
        console.error("Failed to fetch posts:", error);
      } finally {
        setLoading(false);
      }
  
    }

    fetchPosts();
    fetchsinglepost();
  }, [postname]);

  const insight = idPost.insightsPosts.find(
    (post) => post.post_name === postname
  );
  console.log("insight_idPostfind", insight);

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    singlepost[0]?.attributes?.metaTitle,
    singlepost[0]?.attributes?.metaDescription,
    singlepost[0]?.attributes?.keywords
  );
  


  return (
    <Fragment>
      <Header />
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader size={50} color={"rgb(178, 52, 39)"} loading={loading} />
          </div>
        </div>
      ) : (
        <PageWrapper classes={"blog-details-page-content"}>
          <div className={"col-lg-9"}>
            <div
              className="Breadtext"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3
              className="breadtextmobile"
                style={{
                  fontSize: "14px",
                  fontWeight:"600",
                  fontFamily: "HelveticaNeue-Bold, arial, helvetica, sans-serif",
                  color: "#b23427",
                  marginRight: "10px",
                }}
              >
                <a
                  href="/"
                  style={{
                    textDecoration: "none",
                    color: "#b23427",
                    cursor: "pointer",
                  }}
                >
                  HOME
                </a>{" "}
                /
                <a
                  href="/insights"
                  style={{
                    textDecoration: "none",
                    color: "#b23427",
                    cursor: "pointer",
                  }}
                >
                  INSIGHT
                </a>{" "}
                /
              </h3>
              <h3
               className="breadtextmobile"
                style={{
                  fontSize: "14px",
                  fontWeight:"600",
                  fontFamily: "HelveticaNeue-Bold, arial, helvetica, sans-serif",
                  color: "#425563",
                  flex: 1,
                }}
              >
                {singlepost[0]?.attributes?.post_name.toUpperCase()}
              </h3>
            </div>
            <div>
              <InsightDetailsContentWrapper
                relatedPosts={insight}
                post={singlepost[0]}
              />
            </div>
          </div>

          <SidebarForInsights  classes={`sp-y`}  />
   
        </PageWrapper>
      )}
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default InsightDetailsPage;
