import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";

import Footer from "../../components/Footer";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaFacebook, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import bannerlogo from "../../assets/img/blog/CONTACT-LAWYERS-BannerBubble.webp";
import { Link } from "react-router-dom";
import "../../components/People/People.css";
import "../PeoplePage/IndividualPeople.css";
import { useParams } from "react-router-dom";
import employeesData from "../../data/People/Employees.json";
import domesticOffices from "../../data/Locations/DomesticLocations.json";
import internationalOffices from "../../data/Locations/InternationalLocations.json";
import { Grid } from "@mui/material";
import logo from "../../data/People/Employees.json";
import axios from "axios";
import { FadeLoader } from "react-spinners";

const IndividualPeople = () => {
  const { slug } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  const [isShareVisible, setIsShareVisible] = useState(false); 
  const [people, setPeople ] = useState([]);
  const [location, setLocation ] = useState([]);
  const [address, setAddress ] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPhoneNumberHovered, setIsPhoneNumberHovered] = useState(false);
  console.log("IndividualPeople", people);
  const circleStyle = {
    width: "400px",
    height: "400px",
    borderRadius: "50%", // This property makes the box circular
    backgroundColor: "#b23427", // Set your desired background color
    position: "absolute", // Set position to absolute
    top: "0px", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Center the circle
    zIndex: "1",
    transition: "transform 0.3s ease", // Add a transition for smooth scaling
    transform: isHovered ? "scale(1.04)" : "scale(1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "100px",
    marginTop: "0px",
  };
  const phoneNumberStyle = {
    fontSize: "18px",
    fontFamily: "Garamond, serif",
    color: isPhoneNumberHovered ? "#b23427" : "#425563",
    marginLeft: "5px",
    transition: "color 0.3s",
    cursor: "pointer",
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://khuranaandkhurana-strapi.ntb.one/api/employees?filters[slug][$eq]=${slug}`
        );
        setPeople(response?.data?.data);
        setAddress(response?.data?.data[0]?.attributes?.location);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
  const fetchemployeeLocation= async () => {
    let individualAddress = '';
    if (address === "Delhi/Noida/National Capital Region"){
      individualAddress = "Noida";
    } else if (address === "Mumbai/Pune/Indore"){
      individualAddress = "Pune";
    } else if (address === "Bangalore/Chennai"){
      individualAddress = "Bangalore";
    } else {
      individualAddress = address;
    }
    try {
      const response = await axios.get(
        `https://khuranaandkhurana-strapi.ntb.one/api/domestic-locations?filters[city][$containsi]=${individualAddress}`
      );
      setLocation(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data from Strapi:", error);
    }
  };
  fetchemployeeLocation();
}, [address]);

  const handleSearchClick = () => {
   
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  // Function to handle share button click
  const handleShareClick = () => {
    setIsShareVisible(!isShareVisible); 
  };
  
  const url = window.location.href;
 
  // const people = employeesData.find((emp) => emp.slug === slug);
  // const office = (
  //   people.officeType === "domestic" ? domesticOffices : internationalOffices
  // ).locations.find((off) => off.id == people.officeId);

  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
      <Grid container  sx={{ mx: 0 }} className="spacingmobile">
        <Grid item xs={12} md={8}>
          <div className="titleContainer" style={{ width: "100%" }}>
            <div
              className="peoplehome"
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginTop: "30px",
                marginLeft: "50px",
              }}
            >
              <a href="/">
              <p
                style={{
                  color: "#b23427",
                  fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontStyle: "bold",
                  fontWeight: "600",
                }}
              >
                HOME
              </p>
             
              </a>
              <a href="/people">
              <p
                style={{
                  color: "#b23427",
                  fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  fontStyle: "bold",
                  fontWeight: "600",
                  marginTop: "0px",
                }}
              >
               / PEOPLE
              </p>
              </a>
            </div>
            <h2
              style={{
                marginTop: "40px",
                marginLeft: "50px",
                color: "#b23427",
                fontSize: "50px",
                fontStyle: "normal",
                fontWeight: "400",
                fontFamily: "Garamond-Regular,georgia,serif",
              }}
              className="profile-caption"
            >
              {people[0]?.attributes?.name}
            </h2>
            <p
              className="peopledesi"
              style={{
                fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                fontSize: "19px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                marginRight: "30px",
                marginLeft: "50px",
                fontWeight: "600",
              }}
            >
              {people[0]?.attributes?.designation}
            </p>
            <p
              className="peopleloc"
              style={{
                textAlign: "left",
                marginLeft: "50px",
                color: "#425563",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "19px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                marginRight: "30px",
                fontWeight: "600",
              }}
            >
              {people[0]?.attributes?.location}
            </p>
            <div
              className="peopleshare"
              style={{
                display: "flex",
                gap: "20px",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "#b23427",
                marginLeft: "50px",
                cursor: "pointer",
              }}
              onClick={handleShareClick}
            >
              <ShareOutlinedIcon style={{ color: "white", marginTop: "2px" }} />
              {isShareVisible && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <FacebookShareButton
                    url={url}
                    quote={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <FaFacebook size={32} round color="#b23427" />
                  </FacebookShareButton>
                  <TwitterShareButton
                  className="twitter"
                    url={url}
                    title={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <TwitterIcon size={35} round style={{ fill: "#b23427", width:"35px", height:"35px" }}   />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={url}
                    title="Check out this profile:  "
                    style={{ width: "auto" }}
                  >
                    <FaWhatsapp size={32} round color="#b23427" />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    url={url}
                    title={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <FaLinkedin size={32} round color="#b23427" />
                  </LinkedinShareButton>
                </div>
              )}
            </div>
            <div style={{ display: "flex", width: "auto" }}>
              <p
                className="peoplemobile"
                style={{
                  textAlign: "justify",
                  marginLeft: "50px",
                  color: "inherit",
                  fontFamily: "Garamond,serif",
                  fontSize: "28px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.25",
                  color: "#425563",
                  marginBottom: "22px",
                  marginRight: "110px",
                  marginTop: "10px",
                }}
              >
                {people[0]?.attributes?.shortDescription}
              </p>
              {/* <p style={{ textAlign:"right",color: "inherit", fontFamily: "Garamond,serif", fontSize: "28px", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.25", color: "#425563",marginBottom:"22px"}}>
          {item.department}
        </p> */}
            </div>
          </div>
          <div  className="peopleshort">
          <p
        
            dangerouslySetInnerHTML={{ __html: people[0]?.attributes?.description }}
            style={{
              color: "#425563",
              fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
              fontSize: "16px",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "30px",
              textAlign: "justify",
              // paddingInline: "20px",
              fontWeight: "100",
              margin:"10px 110px 22px 50px"
            }}
           
          ></p>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: "5%" }}>
          <div>
            <img
              className="profile-image1"
              src={people[0]?.attributes?.avatarLink}
              alt="Image"
              style={{ width: "100%", background: "#5592b4", zIndex: "99999" }}
            />
          </div>
          <br />
          {people[0]?.attributes?.email && (
            <a
              href={"mailto://" + people[0]?.attributes?.email}
              style={{ display: "flex", margin: "5px 0px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "30px", 
                  height: "30px",
                  borderRadius: "50%", 
                  backgroundColor: "brown", 
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EmailIcon style={{ color: "white" }} />{" "}
                {/* Set the icon color to blue */}
              </div>
              <div style={{}}>
                <h3
                  className="emailLink"
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                  style={{
                    fontSize: "18px",
                    fontFamily: "Garamond, serif",
                    color: isHovered ? "brown !important" : "#425563",
                    marginLeft: "5px",
                    marginTop: "6px",
                    textDecoration: isHovered ? "underline !important" : "none",
                    transition: "color 0.3s, text-decoration 0.3s",
                  }}
                >
                  Send Email
                </h3>
              </div>
            </a>
          )}
          {people[0]?.attributes?.phone && (
            <a
              href={"tel://" + people[0]?.attributes?.phone}
              style={{ display: "flex", margin: "5px 0px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "30px", 
                  height: "30px",
                  borderRadius: "50%", 
                  backgroundColor: "brown", 
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", 
                }}
              >
                <MobileScreenShareIcon style={{ color: "white" }} />{" "}
                {/* Set the icon color to blue */}
              </div>
              <div style={{}}>
                <h3
                  style={phoneNumberStyle}
                  onMouseEnter={() => setIsPhoneNumberHovered(true)}
                  onMouseLeave={() => setIsPhoneNumberHovered(false)}
                >
                  {people[0]?.attributes?.phone}
                </h3>
              </div>
            </a>
          )}
          {people[0]?.attributes?.twitter && (
            <a
              href={"https://twitter.com/" + people[0]?.attributes?.twitter}
              style={{ display: "flex", margin: "5px 0px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "30px", 
                  height: "30px",
                  borderRadius: "50%", 
                  backgroundColor: "brown", 
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TwitterIcon style={{ color: "white" }} />{" "}
                {/* Set the icon color to blue */}
              </div>
              <div style={{ marginTop: "6px" }}>
                <h3
                  style={{
                    fontSize: "18px",
                    fontFamily: "Garamond,serif",
                    color: "#425563",
                    marginLeft: "5px",
                  }}
                >
                  @{people[0]?.attributes?.twitter}
                </h3>
              </div>
            </a>
          )}
          {people[0]?.attributes?.linkedin && (
            <a
              href={people[0]?.attributes?.linkedin}
              style={{ display: "flex", margin: "5px 0px" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "30px", 
                  height: "30px",
                  borderRadius: "50%", 
                  backgroundColor: "brown",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LinkedInIcon style={{ color: "white" }} />{" "}
                {/* Set the icon color to blue */}
              </div>
              <div style={{ marginTop: "6px" }}>
                <h3
                  style={{
                    fontSize: "18px",
                    fontFamily: "Garamond,serif",
                    color: "#425563",
                    marginLeft: "10px",
                  }}
                >
                  LinkedIn
                </h3>
              </div>
            </a>
          )}
          <div style={{ marginTop: "20px" }}>
            <p
              style={{
                color: "inherit",
                fontFamily: "Garamond,serif",
                fontSize: "40px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                color: "#425563",
              }}
            >
              Expertise
            </p>
          </div>
          <div style={{ marginTop: "10px" }}>
            <p
              style={{
                color: "inherit",
                fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "25px",
                color: "#425563",
                fontWeight: "600",
                letterSpacing: "-0.2px",
              }}
            >
              PRACTICES
            </p>
            {(people[0]?.attributes?.practices || []).map((prac) => (
              <div style={{}}>
                <p
                  style={{
                    fontFamily: "Garamond,serif",
                    fontSize: "20px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.25",
                    color: "#b23427",
                    transition: "text-decoration 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.textDecoration = "underline";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.textDecoration = "none";
                  }}
                >
                  <a style={{ color: "#b23427" }} href={prac.link}>
                    {prac.label}
                  </a>
                </p>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "10px" }}>
            <p
              style={{
                color: "inherit",
                fontFamily: "Garamond,serif",
                fontSize: "40px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                color: "#425563",
              }}
            >
              Office
            </p>
            <p
              style={{
                color: "inherit",
                fontFamily: "Garamond,serif",
                fontSize: "20px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                color: "#b23427",
              }}
            >
              {location[0]?.attributes?.city}
            </p>
            <p
              style={{
                textAlign: "justify",
                fontFamily: "Garamond,serif",
                fontSize: "20px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                color: "#425563",
                wordWrap: "break-word",
              }}
            >
              {location[0]?.attributes?.address}
            </p>
            <p
              style={{
                fontFamily: "Garamond,serif",
                fontSize: "20px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                color: "#b23427",
                marginRight: "180px",
                transition: "color 0.3s, text-decoration 0.3s",
                cursor: "pointer",
              }}
              onMouseOver={(e) => {
                e.target.style.color = "brown";
                e.target.style.textDecoration = "underline";
              }}
              onMouseOut={(e) => {
                e.target.style.color = "#b23427";
                e.target.style.textDecoration = "none";
              }}
            >
              <a
                style={{ color: "#b23427" }}
                href={
                  (people[0]?.attributes?.officeType == "domestic"
                    ? "/contact-us"
                    : "/international-contact-us") +
                  "?id=" +
                  people[0]?.attributes?.officeId
                }
              >
                View office →
              </a>
            </p>
          </div>
        </Grid>
      </Grid>
      )}
      <br></br>
      <br></br>

      {/* <div
        style={{ position: 'relative', width: '100%', height: '80vh', marginTop: "100px" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={bannerlogo} alt="Banner Logo" style={{ height: 'auto', marginTop: "100px" }} />
        <div style={circleStyle}>
          <h3 style={{ marginTop: "40px", fontWeight: "400", fontFamily: "Garamond-Regular,georgia,serif", fontSize: "40px", color: "#fff", zIndex: "9999", marginLeft: "5px" }}>Find a Lawyer</h3>
          <Link to="/people">
            <button style={{ fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", padding: "10px 20px", backgroundColor: "white", color: "#b23427", border: "1px solid #b23427", cursor: "pointer", width: "150px", height: "50px", marginLeft: "px", marginTop: "20px" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "#b23427";
                e.target.style.border = "1px solid white"
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#b23427";
                e.target.style.color = "white";
              }}

            >
              Search
            </button>
          </Link>
        </div>
      </div> */}

      <div
        className="outterdivcircle"
        style={{
          position: "relative",
          width: "100%",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          className="mobilebanner"
          src={bannerlogo}
          alt="Banner Logo"
          style={{ width: "100%", height: "auto", margin: "75px 0px" }}
        />
        <div className="circlestyle">
          <h3
            style={{
              marginTop: "40px",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
              fontSize: "40px",
              color: "#fff",
              zIndex: "9999",
              marginLeft: "5px",
            }}
          >
            Find a Lawyer
          </h3>
          <Link
            to="/people"
            style={{
              fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
              fontSize: "16px",
              padding: "10px 20px",
              backgroundColor: "#b23427",
              color: "white",
              border: "1px solid white",
              cursor: "pointer",
              width: "150px",
              height: "50px",
              marginLeft: "px",
              marginTop: "20px",
              textAlign: "center",
            }}
            onClick={handleSearchClick} // Call the function on button click
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.color = "#b23427";
              e.target.style.border = "1px solid white";
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "#b23427";
              e.target.style.color = "white";
            }}
          >
            Search
          </Link>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};
export default IndividualPeople;
