import React from 'react';
import {Link} from "react-router-dom";

const FeaturedBlog = ({title,thumb,publishDate,id, postName}) => {
    const blogURL = `/insights/${postName}`;
    return (
        <Link to={blogURL}>
        <div className="single-blog-item">
            <div className="post-thumb">
                <img src={`https://khuranaandkhurana-strapi.ntb.one${thumb}`} alt={title} />
            </div>

            <div className="post-info">
                <h6 style={{fontSize:"18px",fontFamily:"Arial,Helvetica,sans-serif",color:"black"}}><Link to={blogURL}>{title.toUpperCase().slice(0,24)}</Link></h6>
                <span className="post-date" style={{fontSize:"15px",fontFamily:"Arial,Helvetica,sans-serif",color:"#425563"}}>  {publishDate}</span>
            </div>
        </div>
        </Link>
    );
};

export default FeaturedBlog;