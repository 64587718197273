import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaFacebook, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";
import imagelogo from '../assets/img/blog/AO_hero_image_M01_sectors_Energy.webp'
import bannerlogo from '../assets/img/blog/CONTACT-LAWYERS-BannerBubble.webp'
const AboutUs = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isShareVisible, setIsShareVisible] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuStyle = {
    overflowY: 'hidden',
  };
  const menuPaperStyle = {
    maxHeight: '600px', // Set a specific height if needed
    overflow: 'auto',
    width:"300px"
  };
  const circleStyle = {
    width: '400px',
    height: '400px',
    borderRadius: '50%', // This property makes the box circular
    backgroundColor: '#b23427', // Set your desired background color
    position: 'absolute', // Set position to absolute
    top: '50%', // Center vertically
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Center the circle
    zIndex: '1',
    transition: 'transform 0.3s ease', // Add a transition for smooth scaling
    transform: isHovered ? 'scale(1.04)' : 'scale(1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop:"-400px",
    marginLeft:"100px"
  };

  const buttonStyle = {
    fontFamily: 'HelveticaNeue-Roman, arial, helvetica, sans-serif',
    fontSize: '16px',
    padding: '10px 20px',
    backgroundColor: 'white',
    color: '#b23427',
    border: '1px solid #b23427',
    cursor: 'pointer',
    width: '200px',
    height: '50px',
    marginTop: '20px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };
  const iconButtonStyle = {
    padding: '4px',
    borderRadius: '50%',
  
  };
 
  const listItemStyle = {
    fontSize: "18px",
    color: "#b23427",
    fontFamily: "HelveticaNeue-Roman, Arial, Helvetica, sans-serif",
    listStyleType: "disc", // Removes the default list item bullet point
    margin: "0 20px", // Adjust margin between list items if needed
  };
  const handleShareClick = () => {
    setIsShareVisible(!isShareVisible); 
  };
 
  const url = window.location.href;
 
  

  return (
    <>
      <Header />
      <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left',marginTop:"30px",marginLeft:"30px"}}>
       <p style={{color:"#b23427",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",fontSize:"14px",textTransform:"uppercase",fontStyle:"bold",fontWeight:"400", cursor:"pointer"}}>  HOME</p>
<p style={{color:"inherit",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",fontSize:"14px",textTransform:"uppercase",fontStyle:"bold",fontWeight:"600",marginTop:"0px"}}>{window.location.pathname}</p>
</div>
    <div style={{display:"flex"}}>
      <div className="titleContainer" style={{width:"100%"}}>
        <h2 style={{marginTop:"40px", marginLeft:"30px",color: "#b23427", fontSize: "35px", fontStyle: "normal", fontWeight: "400", fontFamily: "Garamond-Regular,georgia,serif" }} className="profile-caption">
       About Us
        </h2>
        <div style={{marginLeft:"30px",marginTop:"10px"}} 
        onClick={handleShareClick}>
        <ShareOutlinedIcon style={{ color: "white", marginTop: "2px" }} />
              {isShareVisible && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <FacebookShareButton
                    url={url}
                    quote={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <FaFacebook size={32} round color="#b23427" />
                  </FacebookShareButton>
                  <TwitterShareButton
                  className="twitter"
                    url={url}
                    title={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <TwitterIcon size={35} round style={{ fill: "#b23427", width:"35px", height:"35px" }}   />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={url}
                    title="Check out this profile:  "
                    style={{ width: "auto" }}
                  >
                    <FaWhatsapp size={32} round color="#b23427" />
                  </WhatsappShareButton>
                  <LinkedinShareButton
                    url={url}
                    title={`Check out this profile:  `}
                    style={{ width: "auto" }}
                  >
                    <FaLinkedin size={32} round color="#b23427" />
                  </LinkedinShareButton>
                </div>
              )}
</div>
      <br></br>
        <p style={{ textAlign: "left",marginLeft:"30px", color: "#425563", fontFamily: "Garamond,serif", fontSize: "25px", fontStretch: "normal", fontStyle: "normal", lineHeight: "30px", color: "#425563",marginRight:"30px"}}>
        To most of our clients, Khurana & Khurana (K&K) is more than a full-service Intellectual Property (IP) Law firm. K&K was formed with a very firm focus of providing End-to-End IP Legal Services in a manner that is Corporate centric and follows delivery practices that are consistent and are above-defined quality standards so as to ensure “Above Expectations” for all clients.
        </p>
      </div>
      <div className="image-container" style={{ display: "flex", alignItems: "right", justifyContent: "right", zIndex: "1" }}>
        <img className="profile-image" src={imagelogo} alt="Image" style={{ width: "650px", height: "400px",marginRight:"30px"}} />
      </div>
      </div>
      <p style={{ textAlign: "left", color: "#425563", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", fontStretch: "normal", fontStyle: "normal", lineHeight: "30px",marginLeft:"30px",marginRight:"30px"}}>
      K&K is a team of over 150 IP and Commercial Law Professionals spread across 9 Offices in India with a representation of over 4500 clients for managing their complete IP and Legal Portfolio. K&K is also highly ranked and strongly recommended by Legal 500, IAM, Managing IP, Asia IP, Chambers, among many other reputed ranking authorities.

Further, K&K works closely with its Sister Concern “IIPRD”, both of which supplement each other in order to provide end-to-end IP Legal and Commercialization/Licensing services to the corporate world. K&K and IIPRD together form a niche in the IP domain by taking any corporate from the stage of IP Creation and Protection through its team of Attorneys to the stage of IP Valuation, Licensing, and Commercialization through its Practitioners at IIPRD.

We at K&K believe to have a unique understanding of issues that drive businesses. Our team of IP Attorneys/Practitioners, having a high level of technical and legal competence, give us the right competitive edge and positioning as a law firm focused on creating immense IP value for our clients.

K&K, through its experienced and qualified team of Attorneys/Practitioners, across Technology and Legal Domains, gives a rare synergy of legal opinion, out-of-box thinking for protection of ideas/IP’s and entrepreneurial spirits to its client base. K&K earnestly believes that success comes only when one has a long-term perspective and this is why we concentrate on building long-term relationships through a high level of client orientation.

Further, K&K is among the youngest Indian IP Law Firms to have been ranked and recommended by Legal 500, Managing IP, Asia IP and is proud to represent numerous Indian and Global Corporates, SME’s, Universities, and Individual Inventors, from across Technology and Business verticals. K&K also takes pride in its strong associate base in the United States, European Union, Singapore, Israel, Japan, China, Korea, ARIPO, and Australia, among all other countries, which assist K&K in procuring and protecting IP right for its clients.
      </p>
      <button style={{fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", padding: "10px 20px", backgroundColor: "white",color: "#b23427", border: "1px solid #b23427", cursor: "pointer", width: "200px", height: "50px", marginLeft:"30px",marginTop:"20px"}}
      onMouseOver={(e) => {
        e.target.style.backgroundColor = "#b23427";
        e.target.style.color = "white";
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = "white";
        e.target.style.color = "#b23427";
      }}
     
    >
      Read More
    </button>
     <br></br>
     <br></br>
      {/* <div>
      <div style={{display:"flex",marginLeft:"30px"}}>
     <div>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
        <img src={imagelogo} style={{width:"250px",height:"150px",paddingLeft:"auto",paddingRight:"auto"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
          Corporate & Secretarial Practice
        </Typography >
        <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
       <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
    borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '10px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
         <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
          Commercial Litigation
        </Typography >
        <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
      <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
     <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
         Company Law Practice
        </Typography >
        <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
      <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
         Posh Practice
        </Typography >
         <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
    <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
      </div>
      </div> */}
      {/* <br></br>
      <br></br> */}
       {/* <div>
      <div style={{display:"flex",marginLeft:"30px"}}>
     <div>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
         Labour Law Practice
        </Typography >
         <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
     <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
         Fashion Law Practice
        </Typography >
        <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
      <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
         Contract/Agreement Drafting and Vetting
        </Typography >
         <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
      <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '10px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
     <div style={{marginLeft:"20px"}}>
     <Card style={{width:"300px",height:"400px",background:"#e7aeb"}}>
      <CardContent>
      <img src={imagelogo} style={{width:"250px",height:"150px"}}></img>
        <Typography style={{color:"#425563",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"20px",marginTop:"5px"}}>
       NCLT Practice
        </Typography >
         <Typography style={{color:"#425563",fontFamily:"Garamond,serif",fontSize:"16px",marginTop:"5px"}}>
          Card Content goes here. You can add any static content inside the card. Card Content goes here. You can add any static content inside the card...
        </Typography>
      <Button
  variant="contained"
  style={{
    backgroundColor: 'white',
    border: '1px solid #b23427',
    color: '#b23427',
     borderRadius:"0px",
    transition: 'all 0.3s ease',
    marginTop: '40px', // Adjust the margin top value as needed
  }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = '#b23427';
    e.target.style.color = 'white';
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = 'white';
    e.target.style.color = '#b23427';
  }}
>
  Read More
</Button>
      </CardContent>
    </Card>
     </div>
    
      </div>
      </div> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* <div style={{width:"auto",height:"350px",background:"#e7eaeb"}}>
      <div style={{}}>
      <h2 style={{fontSize:"40px",color:"#b23427",fontFamily:"Garamond,serif",paddingTop:"70px",textAlign:"center",fontWeight:"400"}}>Explore Our Services</h2>
       </div>
       <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <li
            style={{ ...listItemStyle, textDecoration: "none" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
          K&K Profile
          </li>
          <li
            style={{ ...listItemStyle, textDecoration: "none" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
         Corporate Brochure
          </li>
          <li
            style={{ ...listItemStyle, textDecoration: "none" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
         Corporate Induction Video
          </li>
        </div>
        <br />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <li
            style={{ ...listItemStyle, textDecoration: "none" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
          Corporate Presentation
          </li>
          <li
            style={{ ...listItemStyle, textDecoration: "none" }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
          >
          Information/Data Security Management
          </li>
        </div>
</div>
      </div> */}
      <br></br>
      <br></br>
    {/* <div
      style={{ position: 'relative', width: '100%', height: '100vh',marginTop:"100px"}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={bannerlogo} alt="Banner Logo" style={{ width: '100%', height: 'auto'}} />
      <div style={circleStyle}>
        <h3 style={{marginTop:"40px",fontWeight:"400",fontFamily:"Garamond-Regular,georgia,serif",fontSize:"40px",color:"#fff",zIndex:"9999",marginLeft:"5px"}}>Meet Our Lawyers</h3>
        <button style={{fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", padding: "10px 20px", backgroundColor: "white",color: "#b23427", border: "1px solid #b23427", cursor: "pointer", width: "200px", height: "50px", marginLeft:"px",marginTop:"20px"}}
      onMouseOver={(e) => {
        e.target.style.backgroundColor = "white";
        e.target.style.color = "#b23427";
        e.target.style.border="1px solid white"
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = "#b23427";
        e.target.style.color = "white";
      }}
     
    >
      View all
    </button>
      </div>
    </div> */}

      {/* <div style={{marginTop:"-300px",marginLeft:"100px"}}>
         <h2 style={{fontSize:"50px",fontFamily:"Garamond,serif",color:"#425563",fontWeight:"400"}}>News & insights</h2>
        </div> */}
        {/* <div style={{marginTop:"-20px",marginLeft:"100px"}}>
      <TextField id="standard-basic" label="Standard" variant="standard" InputProps={{  readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon style={{ cursor: 'pointer',color:"#b23427",fontSize:"50px"}} onClick={handleClick} />
            </InputAdornment>
          ),
        }}/>
        
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: menuPaperStyle,
        }}
      
      >
        <MenuItem onClick={handleClose} style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Global</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>USA</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>UAE</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Bangladesh</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Vietnam</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Myanmar</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Nepal</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Malaysia</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Sri Lanka</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Thailand</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Indonesia</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Philippines</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Noida</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>New Delhi</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Bangalore</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Pune</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Mumbai</MenuItem>
        <MenuItem onClick={handleClose}style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Indore</MenuItem>
        <MenuItem onClick={handleClose} style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Hyderabad</MenuItem>
        <MenuItem onClick={handleClose} style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Punjab</MenuItem>
        <MenuItem onClick={handleClose} style={{borderBottom:"1px solid #E7EAEA",color:"#b23427"}}>Chennai</MenuItem>
      </Menu>
    </div> */}
    {/* <div style={{display:"flex"}} >
    <div style={{display:"flex"}}>
      <Card  style={{background:"white",width:"500px",height:"550px",marginLeft:"100px"}}>
        <CardContent>
          <img src={imagelogo} style={{width:"350px",height:"219px"}}></img>
          <div style={{display:"flex"}}>
        <Typography style={{fontSize:"1rem",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"#b23427",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",textTransform:"uppercase",marginTop:"10px"}}>
            PUBLICATIONS:
          </Typography>
          <p style={{fontSize:"12px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",marginTop:"13px"}}>11 October 2023</p>
          </div>
          <Typography style={{fontSize:"18px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"#425563",fontStretch:"normal",fontStyle:"normal",fontWeight:"500",marginTop:"15px"}} 
          onMouseOver={(e) => { e.target.style.color = "#b23427"; }} 
          onMouseOut={(e) => {  e.target.style.color = "inherit"; }}>
          Card Content goes here. You can add any static content inside the card.
          </Typography>
          <p style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"16px",color:"#425563",fontStyle:"normal",fontStretch:"normal",fontWeight:"400",marginTop:"20px"}}>
          On 3 October 2023, Eddy de Smijter, Head of the International Relations Unit, European Commission, DG Competition, Conor Quigley KC of Serle Court Chambers, and Nicola Mazzarotto, Global Head of…
          </p>
          <a href="" style={{fontSize:"16px",fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",color:"#b23427"}}     onMouseOver={(e) => {
        e.target.style.textDecoration = "underline";
        e.target.style.color = "#b23427";
      }}
      onMouseOut={(e) => {
        e.target.style.textDecoration = "none";
       
      }}>Read more <ArrowRightAltIcon style={{marginTop:"3px"}}/></a>
        </CardContent>
      </Card>
      </div>
      <Card  style={{background:"white",width:"500px",height:"550px",marginLeft:"100px"}}>
        <CardContent>
        <img src={imagelogo} style={{width:"350px",height:"219px"}}></img>
        <div style={{display:"flex"}}>
        <Typography style={{fontSize:"1rem",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"#b23427",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",textTransform:"uppercase",marginTop:"10px"}}>
            PUBLICATIONS:
          </Typography>
          <p style={{fontSize:"12px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",marginTop:"13px"}}>11 October 2023</p>
          </div>
          <Typography style={{fontSize:"18px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",marginTop:"15px"}}    onMouseOver={(e) => { e.target.style.color = "#b23427"; }} 
          onMouseOut={(e) => {  e.target.style.color = "inherit"; }}>
          Card Content goes here. You can add any static content inside the card.
          </Typography>
          <p style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"16px",color:"#425563",fontStyle:"normal",fontStretch:"normal",fontWeight:"400",marginTop:"20px"}}>
          On 3 October 2023, Eddy de Smijter, Head of the International Relations Unit, European Commission, DG Competition, Conor Quigley KC of Serle Court Chambers, and Nicola Mazzarotto, Global Head of…
          </p>
          <a href="" style={{fontSize:"16px",fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",color:"#b23427"}}  
          
          onMouseOver={(e) => { e.target.style.textDecoration = "underline"; e.target.style.color = "#b23427"; }} 
          onMouseOut={(e) => {  e.target.style.textDecoration = "none"; }}>
            Read more <ArrowRightAltIcon style={{marginTop:"3px"}}/></a>
        </CardContent>
      </Card>
    </div>
    
    <div style={{display:"flex",marginTop: "50px"}}>
      <div>
      <Card  style={{background:"white",width:"500px",height:"550px",marginLeft:"100px"}}>
        <CardContent>
        <img src={imagelogo} style={{width:"350px",height:"219px"}}></img>
        <div style={{display:"flex"}}>
        <Typography style={{fontSize:"1rem",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"#b23427",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",textTransform:"uppercase",marginTop:"10px"}}>
            PUBLICATIONS:
          </Typography>
          <p style={{fontSize:"12px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",marginTop:"13px"}}>11 October 2023</p>
          </div>
          <Typography style={{fontSize:"18px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",marginTop:"15px"}}     onMouseOver={(e) => { e.target.style.color = "#b23427"; }} 
          onMouseOut={(e) => {  e.target.style.color = "inherit"; }}>
          Card Content goes here. You can add any static content inside the card.
          </Typography>
          <p style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"16px",color:"#425563",fontStyle:"normal",fontStretch:"normal",fontWeight:"400",marginTop:"20px"}}>
          On 3 October 2023, Eddy de Smijter, Head of the International Relations Unit, European Commission, DG Competition, Conor Quigley KC of Serle Court Chambers, and Nicola Mazzarotto, Global Head of…
          </p>
          <a href="" style={{fontSize:"16px",fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",color:"#b23427"}}     onMouseOver={(e) => {
        e.target.style.textDecoration = "underline";
        e.target.style.color = "#b23427";
      }}
      onMouseOut={(e) => {
        e.target.style.textDecoration = "none";
       
      }}>Read more <ArrowRightAltIcon style={{marginTop:"3px"}}/></a>
        </CardContent>
      </Card>
      </div>
      <Card  style={{background:"white",width:"500px",height:"550px",marginLeft:"100px"}}>
        <CardContent>
        <img src={imagelogo} style={{width:"350px",height:"219px"}}></img>
        <br></br>
        <div style={{display:"flex"}}>
        <Typography style={{fontSize:"1rem",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"#b23427",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",textTransform:"uppercase",marginTop:"10px"}}>
            PUBLICATIONS:
          </Typography>
          <p style={{fontSize:"12px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",marginTop:"13px"}}>11 October 2023</p>
          </div>
          <Typography style={{fontSize:"18px",fontFamily:"HelveticaNeue-Bold,arial,helvetica,sans-serif",color:"inherit",fontStretch:"normal",fontStyle:"normal",fontWeight:"400",marginTop:"15px"}}    onMouseOver={(e) => { e.target.style.color = "#b23427"; }} 
          onMouseOut={(e) => {  e.target.style.color = "inherit"; }}>
          Card Content goes here. You can add any static content inside the card.
          </Typography>
          <p style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"16px",color:"#425563",fontStyle:"normal",fontStretch:"normal",fontWeight:"400",marginTop:"20px"}}>
          On 3 October 2023, Eddy de Smijter, Head of the International Relations Unit, European Commission, DG Competition, Conor Quigley KC of Serle Court Chambers, and Nicola Mazzarotto, Global Head of…
          </p>
          <a href="" style={{fontSize:"16px",fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",color:"#b23427"}}     onMouseOver={(e) => {
        e.target.style.textDecoration = "underline";
        e.target.style.color = "#b23427";
      }}
      onMouseOut={(e) => {
        e.target.style.textDecoration = "none";
       
      }}>Read more <ArrowRightAltIcon style={{marginTop:"3px"}}/></a>
        </CardContent>
      </Card>
    </div> */}
      <Footer />
    </>
  );
};

export default AboutUs;
