// EmployeeCard.js
import { useState } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const EmployeeCard = ({ employee }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleViewProfileClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cardStyle = {
    background: "#E7EAEB",
    width: "100%",
    margin: "10px 5px",
    transition: "transform 0.3s ease",
    transform: isHovered ? "scale(1.03)" : "scale(1)", // Enlarge the card on hover
  };

  const truncateName = (name, maxLength) => {
    return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
  };
  
  const truncatedName = truncateName(employee.attributes.name, 23);

  return (
    <Grid item md={3} sm={4} xs={12}>
      <div style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className='ip-thumbnail' style={{  background: "#5592b4" }}>
          <img src={employee.attributes.avatarLink} alt='' style={{ display: "block", marginLeft: "auto", marginRight: "auto", height:"200px" }}></img>
        </div>
        <br></br>
        <br></br>
        <h3 style={{ fontFamily: "Garamond,serif", fontSize: "25px", color: "brown", display: "flex", fontStretch: "normal", fontWeight: "100", fontStyle: "normal", alignItems: "center", justifyContent: "center" }}>  {truncatedName}</h3>
        <p style={{ color: "#425563", alignItems: "center", justifyContent: "center", fontSize: "15px", textAlign: "center", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", fontWeight: "500", letterSpacing: "-0.2px", lineHeight: "30px" }}>  {employee.attributes.location}</p>
        <p style={{ color: "#425563", alignItems: "center", justifyContent: "center", fontSize: "15px", textAlign: "center", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", fontWeight: "500", letterSpacing: "-0.2px", lineHeight: "30px" }}>{employee.attributes.designation}</p>
        <Link  to={"/people/" + employee.attributes.slug} onClick={handleViewProfileClick} style={{ fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px", color: "#b23427", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", fontWeight: "600" }}>
          View profile <ArrowRightAltIcon />
        </Link>
        <br></br>
        <br></br>
      </div>
    </Grid>
  );
};

export default EmployeeCard;
