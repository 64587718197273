import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./BlogDetails.css";
import "../../src/components/Footer/footer.css";
// import { Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useLocation } from 'react-router-dom';

import { FadeLoader } from "react-spinners";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import axios from "axios";
import DOMPurify from 'dompurify';

import { FaFacebook, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";
import imagelogo from "../assets/img/blog/AO_hero_image_M01_sectors_Energy.webp";
import bannerlogo from "../assets/img/blog/CONTACT-LAWYERS-BannerBubble.webp";
import BlogData from "../data/Blog/blog";
import { useParams, Link, useHistory } from "react-router-dom";
import navbar from "./../data/Navbar/navbar.json";
import { useEffect } from "react";
import {
  CardActions,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const getAllCategories = (category) => {
  const dp = {};
  BlogData.forEach((b) => {
    if (b?.category == category) {
      b?.subCategory?.forEach((sc) => {
        dp[sc] = 1;
      });
    }
  });

  return Object.keys(dp).map((k) => ({ label: k }));

  // {
  //     label: "IP Protection Portfolio Management",
  //   },
  //   {
  //     label: "Litigation And Enforcement",
  //   },
  //   {
  //     label: "Advisors And Opinions",
  //   },
  //   {
  //     label: "Commercial Law Practice",
  //   },
  //   {
  //     label: "Sports Law Practice",
  //   },
  //   {
  //     label: "Media & Entertainment",
  //   },
  //   {
  //     label: "Trade Mark and Copyright Practice",
  //   },
  //   {
  //     label: "Taxation Practice",
  //   },
};
const allInsightsLocation = [
  "Global",
  "USA",
  "UAE",
  "Bangladesh",
  "Vietnam",
  "Myanmar",
  "Nepal",
  "Malaysia",
  "Sri Lanka",
  "Thailand",
  "Indonesia",
  "Philippines",
];

const CommercialLawPractice = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [full, setFull] = useState(false);
  const [expertiseArr, setExpertiseArr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const [isShareVisible, setIsShareVisible] = useState(false);
  const history = useHistory();
  const { slug } = useParams();
  const [insightLocation, setInsightLocation] = useState("Global");
  const [idPost, setidPost] = useState({ insightsPosts: [] });
  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [explorerservices, setExplorerServices] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://khuranaandkhurana-strapi.ntb.one/api/pages?filters[slug][$eqi]=${slug}`
        );
        setData(response.data.data);
        setLoading(false);
        setFull(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchExploreData = async () => {
      try {
        const response = await axios.get(
          `https://khuranaandkhurana-strapi.ntb.one/api/pages?filters[label][$eqi]=${data[0]?.attributes?.label}&fields[0]=title&fields[1]=label&fields[2]=slug`
        );
        setExplorerServices(response.data.data);
        setLoading(false);
        setFull(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };
    fetchExploreData();
  }, [data]);


  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };
  

  updateMetaTags(
    data[0]?.attributes?.meta_field?.title,
    data[0]?.attributes?.meta_field?.description,
    data[0]?.attributes?.meta_field?.keywords

  );

  console.log("fetched_blog_data", data, slug);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          `https://khuranaandkhurana-strapi.ntb.one/api/wp-posts-tests?populate=*&filters[post_status]=publish&pagination[start]=0&pagination[limit]=100&sort=createdAt:desc`
        );
        const data = await response.json();
        console.log("res_data", data?.data);

        if (Array.isArray(data?.data)) {
          console.log("entered_array_if_loop_gcv");

          const insightsPosts = data?.data?.filter((item) => {
            const attributes = item?.attributes;
            return (
              attributes &&
              attributes?.post_status === "publish"
            );
          });

          console.log("insight_posts_jv", insightsPosts);

          insightsPosts.sort(
            (a, b) => new Date(b.attributes.date) - new Date(a.attributes.date)
          );

          const latestPosts = insightsPosts.slice(0, 12);
          console.log("latest_posts", latestPosts);

          setidPost((prev) => ({
            ...prev,
            insightsPosts: latestPosts,
          }));
        } else {
          console.error("Unexpected data structure:", data);
        }
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  useEffect(() => {
    let ArrTemp = [];
    Object.keys(navbar).map(
      (ele) => {
        if (navbar[ele].title == "Expertise") {
          ArrTemp = navbar[ele].megaMenu.flat();
          Object.keys(ArrTemp).map((data, index) => {
            ArrTemp[data].lists.length > 0 &&
              ArrTemp[data].lists.map((val) => {
                if (window.location.pathname == val.link) {
                  setTitle(ArrTemp[data].title);
                }
              });
          });
        }
      },

      []
    );
    let filterAd = ArrTemp.filter((val) => {
      return val.title != title;
    });
    setExpertiseArr(filterAd);
  }, [title]);
  const handleShareClick = () => {
    setIsShareVisible(!isShareVisible);
  };

  const url = window.location.href;
  const onExpertiseItemClick = (slug) => {
    // Redirect to the page using the slug
    history.push("/service/" + setExpertiseArr.slug);
  };

  const handleViewAllClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Add additional logic for navigating to the "View all" link
    // ...
  };
  const handleButtonClick = () => {
    // Redirect to the insights page
    history.push("/insights");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const listItemStyle = {
    fontSize: "18px",
    color: "#b23427",
    fontFamily: "HelveticaNeue-Roman, Arial, Helvetica, sans-serif",
    listStyleType: "disc", // Removes the default list item bullet point
    margin: "0 20px", // Adjust margin between list items if needed
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const menuPaperStyle = {
    position: "absolute",
    maxHeight: "600px", // Set a specific height if needed
    width: "300px",
    marginTop: "10px", // Adjust this value to control the distance from the icon
    marginLeft: "-220px",
    whiteSpace: "nowrap", // Prevents text from wrapping to the next line
    textOverflow: "ellipsis",
    overflowY: "auto", // Enable vertical scrolling if content overflows maxHeight
    overflowX: "hidden", // Hide horizontal scrollbar if content overflows
  };
  const expertiseItemStyle = {
    marginLeft: "30px",
    background: "#b23427",
    width: "300px",
    height: "40px",
    borderRadius: "20px",
    border: "1px solid white",
    color: "white",
    fontSize: "16px",
    fontFamily: "HelveticaNeue-Roman, Arial, Helvetica, sans-serif",
    textAlign: "center",
    paddingTop: "2px",
    transition: "all 0.3s ease", // Add smooth transition for the hover effect
  };

  const onExpertiseItemHover = (event) => {
    event.target.style.background = "white";
    event.target.style.color = "#b23427";
    event.target.style.cursor = "pointer";
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleReadMoreClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: adds smooth scrolling animation
    });
  };
  const onExpertiseItemLeave = (event) => {
    event.target.style.background = "#b23427";
    event.target.style.color = "white";
  };
  

  const blog = BlogData.find(
    (post) => post.slug === slug && post.category !== "Insights"
  );
  const MainServices = getAllCategories(blog?.category);
  const relatedInsights = BlogData.filter(
    (post) =>
      post.subCategory?.some(
        (sc) => !!blog?.subCategory?.find((t) => t === sc)
      ) &&
      post.category === "Insights" &&
      post.slug !== blog.slug &&
      (insightLocation === "Global" || post.location === insightLocation)
  );
  const relatedPosts = BlogData.filter(
    (post) =>
      post.subCategory?.some(
        (sc) => !!blog?.subCategory?.find((t) => t === sc)
      ) &&
      post.category === blog.category &&
      post.slug !== blog.slug
  );
  const sanitizedContent = (i)  =>
    {
      return DOMPurify.sanitize(i)
    }

  return (
    <>
   
      <Header />
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
      <div className="container-fluid headingtext">
      <div
        className="experttext"
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "left",
          marginTop: "30px",
          marginLeft: "30px",
        }}
      >
        <a href="/">
          <p
            className="experttext"
            style={{
              color: "#b23427",
              fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
              fontSize: "14px",
              textTransform: "uppercase",
              fontStyle: "bold",
              fontWeight: "600",
            }}
          >
            HOME
          </p>
        </a>
        <p
          className="experttext"
          style={{
            color: "#425563",
            fontFamily: "HelveticaNeue-Bold,arial,helvetica,sans-serif",
            fontSize: "14px",
            textTransform: "uppercase",
            fontStyle: "bold",
            fontWeight: "600",
            marginTop: "0px",
          }}
        >
          / {data[0]?.attributes?.category} / {data[0]?.attributes?.title}
        </p>
      </div>
      <div className="titleabout" style={{ display: "flex",  }}>
        <div
          className="titleContainer"
          style={{ width: "100%" }}
        >
          <h2
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "50px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {/* {blog.title} */}
            {data[0]?.attributes?.title}
          </h2>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "35px",
              height: "35px",
              marginLeft: "30px",
              alignItems: "center",
              marginTop:"20px",
              cursor: "pointer",
              backgroundColor: "#b23427",
              borderRadius: "50%",
            }}
            onClick={handleShareClick}
          >
            <ShareOutlinedIcon
              style={{
               
                color: "white",
                marginTop: "1.5px",
                marginLeft:"2px"
               
              }}
            />
            {isShareVisible && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                  
                }}
              >
                <FacebookShareButton
                  url={url}
                  quote={`Check out this profile:  `}
                  style={{ width: "auto" }}
                >
                  <FaFacebook size={32} round color="#b23427" />
                </FacebookShareButton>
                <TwitterShareButton
                  className="twitter"
                  url={url}
                  title={`Check out this profile:  `}
                  style={{ width: "auto" }}
                >
                  <TwitterIcon
                    size={35}
                    round
                    style={{ fill: "#b23427", width: "35px", height: "35px" }}
                  />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={url}
                  title="Check out this profile:  "
                  style={{ width: "auto" }}
                >
                  <FaWhatsapp size={32} round color="#b23427" />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={url}
                  title={`Check out this profile:  `}
                  style={{ width: "auto" }}
                >
                  <FaLinkedin size={32} round color="#b23427" />
                </LinkedinShareButton>
              </div>
            )}
          </div>
          <br></br>
          <div>
          <img 
          className="Imagemobile"
            src={`https://khuranaandkhurana.ntb.one/img/${data[0]?.attributes?.thumbnail}`}
            alt={data[0]?.attributes?.title}
            style={{ maxWidth: "60%", objectFit:"contain", margin: "0 30px", float: "right", paddingLeft:"30px" }}
          />
          <p
            className="Headingtext"
            style={{
              textAlign: "justify",
              marginLeft: "30px",
              color: "#425563",
              fontFamily: "Garamond,serif",
              fontSize: "25px",
              wordSpacing: "-2px",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "32px",
              color: "#425563",
              marginRight: "30px",
              textOverflow: "ellipsis",
             
            }}
          >
            {data[0]?.attributes?.shortDescription}
            <br />
            <br />
           
            <span
            style={
          full
            ? {
                // marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
            : {
                maxHeight: "250px",
                overflow: "hidden",
                // marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
        } 
        dangerouslySetInnerHTML=
        {{ __html: data[0]?.attributes?.description }} 
        >
            </span>
          </p>
          </div>
        </div>
        {/* <div
          className="image-container"
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            maxWidth: "100%",
            float:"right",
          }}
        >
          <img
            className="profile-image"
            src={require("../assets/img/" + (blog.thumb || blog.thumbnail))}
            alt={blog.title}
            style={{ maxWidth: "100%", objectFit:"contain", margin: "0 30px" }}
          />
        </div> */}
      </div>

      {/* <h2
        style={{
          marginTop: "40px",
          marginLeft: "30px",
          color: "#b23427",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Garamond-Regular,georgia,serif",
        }}
        className="profile-caption"
      >
        {blog.title1}
      </h2> */}
      {/* <div
        style={
          full
            ? {
                marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
            : {
                maxHeight: "250px",
                overflow: "hidden",
                marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
        }
        dangerouslySetInnerHTML={{ __html: blog.description }}
      ></div> */}
      {full && (
        <>
      <h2
        style={{
          marginTop: "10px",
          marginLeft: "30px",
          color: "#b23427",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: "400",
          fontFamily: "Garamond-Regular,georgia,serif",
        }}
        className="profile-caption"
      >
        {data[0]?.attributes?.title1}
      </h2>
      <div
        style={
          full
            ? {
                marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
            : {
                maxHeight: "250px",
                overflow: "hidden",
                marginLeft: "30px",
                marginRight: "30px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                textAlign: "justify",
              }
        }
        dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description1 }}
      ></div>
      </>
      )}

      {full && (
        <>
          <h2
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title2}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description2 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title3}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description3 }}
          ></div>
        </>
      )}

      {/* {full&&(
      <>
      <button
        style={{
          fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
          fontSize: "16px",
          padding: "10px 20px",
          backgroundColor: "white",
          color: "#b23427",
          border: "1px solid #b23427",
          cursor: "pointer",
          width: "auto",
          height: "50px",
          marginLeft: "30px",
          overflow:"hidden",
          marginTop: "20px",
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = "#b23427";
          e.target.style.color = "white";
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = "white";
          e.target.style.color = "#b23427";
        }}
        dangerouslySetInnerHTML={{ __html: blog.button1}}
      >
      </button>
      </>
)} */}

      {full && (
        <>
          <h2
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title4}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description4 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title5}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description5 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title6}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description6 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title7}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description7 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title8}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description8 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title9}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description9 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title10}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description10 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title11}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description11 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title12}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description12 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title13}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description13 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title14}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description14 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title15}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description15 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title16}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description16 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title17}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description17 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title18}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description18 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title19}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description19 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title20}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description20 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title21}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description21 }}
          ></div>
        </>
      )}
      {full && (
        <>
          <h2
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title22}
          </h2>
          <div
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description22 }}
          ></div>
        </>
      )}
      {/* {full && (
        <>
          <h2
            style={{
              marginTop: "40px",
              marginLeft: "30px",
              color: "#b23427",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              fontFamily: "Garamond-Regular,georgia,serif",
            }}
            className="profile-caption"
          >
            {data[0]?.attributes?.title22}
          </h2>
          <div
            className="descriptionDiv"
            style={
              full
                ? {
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                  }
                : {
                    maxHeight: "250px",
                    overflow: "hidden",
                    marginLeft: "30px",
                    marginRight: "30px",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    textAlign: "justify",
                    textDecoration: "none",
                    transition: "color 0.3s",
                  }
            }
            dangerouslySetInnerHTML={{ __html: data[0]?.attributes?.description21 }}
          ></div>
        </>
      )} */}
      <div className="readmoremobile">
        <button
          className="mobilereadbuttton"
          style={{
            fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
            fontSize: "16px",
            padding: "10px 20px",
            backgroundColor: "white",
            color: "#b23427",
            border: "1px solid #b23427",
            cursor: "pointer",
            width: "200px",
            height: "50px",
            marginLeft: "30px",
            overflow: "hidden",
            marginTop: "20px",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#b23427";
            e.target.style.color = "white";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "white";
            e.target.style.color = "#b23427";
          }}
          onClick={() => setFull(!full)}
        >
          {full ? "Read Less" : "Read More"}
        </button>
      </div>
      </div>
      )}
      <br></br>
      <br></br>
      <br></br>
      {/* <br></br>
      <br></br>
      <br></br> */}
      <div style={{ width: "auto", background: "#e7eaeb" }}>
        <div style={{}}>
          <h2
            style={{
              fontSize: "50px",
              color: "#b23427",
              fontFamily: "Garamond,serif",
              paddingTop: "70px",
              textAlign: "center",
              fontWeight: "400",
            }}
          >
            Explore Our Services
          </h2>
        </div>
        <div
          className="Explormobile"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "40px",
          }}
        >
          <div
            className="Explormobile"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              maxWidth: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
              listStyleType: "disc",
            }}
          >
            {explorerservices.map((p) => (
              <li
                className="listtext"
                style={{
                  ...listItemStyle,
                  textDecoration: "none",
                  lineHeight: "3",
                  listStyleType: "disc",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onMouseOver={(e) =>
                  (e.target.style.textDecoration =
                    " underline 2px solid #b23427")
                }
                onMouseOut={(e) => (
                  (e.target.style.textDecoration = "none"),
                  (e.target.style.borderBottom = "none"),
                  (e.target.style.paddingBottom = "0")
                )}
                onClick={() => history.push("/service/" + p?.attributes?.slug)}
              >
                {p?.attributes?.title}
              </li>
            ))}
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div
        className="outterdivcircle"
        style={{ position: "relative", width: "100%" }}
      >
        <img
          className="mobilebanner"
          src={bannerlogo}
          alt="Banner Logo"
          style={{ width: "100%", height: "auto", margin: "75px 0px" }}
        />
        <div className="circlestyle">
          <h3
            style={{
              marginTop: "40px",
              fontFamily: "Garamond-Regular,georgia,serif",
              fontSize: "35px",
              color: "#fff",
              zIndex: "9999",
              fontStyle: "normal",
              fontStrech: "normal",
              marginLeft: "5px",
            }}
          >
            Meet Our Lawyers
          </h3>
          <Link
            to={"/people?practices=" + data[0]?.attributes?.subCategory}
            style={{
              fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
              fontSize: "16px",
              padding: "10px 20px",
              backgroundColor: "#b23427",
              color: "white",
              border: "1px solid white",
              cursor: "pointer",
              width: "150px",
              height: "50px",
              marginLeft: "px",
              marginTop: "20px",
              textAlign: "center",
            }}
            onClick={handleViewAllClick} // Call the function on button click
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.color = "#b23427";
              e.target.style.border = "1px solid white";
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "#b23427";
              e.target.style.color = "white";
            }}
          >
            View all
          </Link>
        </div>
      </div>

      {relatedInsights.length > 0 && (
        <div className="newsmobile" style={{ marginLeft: "100px" }}>
          <div className="newsleft" style={{ marginLeft: "35px" }}>
            <h2
              className="newstext"
              style={{
                fontSize: "45px",
                fontFamily: "Garamond,serif",
                color: "#425563",
                fontWeight: "400",
                marginBottom: "10px",
              }}
            >
              News & insights
            </h2>
          </div>
          <FormControl
            className="globalmobile"
            variant="standard"
            sx={{ mx: 5, minWidth: 420 }}
          >
            <Select
              value={insightLocation}
              onChange={(e) => setInsightLocation(e.target.value)}
              sx={{
                color: "#b23427",
                fontSize: "25px",
                backgroundColor: "white !important",
                borderColor: "#b23427 !important",
                "&:before": {
                  borderColor: "#b23427 !important",
                  borderWidth: "2px !important",
                },
                "&:after": {
                  borderColor: "#b23427 !important",
                  borderWidth: "2px !important",
                },
                "&:not(.Mui-disabled):hover::before": {
                  borderColor: "#b23427 !important",
                  borderWidth: "2px !important",
                },
              }}
              inputProps={{
                style: { fontSize: 100, backgroundColor: "white !important" },
              }}
            >
              {allInsightsLocation.map((insight) => (
                <MenuItem
                  value={insight}
                  style={{ color: "#b23427", "&:hover": { fontWeight: "900" } }}
                >
                  {insight}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div
        className="viewmoredetails"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          marginLeft: "100px",
          // marginTop: "-35px",
        }}
      >
        {idPost?.insightsPosts?.map((insight) => (
          <Card
            className="cardblogmobile"
            variant="outlined"
            sx={{
              background: "white",
              width: "40%",
              margin: "10px 30px",
              borderBottom: "1px solid #ccc",
              borderLeft: "none", // Hide left border
              borderRight: "none", // Hide right border
              borderTop: "none",
              height: "35rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={`https://khuranaandkhurana-strapi.ntb.one${insight?.attributes?.featuredImage?.data?.attributes?.url}`}
                  style={{ width: "350px", height: "219px" }}
                  alt={insight?.attributes?.post_title}
                />
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontFamily:
                        "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                      color: "#b23427",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  >
                    PUBLICATIONS:
                  </Typography>
                  <p
                    style={{
                      fontSize: "13px",
                      fontFamily:
                        "HelveticaNeue-Bold,arial,helvetica,sans-serif",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      fontWeight: "600",
                      color: "#425563",
                      marginLeft: "5px",
                    }}
                  >
                    {formatDate(insight?.attributes?.post_date)}
                  </p>
                </div>
                <Typography
                  style={{
                    color: "#425563",
                    fontSize: "19px",
                    fontFamily:
                      "HelveticaNeue-BoldCondensed,arial,helvetica,sans-serif",
                    padding: "0px 25px 0px 0px",
                    fontWeight: "bolder",
                    letterSpacing: "-0.2px",
                    overflowWrap: "break-word",
                    paddingTop: "15px",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#b23427";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "#425563";
                  }}
                >
                  {insight?.attributes?.post_title}
                </Typography>
                <p
                  style={{
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    color: "#425563",
                    letterSpacing: "-0.2px",
                    marginTop: "20px",
                    marginBottom: "15px",
                    // height:"5rem",
                    // overflowY:"scroll"
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizedContent(insight?.attributes?.post_content).split(' ').slice(0, 20).join(' ')  + '...'
                    }}
                  />
                </p>
              </div>
              <Link
                to={"/insights/" + insight?.attributes?.post_name}
                style={{
                  fontSize: "16px",
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  color: "#b23427",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textDecoration: "none",
                  marginTop: "auto",
                }}
                onClick={handleReadMoreClick}
                onMouseOver={(e) => {
                  e.target.style.textDecoration = "underline";
                  e.target.style.color = "#b23427";
                }}
                onMouseOut={(e) => {
                  e.target.style.textDecoration = "none";
                }}
              >
                Read more <ArrowRightAltIcon />
              </Link>
            </CardContent>
          </Card>
        ))}
      </div>
      <br/>
      <div
        className="viewmorebutton"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          style={{
            fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
            fontSize: "16px",
            padding: "10px 20px",
            backgroundColor: "white",
            color: "#b23427",
            cursor: "pointer",
            width: "250px",
            height: "50px",
            border: "1px solid #b23427",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#b23427";
            e.target.style.color = "white";
            e.target.style.border = "1px solid #b23427";
            // e.target.style.textDecoration = "underline";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "white";
            e.target.style.color = "#b23427";
            e.target.style.border = "1px solid #b23427";
            e.target.style.textDecoration = "none";
          }}
          onClick={handleButtonClick}
        >
          View More
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>

      {/* <div
        className="expertisemobile"
        style={{
          width: "auto",
          height: "320px",
          backgroundColor: "#b23427",
          textAlign: "center",
        }}
      >
        <h3
          style={{
            color: "white",
            fontFamily: "Garamond, serif",
            fontSize: "50px",
            textAlign: "center",
            paddingTop: "60px",
          }}
        >
          Related expertise
        </h3>
        <div
          className="mobilereleated"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
 
          {expertiseArr.map((expertNav, index) => (
            <button
              key={index}
              style={{
                ...expertiseItemStyle,
                margin: "10px",
              }}
              onMouseEnter={onExpertiseItemHover}
              onMouseLeave={onExpertiseItemLeave}
              onClick={() => {
                history.push(expertNav.link);
                window.scrollTo(0, 0);
              }}
            >
              {expertNav.title} 
            </button>
          ))}
        </div>
      </div> */}

      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
};

export default CommercialLawPractice;
