import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FormControl, Grid, InputAdornment, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import bloglogo from '../assets/img/blog/AO_hero_image_M01_sectors_Energy.webp'
import { useLocation,useHistory } from 'react-router-dom';
import peopleData from '../data/People/Employees.json';
import blogsData from "../data/Blog/blog";
const GlobalSearchPage = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState([])
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const menuStyle = {
    overflowY: 'hidden',
  };
  const menuPaperStyle = {
    maxHeight: '600px', // Set a specific height if needed
    overflow: 'auto',
    width: "300px"
  };
  const [qsearch, setQSearch] = useState("")
  const [mode, setMode] = useState("all");
  const [sort, setSort] = useState("relevance");
  const [blogs, setBlogs] = useState([]);
  const [peoples, setPeople] = useState([]);
  useEffect(()=>{
    setQSearch(query.get("q")||"");
  },[query])

  useEffect(()=>{
    const filteredBlogs = blogsData.filter(b=>{
      if(qsearch && !(b.title.toLowerCase().includes(qsearch.toLowerCase())||b.shortDescription?.toLowerCase().includes(qsearch.toLowerCase())))
      {
        return false
      }
      if(mode=="Expertise" && b.category!=="Expertise"){
        return false
      }if(mode=="Insights" && b.category!=="Insights"){
        return false
      }if(mode=="Others" &&( b.category==="Insights"||b.category==="Expertise")){
        return false
      }
      return true
    })
    if(sort=="date"){
      filteredBlogs.sort((a,b)=>{
        return new Date(b.publishDate).getTime() -new Date(a.publishDate).getTime()
      })
    }
    setBlogs(filteredBlogs);
    setPeople(peopleData.filter(p=>!qsearch||p.name.toLowerCase().includes(qsearch.toLowerCase())));

  },[qsearch,mode,sort])
  const handleChange = (e) => {
    query.set("q",e.target.value);
    history.push({
      search:query.toString()
    })
  }
  
  return (
    <div>
      <Header />
      <div style={{ alignItems: "center", justifyContent: "center", marginTop: "150px" }}>
        <TextField style={{ width: "70%", alignItems: "center", justifyContent: "center", marginLeft: "200px", marginRight: "auto" }}
          fullWidth
          label=""
          id="fullWidth"
          variant="standard"
          value={qsearch}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: '#425563', fontSize: 60 }} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              color: '#425563',
              fontSize: '27px',
              fontFamily: 'Garamond-Regular, Georgia, serif',
              paddingBottom: "15px"
            },
          }}
        />
        <div style={{ display: "flex", marginLeft: "190px", marginTop: "5px" }}>
          <div style={{ margin: "20px", cursor: "pointer" }} onClick={() => setMode("all")}>
            <h3 style={{ color: mode === "all" ? "#b23427" : "#425563", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px" }}>All ({blogsData.length})</h3>
          </div>
          <div style={{ margin: "20px", cursor: "pointer" }} onClick={() => setMode("Expertise")}>
            <h3 style={{ color: mode === "Expertise" ? "#b23427" : "#425563", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px" }}>Expertise ({blogsData.filter(b=>b.category=="Expertise").length})</h3>
          </div>
          <div style={{ margin: "20px", cursor: "pointer" }} onClick={() => setMode("Insights")}>
            <h3 style={{ color: mode === "Insights" ? "#b23427" : "#425563", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px" }}>
              News and Insights ({blogsData.filter(b=>b.category=="Insights").length})</h3>
          </div>
          <div style={{ margin: "20px", cursor: "pointer" }} onClick={() => setMode("Others")}>
            <h3 style={{ color: mode === "Others" ? "#b23427" : "#425563", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "16px" }}>Other ({blogsData.filter(b=>b.category!=="Insights" && b.category!=="Expertise").length})</h3>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <div style={{ display: "flex", marginTop: "-100px", marginLeft: "100px" }}>
          <div>
            <h3 style={{ color: "#b23427", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", fontSize: "22px", textAlign: "left", marginLeft: "100px", zIndex: "99999", marginTop: "20px" }}>{Math.min(qsearch.length,blogs.length)} Results ordered by</h3>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "10px", marginLeft: "20px" }}>
              <FormControl>
                <Select
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                >
                  <MenuItem value={"relevance"}>Relevance</MenuItem>
                  <MenuItem value={"date"}>Date</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

        </div>
        <div style={{"margin":"20px 200px"}}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8} sm={6} >
               {qsearch && blogs.map(blog=>(
                  <div style={{ borderBottom: "1px solid #E5E4E2", paddingBottom: "10px",marginBottom: "20px", backgroundColor: "white", width: "600px" }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <h3 style={{ fontSize: "15px", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", color: "#b23427", textTransform: "uppercase", fontWeight: "600" }}>PUBLICATIONS:</h3>
                    </div>
                    <div >
                      <h3 style={{ fontSize: "12px", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", color: "#425563", marginLeft: "5px" }}>{blog.publishDate}</h3>
                    </div>
                  </div>
                  <h3
                    style={{
                      fontSize: "20px",
                      fontFamily: "HelveticaNeue-BoldCondensed,arial,helvetica,sans-serif",
                      color: "inherit",
                      textDecoration: "none", // Initial text decoration is none
                      transition: "color 0.3s ease, text-decoration 0.3s ease",
                      fontWeight: "600" // Add a smooth transition for color and text decoration changes
                    }}
                    onMouseOver={(e) => {
                      e.target.style.color = "#B23427"; // Change color on hover
                      e.target.style.textDecoration = "underline"; // Add underline on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = "#425563"; // Revert color when not hovering
                      e.target.style.textDecoration = "none"; // Remove underline when not hovering
                    }}
                  >{blog.title}
                  </h3>
                  <p style={{ fontSize: "15px", fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif", color: "#425563" }}>{blog.shortDescription}</p>
                  <a
                    href={(blog.category==="Insights"?"/insights/":"/service/")+blog.slug}
                    style={{
                      fontFamily: "HelveticaNeue-Roman, Arial, Helvetica, sans-serif",
                      fontSize: "1.1rem",
                      color: "brown",
                      textAlign: "left",
                      textDecoration: "none", // Initial text decoration is none
                      transition: "text-decoration 0.3s ease", // Add a smooth transition for text decoration changes
                    }}
                    onMouseOver={(e) => {
                      e.target.style.textDecoration = "underline"; // Add underline on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.textDecoration = "none"; // Remove underline when not hovering
                    }}
                  >
                    Read more <ArrowRightAltIcon />
                  </a>

                </div>
                ))}
              </Grid>
              <Grid item xs={12} md={4} sm={6} >
                <h3 style={{ fontSize: "24px", color: "#b23427", fontFamily: "Garamond,serif", borderBottom: "1px solid #E5E4E2", paddingBottom: "10px" }}>People ({Math.min(qsearch.length,peoples.length)})</h3>
                <div>
                  {qsearch && peoples.map(people=>(<div style={{ display: "flex", borderBottom: "1px solid #E5E4E2", paddingBottom: "20px" }}>
                  <div className='ip-thumbnail' style={{paddingTop:"20px", width:"50%",maxHeight:"120px",background:"#5592b4"}}>
      <img src={people.logo} height={"150px"} alt='' style={{display:"block",marginLeft:"auto",marginRight:"auto",height:"100%"}}></img>
      </div>
                    <div>
                      <h3 style={{ color: "#b23427", fontSize: "16px", fontFamily: "Garamond,serif", marginLeft: "20px", marginTop: "30px" }}>{people.name}</h3>
                      <h3 style={{ fontSize: "13.845px", fontFamily: "Arial,Helvetica,sans-serif", color: "#425563", marginLeft: "20px" }}>{people.location}</h3>
                    </div>
                  </div>))}
                </div>

              </Grid>
            </Grid>
          </div>
      </div>
      <Footer />
    </div>
  )
}

export default GlobalSearchPage