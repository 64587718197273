

import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CorporateandCommercialLawPracticeBrochure from "../PDFs/CorporateandCommercialLawPracticeBrochure.pdf";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function CorporateBrouchure() {
  const iframeRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const iframeKey = Date.now();
  useEffect(() => {
    const handleBackButton = () => {
      if (history.action === "POP") {
        history.push(history.location.pathname);
      } else {
        history.push("/");
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [history]);
  useEffect(() => {
    const shouldOpenInNewTab = location.pathname === history.push("/");
    if (shouldOpenInNewTab) {
      window.open(CorporateandCommercialLawPracticeBrochure, "_blank");
    } else {
      const newIframe = document.createElement("iframe");
      newIframe.src = window.open(CorporateandCommercialLawPracticeBrochure, "_blank");
      newIframe.style.width = "100%";
      newIframe.style.height = "100vh";
      iframeRef.current.appendChild(newIframe);
    }
  }, [location.pathname, history]);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        key={iframeKey}
        title="Corporate Brochure"
        src={CorporateandCommercialLawPracticeBrochure}
        ref={iframeRef}
        style={{ width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
}
export default CorporateBrouchure;