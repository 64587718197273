import "./ContuctPatentSearch.css";
import { useState } from "react";
import axios from "axios";
import LinearLoader from "./LinearLoader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  useTheme,
} from "@material-ui/core";
import Xarrow, { Xwrapper } from "react-xarrows";

const ConductFreePatentSearch = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [patentDetails, setPatentDetails] = useState(null);
  const [buttonloader, setButtonLoader] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchExtraResults, setSearchExtraResults] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("PATENT_DETAILS1");
  const [panelContent, setPanelContent] = useState(null);
  const [activeLink, setActiveLink] = useState("PATENT_DETAILS");
  const [activeLink2, setActiveLink2] = useState("APPLICATION STATUS DETAILS");
  const [dynamicFields, setDynamicFields] = useState([
    { field: "title", value: "", condition: "AND", APIvalue: "TI" },
  ]);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState({});
  const theme = useTheme();
  const fieldOptions = [
    { value: "title", APIvalue: "TI", label: "Title" },
    { value: "abstract", APIvalue: "ABS", label: "Abstract" },
    { value: "inventor", APIvalue: "CSP", label: "Complete Specification" },
    { value: "assignee", APIvalue: "AP", label: "Application Number" },
    { value: "applicant", APIvalue: "patent-number", label: "Patent Number" },
    { value: "application-Number", APIvalue: "PA", label: "Applicant Name" },
    {
      value: "publication-number",
      APIvalue: "ANC",
      label: "Applicant Country",
    },
    { value: "applicant-address", APIvalue: "ANA", label: "Applicant Address" },
    { value: "priority-number", APIvalue: "IN", label: "Inventor Name" },
    { value: "filing-date", APIvalue: "INC", label: "Inventor Country" },
    { value: "publication-date", APIvalue: "INA", label: "Inventor Address" },
    { value: "priority-date", APIvalue: "FO", label: "Filing Office" },
    {
      value: "cpc",
      APIvalue: "IC",
      label: "International Patent Classification (IPC)",
    },
    { value: "ipc", APIvalue: "PAP", label: "PCT Application Number" },
  ];

  console.log("dynamic_fields", dynamicFields);
  console.log("@panel_Content", panelContent);
  const handleAddMoreField = () => {
    const nextIndex = dynamicFields.length % fieldOptions.length;
    const nextOption = fieldOptions[nextIndex];
    setDynamicFields([
      ...dynamicFields,
      {
        field: nextOption.value,
        value: "",
        condition: "AND",
        APIvalue: nextOption.APIvalue,
      },
    ]);
  };

  const handleRemoveMoreField = () => {
    setDynamicFields(dynamicFields.slice(0, -1));
  };

  const handleAddFieldChange = (index, field, value) => {
    const newFields = [...dynamicFields];
    if (field === "field") {
      const selectedOption = fieldOptions.find(option => option.value === value);
      if (selectedOption) {
        newFields[index].field = value;
        newFields[index].APIvalue = selectedOption.APIvalue;
      }
    } else {
      newFields[index][field] = value;
    }
    setDynamicFields(newFields);
  };

  const fetchData = async () => {
    setLoading(true);
    let search_string = "";
    for (let i = 0; i < dynamicFields.length; i++) {
      search_string += `ItemField${i + 1}%3D${dynamicFields[i]?.APIvalue}%26TextField${i + 1}%3D${encodeURIComponent(dynamicFields[i]?.value)}%26LogicField${i + 1}%3D${dynamicFields[i]?.condition}%26`;
    }
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/patents/?search_string=${search_string}`
      );

      if (response.data && response.data.dataset) {
        setSearchResults(response.data.dataset);
      }
      console.log("Search Result is", response.data.dataset);

      if (response.data && response.data.extra) {
        setSearchExtraResults(response.data.extra);
      }
      console.log(
        "Search Result extra is 237",
        typeof response.data.extra.total_result,
        response.data.extra.total_result
      );
    } catch (error) {
      console.error("Error fetching trademarks:", error);
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPatentDetails = async (applicationNumber) => {
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/patents/details/?application_number=${applicationNumber}`
      );
      if (response.data) {
        setPatentDetails(response.data);
        setPanelContent(response?.data);
        setIsPanelOpen(true);
        setActiveTab("PATENT_DETAILS1");
      }
      console.log("Patent details", response.data);
    } catch (error) {
      console.error("Error fetching patent details:", error);
    }
  };
  const fetchApplicationStatus = async (applicationNumber) => {
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/patents/application-status/?application_number=${applicationNumber}`
      );
      if (response.data) {
        setApplicationStatus(response.data);
        setPanelContent(response?.data);
        setIsPanelOpen(true);
        setActiveTab("APPLICATION_STATUS");
      }
      console.log("Application status", response.data);
    } catch (error) {
      console.error("Error fetching application status:", error);
    }
  };

  const handlePatentDetailsClick = (applicationNumber) => {
    setIsPanelOpen(true);
    setButtonLoader("Patent");
    fetchPatentDetails(applicationNumber).finally(() => setLoading(false));
  };

  const handleApplicationStatusClick = (applicationNumber) => {
    setIsPanelOpen(true);
    setButtonLoader("Application");
    fetchApplicationStatus(applicationNumber).finally(() => setLoading(false));
  };

  const closePanel = () => {
    setIsPanelOpen(false);
    setPanelContent(null);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleLinkClick2 = (link) => {
    setActiveLink2(link);
  };

  const handlePagination = async (page) => {
    setLoading(true);
    let search_string = ``;
    if (page === "next") {
      search_string = `ItemField1%3DTI%26TextField1%3D${encodeURIComponent(
        dynamicFields[0]?.value
      )}%26LogicField1%3DAND%26&page=${
        parseInt(searchExtraResults.current_page) + 1
      }&current_page=${searchExtraResults.current_page}&total_pages=${
        searchExtraResults.total_pages
      }&total_result=${searchExtraResults.total_result}&display_total_results=${
        searchExtraResults.display_total_results
      }&connection_name=${searchExtraResults.connection_name}&query_string=${
        searchExtraResults.query_string
      }&title=${searchExtraResults.title}`;
    } else if (page === "prev") {
      search_string = `ItemField1%3DTI%26TextField1%3D${encodeURIComponent(
        dynamicFields[0]?.value
      )}%26LogicField1%3DAND%26&page=${
        parseInt(searchExtraResults.current_page) - 1
      }&current_page=${searchExtraResults.current_page}&total_pages=${
        searchExtraResults.total_pages
      }&total_result=${searchExtraResults.total_result}&display_total_results=${
        searchExtraResults.display_total_results
      }&connection_name=${searchExtraResults.connection_name}&query_string=${
        searchExtraResults.query_string
      }&title=${searchExtraResults.title}`;
    } else if (page === "first") {
      search_string = `ItemField1%3DTI%26TextField1%3D${encodeURIComponent(
        dynamicFields[0]?.value
      )}%26LogicField1%3DAND%26&page=1&current_page=${
        searchExtraResults.current_page
      }&total_pages=${searchExtraResults.total_pages}&total_result=${
        searchExtraResults.total_result
      }&display_total_results=${
        searchExtraResults.display_total_results
      }&connection_name=${searchExtraResults.connection_name}&query_string=${
        searchExtraResults.query_string
      }&title=${searchExtraResults.title}`;
    } else if (page === "last") {
      search_string = `ItemField1%3DTI%26TextField1%3D${encodeURIComponent(
        dynamicFields[0]?.value
      )}%26LogicField1%3DAND%26&page=${
        searchExtraResults.total_pages
      }&current_page=${searchExtraResults.current_page}&total_pages=${
        searchExtraResults.total_pages
      }&total_result=${searchExtraResults.total_result}&display_total_results=${
        searchExtraResults.display_total_results
      }&connection_name=${searchExtraResults.connection_name}&query_string=${
        searchExtraResults.query_string
      }&title=${searchExtraResults.title}`;
    }
    try {
      const response = await axios.get(
        `https://graphdata.khuranaandkhurana.com/patents/?search_string=${search_string}`
      );

      if (response.data && response.data.dataset) {
        setSearchResults(response.data.dataset);
      }
      console.log("Search Result is", response.data.dataset);

      if (response.data && response.data.extra) {
        setSearchExtraResults(response.data.extra);
      }
      console.log(
        "Search Result extra is 237",
        typeof response.data.extra.total_result,
        response.data.extra.total_result
      );
    } catch (error) {
      console.error("Error fetching trademarks:", error);
    } finally {
      setLoading(false);
    }
  };

  // for download pdf File

  async function downloadFile(fileName, fileUrl) {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download failed", error);
    }
  }

  const formatContent = (content) => {
    const formattedContent = content
      .replace(/\n/g, "<br />")
      .replace(/\t/g, "&nbsp;");

    return { __html: formattedContent };
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="main_container">
        <div className="wrapperpatent">
          <div className="search_container">
            <section className="patent_search">
              <div className="content">
                <h3>Patent Search</h3>
                <span>Search by Title or by making combinations</span>
              </div>
              {dynamicFields.map((field, index) => (
                <div
                  key={index}
                  className="search_div"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="title" style={{ marginRight: "10px" }}>
                    <select
                      className="custom-select"
                      value={field.field}
                      onChange={(e) =>
                        handleAddFieldChange(index, "field", e.target.value)
                      }
                    >
                      {fieldOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input" style={{ marginRight: "10px" }}>
                    <input
                      type="search"
                      name="search"
                      placeholder={`Search by ${
                        fieldOptions.find(
                          (option) => option.value === field.field
                        ).label
                      }`}
                      value={field.value}
                      onChange={(e) =>
                        handleAddFieldChange(index, "value", e.target.value)
                      }
                    />
                  </div>
                  {index !== dynamicFields.length - 1 && (
                    <div className="title" style={{ marginRight: "10px" }}>
                      <select
                        className="custom-select"
                        value={field.condition}
                        onChange={(e) =>
                          handleAddFieldChange(
                            index,
                            "condition",
                            e.target.value
                          )
                        }
                      >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                      </select>
                    </div>
                  )}
                  {index === dynamicFields.length - 1 && (
                    <div className="searchbtn" style={{ marginRight: "10px" }}>
                      <button onClick={fetchData} disabled={isMoreLoading}>
                        Search
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <div style={{ display: "flex", gap: "1rem" }}>
                <div
                  className="add_field_button"
                  style={{ marginTop: "10px", width: "6rem" }}
                >
                  <button
                    style={{ textAlign: "left", maxWidth: "fit-content" }}
                    onClick={handleAddMoreField}
                  >
                    + Add field
                  </button>
                </div>

                {dynamicFields.length > 1 && (
                  <div
                    className="remove_field_button"
                    style={{ marginTop: "10px", maxWidth: "fit-content" }}
                  >
                    <button
                      style={{ textAlign: "left", maxWidth: "fit-content" }}
                      onClick={handleRemoveMoreField}
                    >
                      - Remove last field
                    </button>
                  </div>
                )}
              </div>
            </section>
            {loading && (
              <div className="lower-margin-loader">
                <div className="loader">
                  <LinearLoader />
                </div>
              </div>
            )}
          </div>

          <div className="result_container">
            {searchResults.length > 0 && (
              <section className="result_section">
                {searchExtraResults && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="results">
                        {searchExtraResults.total_result}&nbsp;Results
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        {parseInt(searchExtraResults?.current_page) === 1 ? (
                          <p
                            disabled
                            style={{ color: "gray", cursor: "not-allowed" }}
                          >
                            First
                          </p>
                        ) : (
                          <p
                            onClick={() => handlePagination("first")}
                            style={{ color: "#b23427", cursor: "pointer" }}
                          >
                            First
                          </p>
                        )}
                        {parseInt(searchExtraResults?.current_page) === 1 ? (
                          <p style={{ color: "gray", cursor: "not-allowed" }}>
                            Prev
                          </p>
                        ) : (
                          <p
                            onClick={() => handlePagination("prev")}
                            style={{ color: "#b23427", cursor: "pointer" }}
                          >
                            Prev
                          </p>
                        )}
                        <p>{searchExtraResults?.current_page}</p>
                        {parseInt(searchExtraResults?.current_page) ===
                        parseInt(searchExtraResults?.total_pages) ? (
                          <p style={{ color: "gray", cursor: "not-allowed" }}>
                            Next
                          </p>
                        ) : (
                          <p
                            onClick={() => handlePagination("next")}
                            style={{ color: "#b23427", cursor: "pointer" }}
                          >
                            Next
                          </p>
                        )}

                        {parseInt(searchExtraResults?.current_page) ===
                        parseInt(searchExtraResults?.total_pages) ? (
                          <p style={{ color: "gray", cursor: "not-allowed" }}>
                            Last
                          </p>
                        ) : (
                          <p
                            onClick={() => handlePagination("last")}
                            style={{ color: "#b23427", cursor: "pointer" }}
                          >
                            Last
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="patent-list-container">
                  {searchResults.map((patent, index) => (
                    <div
                      key={patent.application_number}
                      className="patent-item"
                    >
                      <p className="patent_title">{patent.title}</p>
                      <p className="patent_title">
                        Application Date :{" "}
                        <span>{patent.application_date}</span>
                      </p>
                      <p className="patent_title">
                        Application Number :{" "}
                        <span>{patent.application_number}</span>
                      </p>
                      <div className="status_link">
                        <span className="status">{patent.status}</span>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button
                            className="detail-btn"
                            onClick={() =>
                              handlePatentDetailsClick(
                                patent.application_number
                              )
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>PATENT DETAILS</span>
                          </button>
                          <button
                            className="parent-btn"
                            onClick={() =>
                              handleApplicationStatusClick(
                                patent.application_number
                              )
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>APPLICATION STATUS</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </div>
          { searchExtraResults.total_result === '0' && 
          <Typography style={{marginTop:"2rem"}} className='text-center' color={"textSecondary"}>0 Results</Typography> 
          }

          <div
            className={`overlay ${isPanelOpen ? "open" : ""}`}
            onClick={closePanel}
          ></div>

          <div className={`slide-panel ${isPanelOpen ? "open" : ""}`}>
            <span className="close-btn" onClick={closePanel}>
              &times;
            </span>

            {panelContent ? (
              <div className="panel-content">
                <div className="patent-details-container">
                  {activeTab === "PATENT_DETAILS1" && (
                    <>
                      <div className="link_heading">
                        <a
                          href="#"
                          onClick={() => handleLinkClick("PATENT_DETAILS")}
                          className={
                            activeLink === "PATENT_DETAILS" ? "active" : ""
                          }
                        >
                          PATENT DETAILS
                        </a>
                        <a
                          href="#"
                          onClick={() => handleLinkClick("INVENTORS")}
                          className={activeLink === "INVENTORS" ? "active" : ""}
                        >
                          INVESTORS
                        </a>
                        <a
                          href="#"
                          onClick={() => handleLinkClick("APPLICANTS")}
                          className={
                            activeLink === "APPLICANTS" ? "active" : ""
                          }
                        >
                          APPLICANTS
                        </a>
                        <a
                          href="#"
                          onClick={() => handleLinkClick("ABSTRACT")}
                          className={activeLink === "ABSTRACT" ? "active" : ""}
                        >
                          ABSTRACT
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            handleLinkClick("COMPLETE_SPECIFICATION")
                          }
                          className={
                            activeLink === "COMPLETE_SPECIFICATION"
                              ? "active"
                              : ""
                          }
                        >
                          COMPLETE SPECIFICATION
                        </a>
                      </div>

                      {activeLink === "PATENT_DETAILS" && (
                        <>
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Invention Title</td>
                                <td>{panelContent?.invention_title}</td>
                              </tr>

                              <tr>
                                <td>Publication Number: </td>
                                <td>{panelContent?.publication_number}</td>
                              </tr>

                              <tr>
                                <td>Publication Date:</td>

                                <td>{panelContent?.publication_date}</td>
                              </tr>

                              <tr>
                                <td>Publication Type:</td>
                                <td>{panelContent?.publication_type}</td>
                              </tr>
                              <tr>
                                <td>Application Number</td>
                                <td>{panelContent?.application_number}</td>
                              </tr>
                              <tr>
                                <td>Application Filing Date</td>
                                <td>{panelContent?.application_filing_date}</td>
                              </tr>
                              <tr>
                                <td>Priority Number</td>
                                <td>{panelContent?.priority_number}</td>
                              </tr>
                              <tr>
                                <td>Priority Country</td>
                                <td>{panelContent?.priority_country}</td>
                              </tr>
                              <tr>
                                <td>Priority Date</td>
                                <td>{panelContent?.priority_date}</td>
                              </tr>
                              <tr>
                                <td>Field of Invention</td>
                                <td>{panelContent.field_of_invention}</td>
                              </tr>

                              <tr>
                                <td>Classification</td>
                                <td>{panelContent?.classification}</td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                      {panelContent?.inventors &&
                        panelContent?.inventors.length > 0 && (
                          <div>
                            {activeLink === "INVENTORS" && (
                              <>
                                <table class="custom-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Address</th>
                                      <th>Country</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {panelContent?.inventors.map(
                                      (inventor, index) => (
                                        <tr key={index}>
                                          <td>{inventor.name}</td>
                                          <td>{inventor.address}</td>
                                          <td>{inventor.country}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        )}
                      {panelContent?.applicant &&
                        panelContent?.applicant.length > 0 && (
                          <div>
                            {activeLink === "APPLICANTS" && (
                              <>
                                <table class="custom-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Address</th>
                                      <th>Country</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {panelContent?.applicant.map(
                                      (applicant, index) => (
                                        <tr key={index}>
                                          <td>{applicant.name}</td>
                                          <td>{applicant.address}</td>
                                          <td>{applicant.country}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        )}

                      {activeLink === "ABSTRACT" && (
                        <>
                          <p>{panelContent?.abstract}</p>
                        </>
                      )}

                      {activeLink === "COMPLETE_SPECIFICATION" && (
                        <>
                          <p
                            dangerouslySetInnerHTML={formatContent(
                              panelContent?.complete_specification
                            )}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="patent-details-container" style={{paddingBottom:"20px"}}>
                  {activeTab === "APPLICATION_STATUS" && (
                    <>
                      <div className="link_heading2">
                        <a
                          href="#"
                          onClick={() =>
                            handleLinkClick2("APPLICATION STATUS DETAILS")
                          }
                          className={
                            activeLink2 === "APPLICATION STATUS DETAILS"
                              ? "active"
                              : ""
                          }
                        >
                          APPLICATION STATUS DETAILS
                        </a>
                        <a
                          href="#"
                          onClick={() => handleLinkClick2("UPLOADED DOCUMENTS")}
                          className={
                            activeLink2 === "UPLOADED DOCUMENTS" ? "active" : ""
                          }
                        >
                          UPLOADED DOCUMENTS
                        </a>
                      </div>

                      {activeLink2 === "APPLICATION STATUS DETAILS" && (
                        <>
                          <table class="custom-table">
                            <tbody>
                              <tr>
                                <td>Application Number</td>
                                <td>{panelContent?.application_number}</td>
                              </tr>

                              <tr>
                                <td>Application Type</td>
                                <td>{panelContent?.application_type}</td>
                              </tr>

                              <tr>
                                <td>Date of Filling</td>
                                <td>{panelContent?.date_of_filing}</td>
                              </tr>

                              <tr>
                                <td>Application Name</td>
                                <td>{panelContent?.applicant_name}</td>
                              </tr>

                              <tr>
                                <td>Title of Innovation</td>
                                <td>{panelContent?.title_of_invention}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{panelContent?.email}</td>
                              </tr>
                              <tr>
                                <td>Priority Date</td>
                                <td>{panelContent?.priority_date}</td>
                              </tr>
                              <tr>
                                <td>Request for Examination Date</td>
                                <td>
                                  {panelContent?.request_for_examination_date}
                                </td>
                              </tr>
                              <tr>
                                <td>Publication Date</td>
                                <td>{panelContent?.publication_date}</td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td>{panelContent?.status}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="flex flex-row space-x-8 justify-around my-8 items-center">
                            <Typography
                              id="status_map_1"
                              className="p-2"
                              color={
                                applicationStatus.status_map[0]
                                  ? "primary"
                                  : "textSecondary"
                              }
                            >
                              Filed
                            </Typography>
                            <Typography
                              id="status_map_2"
                              className="p-2"
                              color={
                                applicationStatus.status_map[1]
                                  ? "primary"
                                  : "textSecondary"
                              }
                            >
                              RQ Filed
                            </Typography>
                            <Typography
                              id="status_map_3"
                              className="p-2"
                              color={
                                applicationStatus.status_map[2]
                                  ? "primary"
                                  : "textSecondary"
                              }
                            >
                              Published
                            </Typography>
                            <Typography
                              id="status_map_4"
                              className="p-2"
                              color={
                                applicationStatus.status_map[3]
                                  ? "primary"
                                  : "textSecondary"
                              }
                            >
                              Under Examination
                            </Typography>
                            <Typography
                              id="status_map_5"
                              className="p-2"
                              color={
                                applicationStatus.status_map[4]
                                  ? "primary"
                                  : "textSecondary"
                              }
                            >
                              Disposed
                            </Typography>
                            <Xarrow
                              start="status_map_1"
                              end="status_map_2"
                              strokeWidth={2}
                              color={
                                applicationStatus.status_map[1]
                                  ? "#b23427"
                                  : "rgba(0,0,0,0.12)"
                              }
                              dashness={!applicationStatus.status_map[1]}
                            />
                            <Xarrow
                              start="status_map_2"
                              end="status_map_3"
                              strokeWidth={2}
                              color={
                                applicationStatus.status_map[2]
                                  ? "#b23427"
                                  : "rgba(0,0,0,0.12)"
                              }
                              dashness={!applicationStatus.status_map[2]}
                            />
                            <Xarrow
                              start="status_map_3"
                              end="status_map_4"
                              strokeWidth={2}
                              color={
                                applicationStatus.status_map[3]
                                  ? "#b23427"
                                  : "rgba(0,0,0,0.12)"
                              }
                              dashness={!applicationStatus.status_map[3]}
                            />
                            <Xarrow
                              start="status_map_4"
                              end="status_map_5"
                              strokeWidth={2}
                              color={
                                applicationStatus.status_map[4]
                                  ? "#b23427"
                                  : "rgba(0,0,0,0.12)"
                              }
                              dashness={!applicationStatus.status_map[4]}
                            />
                          </div>
                        </>
                      )}
                      <div>
                        {activeLink2 === "UPLOADED DOCUMENTS" && (
                          <table class="custom-table">
                            <thead className="MuiTableHead-root">
                              <tr>
                                <th>Name</th>
                                <th>Upload Date</th>
                                <th>Download</th>
                              </tr>
                            </thead>
                            <tbody className="MuiTableBody-root">
                              {panelContent?.documents?.map((doc, index) => (
                                <tr key={index} className="MuiTableRow-root">
                                  <td>{doc.name}</td>
                                  <td>{doc.date}</td>
                                  <td>
                                    <a
                                      className="download-link"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        downloadFile(doc.name, doc.url);
                                      }}
                                    >
                                      Download
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div style={{position:"fixed", top:"0px", width:"100%", left:"0px"}}>
                  <LinearLoader />
                  <p
                    style={{
                      textAlign: "center",
                      color: "gray",
                      marginTop: "10px",
                    }}
                  >
                    {(buttonloader === "Patent") ? <p>Fetching patent details, Please wait...</p> : <p>Fetching application details, Please wait...</p>}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ConductFreePatentSearch;
