import React, { useState, useEffect } from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import axios from "axios";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/K&K Footer_Logo.png";
// import Logo from "../../assets/img/Footerlogo/FooterLOGOIw.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { InputAdornment, IconButton } from "@mui/material";
import "./footer.css";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useHistory } from "react-router-dom";
function Footer() {
  const history = useHistory();
  const [isBool, setIsBool] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailPattern.test(email));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/footers?populate[footer]=[title,url]"
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      console.log("Email to subscribe:", email);
      const response = await axios.post(
        "https://khuranaandkhurana-strapi.ntb.one/api/subscribes",
        { data: { email: email } }
      );

      if (response.status === 200) {
        history.push("/NewsLetterSubscription");
      }
      console.log("Subscription successful:", response.data);
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  const onClickHandler = () => {
    setIsBool(!isBool);
  };
  const handleLogoClick = () => {
    history.push("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <Grid spacing={2} container>
        <Grid item xs={12} sm={4} md={4}>
          <Link to={`${process.env.PUBLIC_URL + "/"}`}>
            <img
              src={Logo}
              style={{
                width: "75%",
                backgroundBlendMode: "multiply",
                paddingTop: "30px",
               
              }}
              onClick={handleLogoClick}
            ></img>
          </Link>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <div
            className="row1"
            style={{ paddingTop: "10px", marginTop: "10px" }}
          >
            <div>
              <Typography
                variant="h1"
                style={{
                  fontFamily: "Arial, Helvetica, Sans-serif",
                  fontSize: "1.1rem",
                  color: "white",
                }}
              >
                NEWSLETTER
                <br></br>
                <br></br>
              </Typography>
              {/* <form onSubmit={handleSubscribe}>
        <input 
          type="email" 
          placeholder="Your Email Address" 
          value={email} 
          onChange={handleEmailChange} 
        />
        <button type="submit" className="btn btn-brand">Subscribe</button>
      </form> */}
              <form method="post" onSubmit={handleSubscribe}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={8}>
                    <label
                      class="pure-material-textfield-outlined"
                      style={{
                        width: "100%",
                        background: "white",
                        height: "80%",
                        marginTop: "-10px",
                      }}
                    >
                      <input
                        type="email"
                        className="black-text-input"
                        placeholder="Your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!isValidEmail}
                        required
                        style={{ borderRadius: "0px", color: "black" }}
                      />
                      {/* <input 
          type="email" 
          placeholder="Your Email Address" 
          value={email} 
          onChange={handleEmailChange} 
        /> */}
                      {/* <span>Your Email</span> */}
                    </label>

                    {!isValidEmail && (
                      <p style={{ color: "white" }}>
                        Please fill out this field
                      </p>
                    )}
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      type="submit"
                      style={{
                        border: "2px solid white",
                        color: "white",
                        textAlign: "center",
                        backgroundColor: "#B23427",
                        marginTop: "-10px",
                        width: "130px",
                        borderRadius: "0px",
                        height: "44px",
                        textTransform: "capitalize",
                        fontSize: "16px",
                      }}
                      variant="contained"
                      disabled={!isValidEmail}
                      inputProps={{ style: { color: "black" } }}
                    >
                      {/* {isValidEmail ? "Subscribe" : "Invalid email"} */}
                      Subscribe
                    </Button>
                    {isFormSubmitted && !isValidEmail && (
                      <p className="error-message">
                        Invalid email. Please enter a valid email address.
                      </p>
                    )}
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <div
            className="row1"
            style={{
              display: "flex",
              marginTop: "30px",
              alignitems: "center",
              justifyContent: "end",
              gap: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "1.0625rem",
                color: "white",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                marginTop: "25px",
                fontWeight: "400",
              }}
            >
              Follow us
            </Typography>
            <div style={{ display: "flex" }}>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCIu2HgBG4rwf7VwtTPgPaog"
                  className="youtube"
                >
                  <div className="social-icon">
                    <YouTubeIcon
                      style={{ color: "#b23427", fontSize: "23px" }}
                    />
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://api.whatsapp.com/send/?lang=en&phone=918920269831&text=Hi%2C+we+"
                  className="whatsapp"
                >
                  <div className="social-icon">
                    <WhatsAppIcon
                      style={{ color: "#b23427", fontSize: "25px" }}
                    />
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/khurana-&-khurana-advocates-and-ip-attorneys/"
                  className="linkedin"
                >
                  <div className="social-icon">
                    <LinkedInIcon
                      style={{ color: "#b23427", fontSize: "25px" }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <br></br>
        </Grid>
      </Grid>

      <div className="row3" style={{ paddingBottom: "25px" }}>
        <br></br>
        <center>
          {/* <li className='footer-quicklist' style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"0.938rem",color:"white",fontWeight:"400",fontStretch:"normal",fontStyle:"normal"}}>
                                <LI><Link to="/contact-us" target={'_blank'} style={{color:'white'}}>Contact Us</Link></LI>
                                <LI><Link to="/CareersAndInternship" target={'_blank'} style={{color:'white'}}>Careers & Internships</Link></LI>
                                <LI><Link to="/Feedback" target={'_blank'} style={{color:'white'}}>Feedback</Link></LI>
                                <LI><Link to="/PrivacyPolicy" target={'_blank'} style={{color:'white'}}>Privacy Policy</Link></LI>
                                <LI><Link to="/TermsAndConditions" target={'_blank'} style={{color:'white'}}>Terms and Conditions</Link></LI>
                                <LI><Link to="/CompliancetoBarCouncilRules" target={'_blank'} style={{color:'white'}}>Compliance to Bar Council Rules</Link></LI>
                                <LI><Link to="/FAQs" target={'_blank'} style={{color:'white'}}>FAQs</Link></LI>
                                <LI><Link to="/Probono" target={'_blank'} style={{color:'white'}}>Pro Bono</Link></LI>
            </li> */}
             {loading ? (
           <Skeleton /> 
        ) : (
          <ul
            className="footer-quicklist"
            style={{
              fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
              fontSize: "0.938rem",
              color: "white",
              fontWeight: "400",
              fontStretch: "normal",
              fontStyle: "normal",
            }}
          >
            {data[0]?.attributes?.footer?.map((item, index) => (
              <li key={index}>
                <a href={item?.url} target="_blank" style={{ color: "white" }}>
                  {item?.title}
                </a>
              </li>
            ))}
          </ul>
        )}
        </center>
        <br></br>
        {/* <div style={{fontFamily:"HelveticaNeue-Roman,arial,helvetica,sans-serif",fontSize:"0.938rem",color:"white",fontWeight:"400",fontStretch:"normal",fontStyle:"normal"}}>
                                 <Text>
                                 Copyright © 2007-2024 Khurana and Khurana. All rights reserved.
                                 </Text>
           
           </div> */}
            {loading ? (
           <Skeleton width={500}/> 
        ) : (
        <div
          style={{
            fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
            fontSize: "0.938rem",
            color: "white",
            fontWeight: "400",
            fontStretch: "normal",
            fontStyle: "normal",
          }}
        >
          <Text>{data[0]?.attributes?.copyright}</Text>
        </div>
        )}
      </div>
    </footer>
  );
}

export default Footer;
