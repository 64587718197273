import React from 'react';
import teamData from '../../data/Team/home-one';
import TeamMember from "../../components/Team/home-one/Member";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from "@mui/material";
const TeamPage = () => {
    return (
        <div className="team-page-area-wrapper bg-offwhite sp-y sm-top">
            <div className="container">
                <div className="row mtn-30">
                    {
                        teamData.map(member=>(
                            <TeamMember key={member.id} type={'page'} id={member.id} name={member.name} designation={member.designation}  place={member.place} link={member.link} profilePic={member.profilePic}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default TeamPage;