import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import complianceimage from "../assets/img/Bar-Council-of-India-BCI.jpg";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import "./CareerAndInternship.css";
const CompliancetoBarCouncilRules = () => {
  const [Compliance, setCompliance] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fatchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/compliance-rules"
        );
        setCompliance(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fatchData();
  }, []);

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Compliance To Bar Council Rules",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );

  return (
    <div>
      <>
        <Header />
        {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        Compliance.map((ComplianceBar, index) => (
          <div key={index}>
            <div>
              <div className="titleContainer" style={{ width: "100%" }}>
                <h2
                  style={{
                    marginTop: "40px",
                    marginLeft: "30px",
                    color: "#b23427",
                    fontSize: "50px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Garamond-Regular,georgia,serif",
                  }}
                  className="profile-caption procontainer"
                >
                  {ComplianceBar.attributes.Heading}
                </h2>
                <p
                  style={{
                    textAlign: "justify",
                    marginLeft: "30px",
                    color: "#425563",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    marginRight: "30px",
                  }}
                >
                  {ComplianceBar.attributes.title}
                </p>
                <br />
              </div>
              <center>
                <div className="image-containerbarimage">
                  <img
                    className="profile-image barimage"
                    src={complianceimage}
                    alt="Image"
                    style={{
                      width: "650px",
                      height: "200px",
                      marginRight: "30px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </center>
            </div>
            <p className="subheading"
              style={{
                textAlign: "justify",
                marginLeft: "30px",
                color: "#425563",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                color: "#425563",
                marginRight: "30px",
                marginTop: "-200px",
                marginBottom:"20px",
              }}
            >
              {ComplianceBar.attributes.subheading}
            </p>
          </div>
        ))
      )}
        <Footer />
      </>
    </div>
  );
};

export default CompliancetoBarCouncilRules;
