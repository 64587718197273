import React, { useState, useEffect } from "react";
import EmployeeCard from "../components/People/EmployeeCard";
import employeeData from "../data/People/Employees.json";
import {
  Typography,
  Checkbox,
  ListItemText,
  FilledInput,
  TextField,
  OutlinedInput,
} from "@mui/material";
import { FadeLoader } from "react-spinners";

import { InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../components/People/People.css";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
const searchStyle = `
.People .MuiInput-underline::before {
  border-bottom: 1px solid #425563 !important;
}
fieldset {
  legend {
      width: unset;
      height: unset;
  }
}
.People .MuiInput-underline:after {
  border-bottom: 1px solid #425563 !important;
}`;

// const availablePractices = [
//   {
//     label: "IP Protection Portfolio Management",
//     value: "IP Protection Portfolio Management",
//   },
//   {
//     label: "Litigation And Enforcement",
//     value: "Litigation And Enforcement",
//   },
//   {
//     label: "Taxation Practice",
//     value: "Taxation Practice",
//   },
//   {
//     label: "Advisors And Opinions",
//     value: "Advisors And Opinions",
//   },
//   {
//     label: "Commercial Law Practice",
//     value: "Commercial Law Practice",
//   },
//   {
//     label: "Sports Law Practice",
//     value: "Sports Law Practice",
//   },
//   {
//     label: "Media & Entertainment",
//     value: "Media & Entertainment",
//   },
//   {
//     label: "Trade Mark and Copyright Practice",
//     value: "Trade Mark and Copyright Practice",
//   },
//   {
//     label: "Patent and Design Practice",
//     value: "Patent and Design Practice",
//   },
//   {
//     label: "Litigation and Legal Support Practice",
//     value: "Litigation and Legal Support Practice",
//   },
// ];

// const availableOffices = [
//   {
//     label: "International Offices",
//     value: "International Offices",
//   },
//   {
//     label: "Delhi/Noida/National Capital Region",
//     value: "Delhi/Noida/National Capital Region",
//   },
//   {
//     label: "Mumbai/Pune/Indore",
//     value: "Mumbai/Pune/Indore",
//   },
//   {
//     label: "Bangalore/Chennai",
//     value: "Bangalore/Chennai",
//   },
//   {
//     label: "Hyderabad",
//     value: "Hyderabad",
//   },
// ];

const showMore = 8;

const NameFilter = ({ onFilter, currentLetter }) => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
      <Typography
        variant="h1"
        style={{
          display: "flex",
          fontFamily: "Garamond,serif",
          fontSize: "25px",
          color: "#425563",
          paddingRight: "10px",
        }}
      >
        Filter by firstname
      </Typography>
      {letters.split("").map((letter) => (
        <div
          className={
            "alphabets " +
            (currentLetter.toUpperCase() === letter ? "alphabets-active" : "")
          }
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "inline-block",
            padding: "0px 5px",
            width: "30px",
            textAlign: "center",
            color: "#425563",
            fontSize: "25px",
            fontFamily: "Garamond,serif",
            cursor: "pointer",
            marginTop: "-5px",
          }}
          key={letter}
          onClick={() =>
            letter === currentLetter.toUpperCase()
              ? onFilter("")
              : onFilter(letter)
          }
        >
          {letter}
        </div>
      ))}
    </div>
  );
};

const People = () => {
  const [batchSize, setBatchSize] = useState(12);
  const [visibleEmployees, setVisibleEmployees] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [practices, setPractices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const [data, setData] = useState([]);
  const [practicedata, setPracticeData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/Employees"
        );
        const officeslist = response?.data?.data;
        const sorted_offices = officeslist?.map(
          (office) => office.attributes?.location
        );
        const unique_offices = [...new Set(sorted_offices.flat())];
        setOfficeList(unique_offices);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    const fetchPracticeData = async () => {
      try {
        const response = await axios.get(
          `https://khuranaandkhurana-strapi.ntb.one/api/employees?fields[0]=practices`
        );
        const employeesData = response?.data?.data;
        const sorted_practices = employeesData?.map((employee) =>
          employee.attributes?.practices?.map((practice) => practice?.label)
        );
        const unique_practices = [...new Set(sorted_practices.flat())];
        setPracticeData(unique_practices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
    fetchPracticeData();
  }, []);

  const handlePracticeChange = (event) => {
    const {
      target: { value },
    } = event;

    const val = typeof value === "string" ? value : value.join(",");
    query.set("practices", val);
    history.push({
      search: query.toString(),
    });
  };
  // const BrownDropdownIcon = () => {
  //   return (
  //     <div className="Arrow">
  //       <ArrowDropDownIcon
  //         style={{
  //           color: "#b23427",
  //           marginRight: "20px",
  //           transition: "color 0.3s ease", // Add a transition effect for smooth color change
  //         }}
  //         onMouseEnter={(e) => {
  //           e.target.style.color = "white"; // Change color to black on hover
  //         }}
  //         onMouseLeave={(e) => {
  //           e.target.style.color = "#b23427"; // Change color back to original on mouse leave
  //         }}
  //       />
  //     </div>
  //   );
  // };
  const handleOfficeChange = (event) => {
    const {
      target: { value },
    } = event;

    const val = typeof value === "string" ? value : value.join(",");
    query.set("offices", val);
    history.push({
      search: query.toString(),
    });
  };

  useEffect(() => {
    setOffices(query.get("offices") ? query.get("offices").split(",") : []);
    setPractices(
      query.get("practices") ? query.get("practices").split(",") : []
    );
  }, [query]);

  const handleFilterByLetter = (letter) => {
    setSelectedLetter(letter);
  };
  const handleSearchByName = (e) => {
    const keyword = e.target.value;
    setSearchInput(keyword);
  };

  useEffect(() => {
    const validEmployees = data.filter((employee) => {
      const name = employee.attributes.name
        ? employee.attributes.name.toLowerCase()
        : "";

      if (selectedLetter && !name.startsWith(selectedLetter.toLowerCase())) {
        return false;
      }
      if (
        practices.length > 0 &&
        (!employee.attributes.practices ||
          !practices.every((prac) =>
            employee.attributes.practices[0]?.label?.includes(prac)
          ))
      ) {
        return false;
      }
      if (
        offices.length > 0 &&
        !offices.includes(employee.attributes.location)
      ) {
        return false;
      }
      if (searchInput && !name.includes(searchInput.toLocaleLowerCase())) {
        return false;
      }
      return true;
    });
    setVisibleEmployees(validEmployees.slice(0, batchSize));
    setFullyLoaded(batchSize > validEmployees.length);
  }, [data, batchSize, selectedLetter, practices, offices, searchInput]);

  const loadMore = () => {
    setBatchSize((bs) => bs + showMore);
  };

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute("href", window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName !== null) {
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
    }
  };

  updateMetaTags(
    "Khurana And Khurana",
    "Khurana And Khurana",
    "Khurana And Khurana"
  );

  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
      <div className="People">
        <Typography
          variant="h1"
          style={{
            fontFamily: "Garamond,serif",
            fontSize: "65px",
            color: "#b23427",
            textAlign: "left",
            marginTop: "-20px",
          }}
        >
          People
        </Typography>
        <br></br>
        <style>{searchStyle}</style>
        <div style={{}}>
          <TextField
            style={{}}
            fullWidth
            label=""
            id="fullWidth"
            variant="standard"
            placeholder="Search by name or keyword"
            onChange={handleSearchByName}
            value={searchInput}
            InputProps={{
              endAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
            inputProps={{
              style: {
                color: "#425563",
                fontSize: "27px",
                fontFamily: "Garamond-Regular,georgia,serif",
                borderColor: "1px solid #7d8794",
                marginBottom: "10px",
              },
            }}
          />
        </div>
        <br></br>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <FormControl
              sx={{ m: 1, width: 220, borderRadius: 0 }}
              className="formControl focused"
            >
              <Select
                input={<OutlinedInput />}
                className="select"
                displayEmpty
                variant="filled"
                multiple
                value={practices}
                onChange={handlePracticeChange}
                label={null}
                renderValue={(selected) => {
                  if (selected.length == 0) {
                    return "Practices";
                  }
                  return "Practices (" + selected.length + ")";
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      backgroundColor: "#b23427",
                      width: "auto", 
                      maxHeight: "500px",
                      overflowY: "auto",
                      wordWrap: "wrap",
                    },
                  },
                }}
              >
                {practicedata?.map((prac) => (
                  <MenuItem
                    key={prac}
                    value={prac}
                    sx={{
                      color: "white",
                      fontSize: "small",
                      "&:hover": { backgroundColor: "#b23427", color: "white" },
                      marginTop: "10px",
                      margin: "-10px 0px",
                    }}
                  >
                    <Checkbox
                      checked={practices.indexOf(prac) > -1}
                      sx={{
                        color: "white",
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                        },
                      }}
                    />
                    <ListItemText
                      primary={prac}
                      sx={{ paddingTop: "0px", fontSize: "10px" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              sx={{ m: 1, width: 220, borderRadius: 0 }}
              className="formControl focused"
            >
              <Select
                input={<OutlinedInput />}
                className="select"
                displayEmpty
                multiple
                value={offices}
                onChange={handleOfficeChange}
                renderValue={(selected) =>
                  selected.length === 0
                    ? "Offices"
                    : "Offices (" + selected.length + ")"
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      backgroundColor: "#b23427",
                      width: "auto",
                      maxHeight: "600px",
                      overflowY: "auto",
                      wordWrap: "wrap",
                    },
                  },
                }}
              >
                {officeList.map((office) => (
                  <MenuItem
                    key={office}
                    value={office}
                    sx={{
                      color: "white",
                      fontSize: "5px",
                      "&:hover": { backgroundColor: "#b23427", color: "white" },
                      marginTop: "10px",
                      margin: "-10px 0px",
                    }}
                  >
                    <Checkbox
                      checked={offices.indexOf(office) > -1}
                      sx={{
                        color: "white",
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                        },
                      }}
                    />
                    <ListItemText
                      primary={office}
                      style={{ paddingTop: "0px" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <br></br>
        <div>
          <NameFilter
            onFilter={handleFilterByLetter}
            currentLetter={selectedLetter}
          />
          <br></br>
          <br></br>
        </div>
        <Grid container spacing={1}>
          {visibleEmployees.length > 0 ? (
            visibleEmployees.map((employee, index) => (
              <EmployeeCard employee={employee} />
            ))
          ) : (
            <p>No matching employees found.</p>
          )}
        </Grid>
        {fullyLoaded || (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                padding: "10px 20px",
                backgroundColor: "white",
                color: "#b23427",
                border: "2px solid #b23427",
                cursor: "pointer",
                width: "200px",
                height: "50px",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#b23427";
                e.target.style.color = "white";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "#b23427";
              }}
              onClick={loadMore}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      )}
      <Footer />
    </>
  );
};

export default People;
