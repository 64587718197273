import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

/*
 * @ All pages Import
 */
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
// import ScrollToTop from "./helpers/ScrollToTop";
import BlogDetailsPage from "./pages/BlogDetails";
import NewsLetterSubscription from "./pages/NewsLetterSubscription";
import ContactUs1 from "./pages/ContactUs1";
import ContactUs2 from "./pages/ContactUs2";
import People from "./pages/People";
import GlobalSearchPage from "./pages/GlobalSearchPage";
import AboutUs from "./pages/AboutUs";
import CorporateInductionVideo from "./pages/CorporateInductionVideo";
import InsightsPage from "./components/insights/insightsPage";
import InsightDetailsPage from "./components/InsightDetails";
import CorporatePresentation from "./pages/CorporatePresentation";
import CompliancetoBarCouncilRules from "./pages/CompliancetoBarCouncilRules";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Feedback from "./pages/Feedback";
import Probono from "./pages/Probono";
import CorporateBrouchure from "./pages/CorporateBrouchure";
import TaxationPracticeBrouchure from "./pages/TaxationPracticeBrouchure";
import CorporateandCommercialLawPracticeBrochure from "./pages/CorporateandCommercialLawPracticeBrochure";
import FAQs from "./pages/FAQs";
import FAQsTrademark from "./pages/FAQsTrademark";
import CareersAndInternship from "./pages/CareersAndInternship";
import CareerInnerPage from "./pages/CareerInnerPage";
import IndividualPeople from "./pages/PeoplePage/IndividualPeople";
import ConductFreeTradeMarkButton from "./pages/ConductFreeTradeMarkButton";
import ShareTradeMarkButton from "./pages/ShareTradeMarkButton";
import ContuctFreePatentSearch from "./pages/ContuctFreePatentSearch";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/services"}`}
          component={Service}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/services/:serviceID"}`}
          component={ServiceDetails}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-right-sidebar"}`} component={BlogGridRightSidebar}/> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/insights/"}`}
          component={InsightsPage}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/insights/:postname"}`}
          component={InsightDetailsPage}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/service/:slug"}`}
          component={BlogDetailsPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/team"}`}
          component={Team}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/team-member/:teamID"}`}
          component={TeamDetails}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL + "/CommercialLawPractice"}`} component={CommercialLawPractice}/> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contact"}`}
          component={Contact}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/home-one"}`}
          component={HomeOne}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL + '/home-two'}`} component={HomeTwo}/> */}

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={HomeOne}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/People"}`}
          component={People}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contact-us"}`}
          component={ContactUs2}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/international-contact-us"}`}
          component={ContactUs1}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/global-search"}`}
          component={GlobalSearchPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/AboutUs"}`}
          component={AboutUs}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/people/:slug"}`}
          component={IndividualPeople}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/NewsLetterSubscription"}`}
          component={NewsLetterSubscription}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CorporateInductionVideo"}`}
          component={CorporateInductionVideo}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CorporatePresentation"}`}
          component={CorporatePresentation}
        />
       
      
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CompliancetoBarCouncilRules"}`}
          component={CompliancetoBarCouncilRules}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/TermsAndConditions"}`}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/PrivacyPolicy"}`}
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Feedback"}`}
          component={Feedback}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Probono"}`}
          component={Probono}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CorporateBrouchure"}`}
          component={CorporateBrouchure}
        />
       
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/FAQs"}`}
          component={FAQs}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/TaxationPracticeBrouchure"}`}
          component={TaxationPracticeBrouchure}
        />
        <Route
          exact
          path={`${
            process.env.PUBLIC_URL +
            "/CorporateandCommercialLawPracticeBrochure"
          }`}
          component={CorporateandCommercialLawPracticeBrochure}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/FAQsTrademark"}`}
          component={FAQsTrademark}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CareersAndInternship"}`}
          component={CareersAndInternship}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/CareerInnerPage"}`}
          component={CareerInnerPage}
        />

<Route
          exact
          path={`${process.env.PUBLIC_URL + "/trademark-search"}`}
          component={ConductFreeTradeMarkButton}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/trademark-requisition-form"}`}
          component={ShareTradeMarkButton}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/patent-search"}`}
          component={ContuctFreePatentSearch}
        />


        <Route exact component={Error404}/>
      </Switch>
    </Router>
  );
};

export default App;
