import { Box, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function MegaMenuItem({
  listItem,
  arrow,
  handleCloseUserMenu,
  color,
  style,
  font,
  fontSize,
  fontWeight,
  fontStyle,
}) {
  return (
    <MenuItem
      key={listItem.id}
      style={{
        overflow: "hidden",
        height: "2.5rem",
        lineHeight: "1.5", 
        borderStyle: "solid",
        margin: 0,

        borderWidth: "0px 0px 1px 0px",
        // borderWidth:100%,
        borderColor: color,
        ...style,
        color: "#DFDFD5",
        "&:hover": { color: "#f58727" },
      }}
      onClick={handleCloseUserMenu}
    >
      <Link
        to={`${process.env.PUBLIC_URL + listItem.link}`}
        style={{ width: "100%" }}
      >
        <Box
          component="div"
          textAlign="left"
          sx={{
            overflow: "hidden",
            width: "100%",
            color: "#425563",
            height: "auto",
            marginBottom: 0,
            whiteSpace: "nowrap",
           // Remove bottom margin
            "&:hover": { color: "#b23427"},
            alignItems: "center",
            // justifyContent: "center",
            display: "flex",
          }}
        >
          <span
            style={{
              color: color,
              fontSize: fontSize,
              overflow: "hidden",
              width:"100%",
              whiteSpace: "wrap",
              wordWrap: "break-word",
              fontFamily: font,
              fontWeight: fontWeight,
              fontStyle: fontStyle,
            }}
          >
            {listItem.title}
          </span>{" "}
          <span style={{ float: "right" }}>
            {arrow && (
              <ArrowForwardIosIcon style={{ color: color, fontSize: "15px" }} />
            )}
          </span>
        </Box>
      </Link>
    </MenuItem>
  );
}
