import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./locations.css";
import { useState } from "react";
import axios from "axios";
import { FadeLoader } from "react-spinners";
const PrivacyPolicy = () => {
  const [policy, setPolyce] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fatchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/privacy-policies"
        );
        setPolyce(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error find", error);
      }
    };
    fatchData();
  }, []);

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Privacy Policy",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );

  return (
    <div>
      <>
        <Header />
        {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <div>
          {policy.map((PaivacyPalicy, index) => (
            <div
              key={index}
              className="titleContainer procontainer"
              style={{ width: "100%" }}
            >
              <h2
                style={{
                  marginTop: "40px",
                  marginLeft: "30px",
                  color: "#b23427",
                  fontSize: "50px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontFamily: "Garamond-Regular,georgia,serif",
                }}
                className="profile-caption privacycontainer"
              >
                {PaivacyPalicy.attributes.title}
              </h2>
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "30px",
                  color: "#425563",
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "30px",
                  color: "#425563",
                  marginRight: "30px",
                }}
              >
                {PaivacyPalicy.attributes.subheading}
              </p>
            </div>
          ))}
        </div>
      )}
        <br></br>
        <br></br>
        <Footer />
      </>
    </div>
  );
};

export default PrivacyPolicy;
