import React, {Fragment} from 'react';
import PageWrapper from "../PageWrapper";
import SidebarForInsights from "./sidebarInsights";
import InsightsList from './list';
import Header from "../Header";
import Footer from "../Footer/index";
import LoginRegister from "../LoginRegister";
import MobileMenu from "../MobileMenu";
import "./insightlist.css"
const InsightsPageLayout = ({sidebar_position,blog_type,sidebar}) => {
    return (
        <Fragment>
            {/* <PageHeader
                bgImg={require('../../assets/img/page-header.jpg')}
                title={'From Businex Latest'}
                content={'Businex always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly.'}
            /> */}
              
            <PageWrapper classes={'blog-page-content-area sp-x'}>
                {sidebar === true && sidebar_position === 'left' ? <SidebarForInsights classes={'order-1 order-lg-0'}/> : null}

                <InsightsList blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1':null}/>

                {sidebar === true && sidebar_position === 'right' ? <SidebarForInsights/> : null}
            </PageWrapper>
        </Fragment>
    );
};

const InsightsPage = () => {
    return (
        <Fragment>
            <Header/>
            <InsightsPageLayout blog_type={'list'} sidebar={true} sidebar_position={'right'}/>
            {/* <CallToAction/> */}
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default InsightsPage;