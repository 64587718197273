import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import "./insightlist.css";
import blogimg from "../../assets/img/K & K Image.webp";

const InsightItemList = (props) => {
  const urltitle = props.postName.replace(/ /g,'-').toLowerCase();
  // const urlid = props.id;
  // console.log("urltitlehjash",urltitle);
  const blogURL = `/insights/${urltitle}`;
  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="blog-item">
      <div className="row1  align-items-center">
        {/* <div className="col-md-5"> */}
        <div className="col-md-2" style={{ padding: "15px" }}>
          <figure className="blog-thumb">
            {/* <Link to={`${process.env.PUBLIC_URL}${blogURL}`}>
                            <img
                                src={props.thumb}
                                alt={props.title}
                                style={{
                                    transition: 'opacity 0.3s ease',
                                }}
                                onMouseOver={(e) => (e.currentTarget.style.opacity = '0.7')}
                                onMouseOut={(e) => (e.currentTarget.style.opacity = '1')}
                            />
                        </Link> */}
          </figure>
        </div>

        {/* <div className="col-md-"> */}
        <div className="col-md-10">
          <div className="blog-content">
            <div style={{ marginTop: "40px" }}>
              <h2
                className="h5"
                style={{
                  color: "#425563",
                  fontSize: "19px",
                  fontFamily:
                    "HelveticaNeue-BoldCondensed, arial, helvetica, sans-serif",
                  padding: "0px 25px 0px 0px",
                  fontWeight: "bolder",
                  letterSpacing: "-0.2px",
                  overflowWrap: "break-word",
                  paddingTop: "15px",
                }}
              >
                <Link to={`${process.env.PUBLIC_URL}${blogURL}`}>
                  {props.title}
                </Link>
              </h2>
            </div>
            <div
              className="blog-meta"
              style={{ marginTop: "-30px", display: "flex" }}
            >
              <div>
                <AccessTimeOutlinedIcon
                  style={{ fontSize: "small", color: "#425563", width: "100%" }}
                />
              </div>
              <div>
                <p style={{ color: "#425563", marginLeft: "5px" }}>
                  {formatDate(props?.date)}
                </p>
              </div>
              <div style={{ display: "flex", marginLeft: "10px" }}>
                <div>
                  <FolderOutlinedIcon
                    style={{
                      fontSize: "small",
                      color: "#425563",
                      width: "100%",
                    }}
                  />
                </div>
                <br />
                <div>
                  {isMobile ? (
                    <p style={{ color: "#425563", marginLeft: "5px" }}>
                      {props?.category?.slice(0, 22)}
                    </p>
                  ):(

                  <p style={{ color: "#425563", marginLeft: "5px" }}>
                    {props?.category}
                  </p>
                  )})
                </div>
                {/* <div>
                                    <Link to={`${process.env.PUBLIC_URL}${blogURL}`} style={{ textDecoration: 'none', color: '#425563', transition: 'text-decoration 0.3s ease, color 0.3s ease', marginLeft: "10px", width: "100%" }}
                                        onMouseOver={(e) => { e.currentTarget.style.textDecoration = 'underline'; e.currentTarget.style.color = '#b23427'; }}
                                        onMouseOut={(e) => { e.currentTarget.style.textDecoration = 'none'; e.currentTarget.style.color = '#425563'; }}>
                                        {props.postBy}
                                    </Link>
                                </div> */}
              </div>
            </div>
            <br />
            {/* <p style={{ fontSize: "16px", fontFamily: "HelveticaNeue-Roman, arial, helvetica, sans-serif", color: "#425563", fontWeight: "400", letterSpacing: "-0.1px", fontStretch: "normal" }}>{props.excerpt}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightItemList;
