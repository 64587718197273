import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./locations.css";
import { FadeLoader } from "react-spinners";
import axios from "axios";
const TermsAndConditions = () => {
  const [term, setTerm] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fatchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/terms-and-conditions"
        );
        setTerm(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error find", error);
      }
    };
    fatchData();
  }, []);

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Terms And Conditions",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );

  return (
    <div>
      <>
        <Header />
        {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div className="titleContainer termhead" style={{ width: "100%" }}>
            <h2
              style={{
                marginTop: "40px",
                marginLeft: "30px",
                color: "#b23427",
                fontSize: "50px",
                fontStyle: "normal",
                fontWeight: "400",
                fontFamily: "Garamond-Regular,georgia,serif",
                lineHeight: "100%",
                letterSpacing: "-1.6px",
              }}
              className="profile-caption termsubhead"
            >
              Terms And Conditions
            </h2>
            {term.map((Term, index) => (
              <>
                <h2
                  key={index}
                  style={{
                    marginTop: "40px",
                    marginLeft: "30px",
                    color: "#b23427",
                    fontSize: "35px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Garamond,serif",
                    lineHeight: "100%",
                    letterSpacing: "-1.6px",
                  }}
                  className="profile-caption"
                >
                  {Term.attributes.title}
                </h2>
                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "30px",
                    color: "#425563",
                    fontFamily:
                      "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                    fontSize: "16px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    marginRight: "30px",
                  }}
                >
                  {Term.attributes.subheading}
                </p>
              </>
            ))}
          </div>
        </div>
      )}
        <br></br>
        <br></br>
        <Footer />
      </>
    </div>
  );
};

export default TermsAndConditions;
