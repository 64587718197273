import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./locations.css";
import { FadeLoader } from "react-spinners";
import Probonoimage1 from "../assets/img/FOOTERPICS/1.png";
import Probonoimage2 from "../assets/img/FOOTERPICS/DCE.jpg";
import Probonoimage3 from "../assets/img/FOOTERPICS/GIAN.jpg";
import Probonoimage4 from "../assets/img/FOOTERPICS/iim.jpg";
import Probonoimage5 from "../assets/img/FOOTERPICS/KIIT.jpg";
import Probonoimage6 from "../assets/img/FOOTERPICS/NIF.jpg";
import Probonoimage7 from "../assets/img/FOOTERPICS/SRMS.jpg";
import Probonoimage8 from "../assets/img/FOOTERPICS/YIE.jpg";
import axios from "axios";
const Probono = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/probonos"
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Strapi:", error);
      }
    };

    fetchData();
  }, []);
  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Pro Bono",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );
  return (
    <div>
      <>
        <Header />
        {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        data.map((proB, index) => (
          <div key={index}>
            <div className="PorHead" style={{ display: "flex" }}>
              <div className="titleContainer" style={{ width: "100%" }}>
                <h2
                  style={{
                    marginTop: "40px",
                    marginLeft: "30px",
                    color: "#b23427",
                    fontSize: "35px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontFamily: "Garamond-Regular,georgia,serif",
                  }}
                  className="profile-caption proheading"
                >
                  {proB.attributes.Heading}
                </h2>

                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "30px",
                    color: "#425563",
                    fontFamily: "Garamond,serif",
                    fontSize: "25px",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "30px",
                    color: "#425563",
                    marginRight: "30px",
                    textAlign: "justify",
                  }}
                  className="protitle"
                >
                  {proB.attributes.title}
                </p>
                <br />
              </div>
            </div>
            <p
              style={{
                textAlign: "left",
                color: "#425563",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "30px",
                marginLeft: "30px",
                marginRight: "30px",
                textAlign: "justify",
              }}
              className="protitle1"
            >
              {proB.attributes.subheading}
            </p>
          </div>
        ))
      )}
        <div className="image-container procontainer1" style={{ display: "flex" }}>
          <img
            className="profile-image"
            src={Probonoimage1}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage2}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage3}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage4}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
        </div>
        <div
          className="image-container1 procontainer2"
          style={{ display: "flex", marginLeft: "320px" }}
        >
          <img
            className="profile-image"
            src={Probonoimage5}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage6}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage7}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
          <img
            className="profile-image"
            src={Probonoimage8}
            alt="Image"
            style={{ width: "150px", height: "150px", margin: "30px" }}
          />
        </div>
        <Footer />
      </>
    </div>
  );
};

export default Probono;
