import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ShareIcon from "@mui/icons-material/Share";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import { FadeLoader } from "react-spinners";
const CareerInnerPage = () => {
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobs, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/fa-qs-trademarks"
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from StrApi:", error);
      }
    };
    fetchData();
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions(isExpanded ? panel : []);
  };
  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "FAQs for Trademark",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );
  return (
    <div>
      <Header />
      <br />
      <br />
      {/* Your other content here */}
      {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <>
      <div
      className="faqtrade buttonfaq"
        style={{
          display: "flex",
          background: "white",
          width: "1000px",
          height: "70px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
        className='buttonfaq'
          style={{
            width: "500px",
            height: "70px",
            background: "#b23427",
            borderRadius: "20px",
          }}
        >
          <a href="/FAQs">
            <h3
              style={{
                color: "white",
                fontSize: "20px",
                fontFamily: "Garamond-Regular,georgia,serif",
                textAlign: "center",
                paddingTop: "10px",
                marginLeft: "20px",
                textDecoration: "underline 1px solid white",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "25px",
              }}
            >
              <ArrowForwardIosIcon style={{ fontSize: "small" }} />
              FAQs For Patent
            </h3>
          </a>
        </div>
        <div
        className='buttonfaq1'
          style={{
            width: "500px",
            height: "70px",
            background: "#b23427",
            marginLeft: "10px",
            borderRadius: "20px",
          }}
        >
          <a href="/FAQsTradeMark">
            <h3
              style={{
                color: "white",
                fontSize: "20px",
                fontFamily: "Garamond-Regular,georgia,serif",
                textAlign: "center",
                marginLeft: "20px",
                textDecoration: "underline 1px solid white",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "25px",
              }}
            >
              <ArrowForwardIosIcon style={{ fontSize: "small" }} />
              FAQs For Trade Mark
            </h3>
          </a>
        </div>
      </div>
      <br></br>
      <div>
        <h3
          style={{
            marginTop: "50px",
            textAlign: "center",
            color: "#b23427",
            fontSize: "50px",
            fontFamily: "Garamond-Regular,georgia,serif",
            lineHeight: "30px",
            margin: "8px 0 32px",
            width: "100%",
            marginBottom: "10px",
          }}
          className="profile-caption faqmaintext"
        >
          (FAQs) For Trademark
        </h3>
      </div>
      <br></br>
      <br></br>
      <div
       className='MainDiv1 faqtextarea'
        style={{
          width: "1000px",
          height: "auto",
          background: "#f3f3f3",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "20px",
        }}
      >
        <div>
          {jobs.map((job, index) => (
            <div
            className='faqtextarea'
              key={index}
              style={{
                width: "900px",
                height: "auto",
                background: "white",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                marginBottom: "20px",
                padding: "10px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <h3 style={{ fontSize: "25px", fontFamily: "Arial, Arial, Arial, sans-serif", color: "#b23427", paddingLeft: "30px", paddingTop: "30px" }}>
                                    {job.title}
                                    <ExpandMoreIcon
                                        onClick={handleAccordionChange(index)}
                                        style={{ marginLeft: 'auto', cursor: 'pointer', color: "#425563" }}
                                    />
                                </h3> */}
              </div>
              <div
                style={{
                  width: "90%",
                  width: "auto",
                  height: "auto",
                  background: "#f3f3f3",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20px",
                }}
              >
                <div>
                  <Accordion
                    expanded={expandedAccordions === index}
                    onChange={handleAccordionChange(index)}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography
                        style={{
                          paddingLeft: "15px",
                          color: "#b23427",
                          fontFamily:
                            "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                          fontSize: "20px",
                        }}
                      >
                        {job.attributes.question}
                      </Typography>
                      <ExpandMoreIcon
                        onClick={handleAccordionChange(index)}
                        style={{
                          marginLeft: "auto",
                          cursor: "pointer",
                          color: "#b23427",
                          marginTop: "5px",
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {expandedAccordions === index && (
                        <Typography
                          style={{
                            color: "#425563",
                            fontFamily:
                              "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {job.attributes.answer}
                        </Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
      </div>
      <br />
      <br />
      </>
      )}
      <Footer />
    </div>
  );
};

export default CareerInnerPage;
