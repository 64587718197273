import React, { useState, useEffect } from "react";
import "../pages/locations.css";
import { Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FadeLoader } from "react-spinners";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

function ContactUs1() {
  const { search } = useLocation();
  const history = useHistory();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [lineTop, setLineTop] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://khuranaandkhurana-strapi.ntb.one/api/international-locations/"
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data from StrApi:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let id = query.get("id");
    const fetchSelectedLocation = async()=>{
      try {
        const response = await axios.get(
          `https://khuranaandkhurana-strapi.ntb.one/api/international-locations/${id}`
        );
        setSelectedLocation(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from StrApi:", error);
      }
    }
    // const index = data?.[0]?.attributes?.locations.findIndex(
    //   (loc) => loc.id === id
    // );
    const index = data?.findIndex(
      (loc) => loc.id == id
    );

    if (!id || index < 0) {
      // handleItemClick(data?.[0]?.attributes?.locations[0]?.id);
      handleItemClick(13);
    }

    setSelectedItem(index);

    const lineHeight = 50;
    setLineTop(index * lineHeight);
   
    fetchSelectedLocation(id);
  
    // setSelectedLocation(data?.[0]?.attributes?.locations[index]);
    
  }, [query, data]);

  const handleItemClick = (id) => {
    query.set("id", id);
    history.push({
      search: query.toString(),
    });
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [verified, setVerified] = useState(false);

  function onChange(value) {
    setVerified(true);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (handleFormValidation()) {
  //     console.log(formData);
  //     Swal.fire({
  //       icon: "success",
  //       confirmButtonColor: "#b23427",
  //       title: "Thank You for contacting Us",
  //       titleText: "Thank You for contacting Us",
  //       text: "One of our executives will reach out to you shortly.",
  //       customClass: {
  //         title: "swal-title", 
  //         content: "swal-text", 
  //       },
  //     }).then(() => {
  //       setFormData({
  //         FirstName: "",
  //         LastName: "",
  //         email: "",
  //         contactno: "",
  //         message: "",
  //       });
  //     });
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleFormValidation()) {
      fetch('https://khuranaandkhurana-strapi.ntb.one/api/get-in-touches', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: formData }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data); 
        const { id } = data;
        
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#b23427",
          title: "Thank You for contacting Us",
          titleText: "Thank You for contacting Us",
          text: `Your submission was successful. ID: ${id}`,
          customClass: {
            title: "swal-title", 
            content: "swal-text", 
          },
        });
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          contact_no: "",
          message: "",
        });
        setVerified(false);
      })
      .catch(error => {
        console.error('There was an error with the fetch operation:', error);
      });
    }
  };
  

  const handleFormValidation = () => {
    let errors = {};
    let isValid = true;

    if (!formData.first_name) {
      errors.first_name = "First Name is required";
      isValid = false;
    }

    if (!formData.last_name) {
      errors.last_name = "Last Name is required";
      isValid = false;
    } else if (formData.first_name === formData.last_name) {
      errors.last_name = "Last Name should not be the same as First Name";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!formData.contact_no ) {
      isValid = false;
      errors.contact_no = "Phone number is required.";
    } else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
      var cm = formData.contact_no;
      if (!mobPattern.test(cm)) {
        isValid = false;
        errors.contact_no = "Invalid phone number.";
      }
    }

    if (!formData.message) {
      errors.message = "Message is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const updateMetaTags = (metaTagName, metaTagContent, metaTagKeyword) => {
    let metaTag = document.querySelector(`meta[name="description"]`);
    let metaKeyword = document.querySelector(`meta[name="keywords"]`);
    let linkTag = document.querySelector(`link[rel="canonical"]`);
    linkTag.setAttribute('href', window.location.href);
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "description");
      document.head.appendChild(metaTag);
    } else {
      metaTag.setAttribute("content", metaTagContent);
      metaKeyword.setAttribute("content", metaTagKeyword);
    }
    if (metaTagName && metaTagName!== null){
      console.log(metaTagName, "enteredLoopMeta");
      document.title = metaTagName;
      }
  };

  updateMetaTags(
    "Contact Us",
    "Khurana And Khurana",
    "Khurana And Khurana",
  );


  return (
    <>
      <Header />
      
      <div className="ContactUs1">
        <Typography
          variant="h1"
          className="hover-effect"
          style={{
            fontSize: "43px",
            fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
            paddingLeft: "70px",
          }}
        >
          Contact Us
        </Typography>
        {loading ? (
        <div className="loader-container">
          <div>
            <FadeLoader
              size={50}
              color={"rgb(178, 52, 39)"}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <>
        <div className="sidebar">
          <ul className="list">
            {/* {data?.[0]?.attributes?.locations.map((location, index) => ( */}
                {data?.map((location, index) =>  (
              <li
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "15px",
                  color: "#666",
                  lineHeight: "30px",
                }}
                key={location.id}
                className={`list-item ${
                  selectedItem === index ? "active" : ""
                }`}
                onClick={() => handleItemClick(location.id)}
              >
                {location.attributes.city}
              </li>
            ))}
          </ul>

          {selectedItem !== null && (
            <div className="red-line" style={{ top: `${lineTop}px` }} />
          )}
        </div>
        <div
          className="data-container"
          style={{ paddingLeft: "300px", marginTop: "-450px" }}
        >
          {console.log({selectedLocation})}
          {selectedLocation && (
            <div className="selected-data">
              <p
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "28px",
                  color: "#b23427",
                  textAlign: "left",
                }}
              >
                {selectedLocation.attributes.city}
              </p>
              <p
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "20px",
                  color: "#425563",
                  textAlign: "left",
                }}
              >
                {selectedLocation.attributes.address}
              </p>
              <p
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "20px",
                  color: "#425563",
                  textAlign: "left",
                }}
              >
                {selectedLocation.attributes.telephone}
              </p>
              <p
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "20px",
                  color: "#425563",
                  textAlign: "left",
                }}
              >
                {selectedLocation.attributes.email}
              </p>
              <p
                style={{
                  fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                  fontSize: "20px",
                  color: "#425563",
                  textAlign: "left",
                }}
              >
                {selectedLocation.attributes.Fax}
              </p>
              {selectedLocation.attributes.google_map && (
                <iframe
                  style={{ width: "90%", marginLeft: "-95px" }}
                  height="450"
                  loading="lazy"
                  src={selectedLocation.attributes.google_map}
                  allowfullscreen=""
                ></iframe>
              )}
            </div>
          )}
        </div>
        </>
      )}
        <br></br>
        <br></br>
        <br></br>
        {/* <br></br>
        <br></br>
        <br></br>
        <br></br> */}
        <form
          className="form-container"
          onSubmit={handleSubmit}
          style={{ margin:"0 auto", width:"100%" }}
        >
          <center>
            <Typography
              variant="h1"
              className="hover-effect"
              style={{
                fontSize: "43px",
                fontFamily: "HelveticaNeue-Roman,arial,helvetica,sans-serif",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Get In Touch
            </Typography>
            <br></br>
            <br></br>
            <div className="Form1placeholder">
              <div style={{ width: "49%", height: "50px" }}>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="form-input1"
                />
                {formErrors.first_name && (
                  <div
                    className="error"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {formErrors.first_name}
                  </div>
                )}
              </div>
              <div style={{ width: "49%", height: "50px" }}>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="form-input2"
                />
                {formErrors.last_name && (
                  <div className="error">{formErrors.last_name}</div>
                )}
              </div>
            </div>
            <div className="Form2placeholder">
            <div style={{ width: "49%", height: "50px" }}>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-input3"
                />
                {formErrors.email && (
                  <div className="error">{formErrors.email}</div>
                )}
              </div>

              <div style={{ width: "49%", height: "50px" }}>
                <input
                  type="number"
                  name="contact_no"
                  placeholder="Your Contact Number"
                  value={formData.contact_no }
                  onChange={handleChange}
                  className="form-input4"
                />
                {formErrors.contactno && (
                  <div className="error">{formErrors.contact_no}</div>
                )}
              </div>
            </div>
            <textarea
              style={{ width: "100%", height: "200px", display: "flex" }}
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              className="form-input5"
            />
            {formErrors.message && (
              <div className="error">{formErrors.message}</div>
            )}
            <br></br>
            <br></br>
            <ReCAPTCHA
              style={{ marginRight: "600px" }}
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={onChange}
            />
            ,<br></br>
            <button type="submit" className="form-button" disabled={!verified}>
              Submit
            </button>
          </center>
        </form>
        <br></br>
        <br></br>
      </div>    
      <br/>
      <Footer />
    </>
  );
}

export default ContactUs1;
