import React , { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import bloglogo from '../../assets/img/blog/AO_hero_image_M01_sectors_Energy.webp'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DOMPurify from 'dompurify';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from '@mui/material';
import '../insights/insightlist.css';
const InsightDetailsContentWrapper = ({ post, relatedPosts }) => {
  const borderColor = "#425563";
  console.log("detailinsightcontent", post);
  const sanitizedContent = DOMPurify.sanitize(post?.attributes?.post_content);
  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  useEffect(() => {
    modifiedContent();
    modifyAnchor();
    modifyHeading();
    modifyImage();
  }, [post]);

  function modifiedContent() {
    const imgElement = document.querySelector('.backendcontent > span > img');
    if (imgElement) {
      const nextSibling = imgElement.nextSibling;
      const prevSibling = imgElement.previousSibling;
      if (nextSibling && prevSibling){
        if (nextSibling.textContent !== null && prevSibling.textContent !== null) {
            nextSibling.textContent = '';
            prevSibling.textContent = '';
        }
      }
    }
}

function modifyHeading() {
  const strongHead = document.querySelectorAll('.backendcontent span strong');
  if(strongHead && strongHead.length > 0){
    strongHead.forEach(element => { 
    element.style.display="block";
    element.style.margin="15px 0";
  });
}
}

function modifyImage() {
  const strongHead = document.querySelectorAll('.backendcontent  span  img');
  if (strongHead && strongHead.length > 0) {
    strongHead.forEach(element => {
      if (window.innerWidth < 768) {
        element.style.transform = "";
        element.style.margin = ""; 
        element.style.height = ""; 
        element.style.width = ""; 
      } else {
        element.style.transform = "translate(50%, 0)";
        element.style.marginTop = "15px";
        element.style.marginBottom = "35px";
        element.style.height = "225px";
        element.style.width = "400px";
      }
    });
  }
}
// modifyImage();
window.addEventListener('resize', modifyImage);

function modifyAnchor() {
  const anchorElements = document.querySelectorAll('.backendcontent span a');
  if (anchorElements && anchorElements.length > 0) {
    console.log(anchorElements, "anchorelements");
    anchorElements.forEach(element => {
      element.style.color = "#0d6efd";
    });
  }
}


  return (
    <div className="maintext" style={{ display: "flex" }}>
      <div className="basetext" style={{ flex: "80%",maxWidth:"100%", paddingRight: "20px" }}>
        {/* <div className="Breadtext" style={{ display: "flex", alignItems: "center", marginTop: "-200px" }}>
          <h3 style={{ fontSize: '16px', fontFamily: "Arial, Helvetica, sans-serif", color: "#b23427", marginRight: "10px" }}>
          <a href="/" style={{ textDecoration: 'none', color: '#b23427', cursor: 'pointer' }}>Home</a> / 
          <a href="/insights" style={{ textDecoration: 'none', color: '#b23427', cursor: 'pointer' }}>Insight</a> /

            </h3>
          <h3 style={{ fontSize: '16px', fontFamily: "Arial, Helvetica, sans-serif", color: "#425563", flex: 1 }}>{post.subCategory?.[0]}</h3>
        </div> */}


        <div style={{ display: "flex" }}>
          <div  className="basetext" style={{ flex: "80%",maxWidth:"100%", paddingRight: "20px" }}>
            {/* <br></br>
            <br></br> */}
            <div className='Breadtext' style={{ marginTop: "10px", paddingTop:"60px" }}>
        <h3  className='mobiletext' style={{ fontSize: "34px", fontFamily: "Garamond,serif", color: "#b23427", width: "80%" }}>{post?.attributes?.post_title}</h3>
              <br></br>
              <br></br>
              <div  style={{ marginTop: "-30px" }}>
              <h3 style={{ fontSize: "16px", fontFamily: "Arial, Helvetica, sans-serif", color: "#425563" }}>
                <WatchLaterOutlinedIcon style={{ fontSize:"large", fontWeight:"500" }} /><strong>{formatDate(post?.attributes?.post_date)}</strong>&nbsp;&nbsp;
              <FolderOutlinedIcon style={{ fontSize:"large",fontWeight:"500" }} /><strong>{post?.attributes?.categories?.replace(/&amp;/g, '')}</strong>&nbsp;
             
               {/* <strong> {post?.attributes?.post_name}</strong> */}
                </h3>
              <div className='backendcontent' style={{textAlign:"justify"}}  ><span dangerouslySetInnerHTML={{ __html: sanitizedContent }} /></div>  
              </div>
            </div>
            <br/>
            <div  style={{ display: "flex" }}>
              <div style={{
                boxSizing: "border-box",
                overflowY: "auto", 
                
              }}>
                {/* <div dangerouslySetInnerHTML={{__html: post?.attributes?.post_content}} style={{textAlign:"justify"}}>
                </div> */}
                {relatedPosts?.attributes.length>0&&<div>
                  <h3 style={{ fontSize: "27px", fontFamily: "Arial,Helvetica,sans-serif", color: "#222222", marginLeft: "0px", borderBottom: "1px solid #E5E4E2", paddingBottom: "10px" }}>Related Posts</h3>
                  <Grid container spacing={2}>
                    {relatedPosts?.attributes?.map(rPost=>(

                    <Grid item xs={12} sm={6} md={4} style={{ borderRadius: "5px", height: "400px"}}>
                      <img
                        src={require('../../assets/img/' +rPost?.post_title)}
                        alt={rPost?.post_title}
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          transition: "opacity 0.3s"
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.opacity = "0.8"; 
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.opacity = "1"; 
                        }}
                      />
                      <p style={{ fontSize: "18px", fontFamily: "Arial, Helvetica, sans-serif", color: "#222222", marginTop: "10px" }} onMouseEnter={(e) => {
                        e.target.style.color = "#b23427";
                      }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "#222222";
                        }}>
                        {rPost?.post_title}
                      </p>
                      {/* <p style={{ fontSize: "13px", fontFamily: "Arial, Helvetica, sans-serif", color: "#425563", marginTop: "10px" }}>
                        {rPost?.post_content}
                      </p> */}
                    </Grid>
                    ))}
                  </Grid>

                </div>}


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InsightDetailsContentWrapper;