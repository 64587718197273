import React from "react";
import "../components/MyCard.scss";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const Mycard = (props) => {
  return (
    <div className="mycard">
      <center>
        <a href={props.postname}>
          <img src={`https://khuranaandkhurana-strapi.ntb.one${props.logo}`} alt="Logo" 
          style={{width:"240px", height:"160px"}}
          />
        </a>
        <br />
        <h3
          style={{
            color: "#425563",
            fontFamily: "HelveticaNeue-Roman, Arial, Helvetica, sans-serif",
            fontSize: "20px",
            textAlign: "left",
            marginLeft: "15px",
            marginTop: "15px",
            maxHeight:"50px",
            overflow: "hidden",
          }}
        >
          <a className="ellipsis" href={props.postname} style={{ color: "#425563" }}>
            {props.title}
          </a>
        </h3>
        <br />
        <p
          style={{
            color: "#425563",
            textAlign: "left",
            paddingLeft: "15px",
            fontFamily: "Garamond-Roman, Arial, Helvetica, sans-serif",
            fontSize: "16px",
          }}
        >
          {props.date}
        </p>{" "}
        {/* Use props.date for the date */}
        <a
          href={props.postname}
          style={{
            color: "#B23427",
            fontSize: "16px",
            fontFamily: "Garamond-Roman, Arial, Helvetica, sans-serif",
            textAlign: "left",
            marginLeft: "-110px",
          }}
        >
          Read More
          <ArrowRightAltIcon />
        </a>
      </center>
    </div>
  );
};

export default Mycard;
